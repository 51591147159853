import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks/redux-hooks';
import SidebarV2 from 'components/sidebar';
import { useEffect } from 'react';
import { getProtoTypes } from 'redux/slices/prototype';

const AuthenticatedLayout = ({ children }: any) => {
  const dispatch = useAppDispatch();
  const { userId } = useAppSelector((root) => root.authentication);

  useEffect(() => {
    dispatch(getProtoTypes());
  }, [userId]);

  return (
    <div className='flex bg-background h-screen items-start font-nunito overflow-y-hidden'>
      <SidebarV2 />
      {children}
    </div>
  );
};

export default AuthenticatedLayout;
