import React from 'react';
import loginImage from 'assets/welcome/signin.png';
import MobileLogo from 'assets/welcome/outdefine-mobile-logo.png';
import MobileGraphic from 'assets/welcome/signin-mobile-graphic.png';
import LoginPanel from './LoginPanel';
import Heading from 'components/Heading/HeadingV2';
import Typography from 'components/V2/Typography/TypographyV2';
import './styles.scss';

const Login: React.FC = () => {
  return (
    <>
      <div className='flex-col hidden min-h-screen px-0 md:flex-row md:flex'>
        <div className='px-2 mx-auto my-auto mt-10 mb-10 md:mb-auto md:mt-auto'>
          <LoginPanel />
        </div>
        <div className='relative md:flex flex-col gap-y-4 w-full md:w-[550px] lg:w-[725px] mr-0 md:mb-0 justify-between'>
          <img src={loginImage} alt='login'></img>
        </div>
      </div>
      <div className='flex flex-col min-h-screen px-0 md:flex-row md:hidden'>
        <div className='relative flex flex-col items-center justify-center text-center text-white mobile-signin-header-background'>
          <img src={MobileLogo} alt='Outdefine' className='mt-8' width={186} />
          <img src={MobileGraphic} alt='LFG' width={295} className='z-10' />
          <div className='w-full h-[160px] bg-white block absolute bottom-0 rounded-t-lg'></div>
        </div>
        <div className='mx-auto my-auto mb-10 md:mb-auto md:mt-auto'>
          <LoginPanel />
        </div>
      </div>
    </>
  );
};

export default Login;
