import * as Sentry from '@sentry/react';
/* global localStorage */
const APP_ID = 'OUTDEFINE_';

interface ISetItemOptions {
  expireDate?: Date
}

export const setItem = (key: string, data: any, options: ISetItemOptions ={} ) => {

  // Save the expiration date is provided
  const {expireDate} = options
  if(expireDate) {
    data = {
      expireDate,
      data
    }
  }

  localStorage.setItem(APP_ID + key, JSON.stringify(data));
};

interface IGetItemOptions {
  withExpirationDate?: boolean,
  defaultValue?: any
}

export const getItem = (key: string = '', options: IGetItemOptions = { defaultValue: ''}) => {
  try {

    let parsed = JSON.parse(localStorage.getItem(APP_ID + key) || '')

    // If the expiration date was set
    if(options.withExpirationDate) {
      if (new Date(parsed.expireDate).getTime() < new Date().getTime()) {
        deleteItem(key)
        parsed=null
      }else{
        parsed=parsed.data
      }
    }

    return parsed || options.defaultValue;
  } catch (e) {
    Sentry.captureException(e);
    return options.defaultValue || false;
  }
};

export const deleteItem = (key: string) => {
  localStorage.removeItem(APP_ID + key);
};

export const getProfileId = () => getItem('USER_ID');
export const setUserId = (data: string) => setItem('USER_ID', data);
export const deleteUserId = () => deleteItem('USER_ID');

export const getToken = () => getItem('TOKEN');
export const setToken = (data: string) => setItem('TOKEN', data);
export const deleteToken = () => deleteItem('TOKEN');

export const clearInfo = () => {
  deleteUserId();
  deleteToken();
};
