import { JobPosting } from 'types/JobPosting';

export function getJobPrimarySkills(job: Partial<JobPosting>) {
    if (!job.Skills) return JSON.parse(job.filter_primary_skills || "[]").map(name => ({ name }));
    return job.Skills.filter(s => s.job_skill.is_primary);
  }
  
  export function getJobSecondarySkills(job: Partial<JobPosting>) {
    if (!job.Skills) return JSON.parse(job.filter_secondary_skills || "[]").map(name => ({ name }));
    return job.Skills.filter(s => !s.job_skill.is_primary);
  }
  