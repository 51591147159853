import { Auth } from 'aws-amplify';

import stageUtils from './stageUtils';
import utils from './utils';

const getAuthHeader = async () => {
  const session = await Auth.currentSession();

  if (
    !utils.isNull(session) &&
    !utils.isNull(session.getAccessToken()) &&
    !utils.isNull(session.getAccessToken().getJwtToken())
  ) {
    return {
      Authorization: session.getAccessToken().getJwtToken(),
    };
  } else return null;
};

const getAPIName = () => {
  const awsConfig = stageUtils.getAWSConfig();
  return awsConfig.API.endpoints[0].name;
};

const getAuthAPIName = () => {
  const awsConfig = stageUtils.getAWSConfig();
  return awsConfig.API.endpoints[1].name;
};

const getCompilerAPIName = () => {
  return 'http://ec2-3-7-45-15.ap-south-1.compute.amazonaws.com:3000';
};

const getUserCred = () => {
  return {
    key: 'AKIAS5EI4PVGJIWPE77W',
    secret: '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',
  };
};

export default {
  getAuthHeader,
  getAPIName,
  getCompilerAPIName,
  getUserCred,
  getAuthAPIName,
};
