import { API } from "aws-amplify";

import awsUtils from "utils/awsUtils";

async function getDashboardStats() {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/admin-panel/dashboard`;
  const myInit = {
    headers: { ...authHeader },
    response: true,
  };
  const result = await API.get(apiName, path, myInit);
  return result;
}

async function getManangement() {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/admin-panel/management/`;
  const myInit = {
    headers: { ...authHeader },
    response: true,
  };
  const result = await API.get(apiName, path, myInit);
  return result?.data;
}

export default {
    getDashboardStats,
    getManangement
}