import { ReactElement, useState } from 'react';
import IndustryList from './components/List.Industry';
import DebouncedInput from './components/DebouncedInput';

export default function IndustryViewPage(): ReactElement {
  const [keyword, setKeyword] = useState<string>('');

  return (
    <div className='pt-8 px-6'>
      <div className='mb-4'>
        <DebouncedInput
          placeholder='Search through our job titles, skills and industries we support'
          onChange={setKeyword}
          value={keyword}
        />
      </div>
      <IndustryList keyword={keyword} />
    </div>
  );
}
