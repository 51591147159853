import IconV2 from "components/V2/Icons/IconV2";
import InputV2 from "components/V2/Input/InputV2";
import RangeSelect from "components/V2/MultiSelectDropdown/RangeSelect";
import { MultiSelectDropdownV2 } from "components/V2/MultiSelectDropdown/MultiSelectDropdownV2";
import ENUMS from "constants/enums";
import { useEffect, useState } from "react";
import { FreelancerProfile } from "types";
import { averageRating } from "utils/talent/utils";

interface ITalentFilter {
    data?: FreelancerProfile[],
    onFilter?: (_: FreelancerProfile[]) => void,
    className?: string,
}

const onsiteData = ENUMS.location.map((value, index) => ({ value, index }));
const expData = ENUMS.level.map((value, index) => ({ value, index }));
const interviewRatingData = ENUMS.interviewRating.map((value, index) => ({ value, index }));
const availabilityData = [
    { index: 0, value: "Available" },
    { index: 1, value: "Hired" }
]

export function getTalentAvailability(talent: FreelancerProfile) {
    return talent?.JobsOffers?.every(jo => !jo.is_ongoing) ? availabilityData[0].value : availabilityData[1].value;
}


const TalentFilter = ({ data = [], className = "", onFilter }: ITalentFilter) => {

    const [searchValue, setSearchValue] = useState<string>("");
    const [compensation, setComensation] = useState<number[]>([0, Number.POSITIVE_INFINITY]);
    const [onSite, setOnSite] = useState<IData[]>([]);
    const [experience, setExperience] = useState<IData[]>([]);
    const [available, setAvailable] = useState<IData[]>([]);
    const [interviewRating, setInterviewRating] = useState<number[]>([0, 5]);

    // Apply filters
    const sValue = searchValue.toLowerCase();

    useEffect(() => {
        if (!onFilter) return

        // a) Search filter
        let filtered = data.filter(t =>
            `${t.User.first_name?.toLowerCase()} ${t.User.last_name?.toLowerCase()}`.includes(sValue) ||
            t.skills?.some(s => s.name?.toLowerCase().includes(sValue)) ||
            t.Role?.name?.toLowerCase().includes(sValue) ||
            t.User.email_id?.toLowerCase().includes(sValue)
        )

        // b) Compensation range
        if (compensation) filtered = filtered.filter(t => (t.hourly_rate || 0) >= compensation[0] && (t.hourly_rate || 0) <= compensation[1])

        // c) OnSite
        if (onSite.length) filtered = filtered.filter((t) => {
            const onSiteOptions = JSON.parse(t.roles_open_to || '[]');
            for (let i = 0; i < onSiteOptions.length; i++) {
                for (let j = 0; j < onsiteData.length; j++) {
                    if (onSiteOptions[i].value.toLowerCase() === onsiteData[j].value.toLowerCase()) return true;
                }
            }
            return false;
        }
        )

        // d) Experience
        if (experience.length) filtered = filtered.filter((t) =>
            experience.some(exp => exp.value === t.level_of_experience))

        // e) Interview Rating
        if (interviewRating) filtered = filtered.filter((t) => {
            const rating = averageRating(t.User?.AssessmentInfo?.rating)
            return rating >= interviewRating[0] && rating <= interviewRating[1];
        })

        // f) Availability
        if (available.length) filtered = filtered.filter((t) =>
            available.some(av => av.value === getTalentAvailability(t))
        )

        onFilter(filtered)

    }, [searchValue, compensation, onSite, experience, available, interviewRating, data])


    return (

        <div className={`flex flex-col ${className}`}>
            <InputV2 value={searchValue} onChange={(e) => setSearchValue(e.target.value)} icon={
                <IconV2
                    iconType="SEARCH"
                    iconClassName="w-6 h-6 translate-y-[1px]"
                />
            } placeholder="Search for talent by skills, role, email or name " />

            <div className="flex gap-x-[13px] mt-[13px] mb-[13px]">
                <RangeSelect
                    buttonText="Compensation"
                    buttonClassName="w-[160px] h-8 text-xs font-inter"
                    onChange={setComensation}
                    suffix="/hr"
                    prefix="$"
                />

                <MultiSelectDropdownV2
                    filterDropdown
                    buttonText="Onsite/Remote"
                    selectedData={onSite}
                    onChange={setOnSite}
                    listData={onsiteData}
                    buttonClassName="w-[160px] h-8 text-xs font-inter"
                    listClassName="w-[300px]"
                />

                <MultiSelectDropdownV2
                    filterDropdown
                    buttonText="Experience"
                    selectedData={experience}
                    onChange={setExperience}
                    listData={expData}
                    buttonClassName="w-[130px] h-8 text-xs font-inter"
                    listClassName="w-[300px]"
                />

                <RangeSelect
                    buttonText="Interview rating"
                    buttonClassName="w-[160px] h-8 text-xs font-inter"
                    onChange={setInterviewRating}
                    min={interviewRatingData[0].value}
                    max={interviewRatingData[interviewRatingData?.length - 1].value}
                />

                <MultiSelectDropdownV2
                    filterDropdown
                    buttonText="Availability"
                    selectedData={available}
                    onChange={setAvailable}
                    listData={availabilityData}
                    buttonClassName="w-[130px] h-8 text-xs font-inter"
                    listClassName="w-[300px]"
                />


            </div>

        </div>
    );
}

export default TalentFilter;