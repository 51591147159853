import IconV2 from "components/V2/Icons/IconV2";
import { default_avatars } from "constants/default_images";
import { User } from "types";

interface IUserAvatarLogo {
  user: User;
  className?: string;
  redBadgeclassName?: string;
  withRedBadge?: boolean;
}

const UserAvatarLogo = ({
  user,
  className = "",
  withRedBadge = false,
  redBadgeclassName = "",
}: IUserAvatarLogo) => {
  const { avatar } = user;
  let link;
  let image;
  if (!avatar)
    image = (
      <IconV2 iconType="OUTDEF-LOGO" iconClassName={`w-[45px] h-[45px] mx-auto ${className}`} />
    );
  else if (isNaN(Number(avatar))) link = avatar;
  else link = default_avatars[avatar];

  if (link)
    image = <img src={link} alt="avatar" className="object-cover rounded-full w-full h-full" />;

  return (
    <div>
      <div className={`w-[45px] h-[45px] overflow-hidden rounded-full mx-auto ${className}`}>
        <div className="h-full">{image}</div>
      </div>
      {withRedBadge && (
        <img
          src="/red-logo.png"
          alt="red badge"
          className={`mt-[-25px] w-[45px] ${redBadgeclassName}`}
        />
      )}
    </div>
  );
};

export default UserAvatarLogo;
