import Loader from "components/V2/Loader/Loader";
import { IReputationMetrics } from "types/Reputation";

const Metrics = ({ metrics }: { metrics?: IReputationMetrics }) => {
    if (!metrics) return <Loader className="w-[200px] h-[200px]" />
    return (<div className="w-full flex gap-5">
        <BlackCard title="Verification analytics">
            <div className="flex gap-5">
                <Card title="Submitted " amount={metrics.total.SUBMITTED} />
                <Card title="Denied" amount={metrics.total.DENIED} />
            </div>
        </BlackCard>
        <BlackCard className="w-[27%] shrink-0" title="Verifications assigned">
            <Card className="text-white !bg-blue2" title="Verifications assigned to you" amount={metrics.total.ASSIGNED_TO_YOU} />
        </BlackCard>
    </div>);
}


const BlackCard = ({ title, children, className = "" }) => <div className={"w-full bg-[#3B3B3B] rounded-3xl p-5 " + className}>
    <p className="font-poppins text-base font-semibold text-white mb-5">{title}</p>
    {children}
</div>

const Card = ({ amount, title, className = "" }: { amount: string | number, title: string, className?: string }) => <div className={"w-full rounded-lg bg-[#ECECFF] text-blue2 p-5 " + className}>
    <p className="font-inter text-sm font-semibold mb-2">{title}</p>
    <p className="font-poppins text-[36px] font-semibold">{amount}</p>
</div>


export default Metrics;