type LabelProps = {
    children: React.ReactNode;
    htmlFor?: string;
    className?: string;
};
export const Label = (props: LabelProps) => {
    const { children, htmlFor, className } = props;
    return (
        <label
            className={`text-xs inline-block font-normal font-poppins ${className}`}
            htmlFor={htmlFor}
        >
            {children}
        </label>
    );
};

export default Label;