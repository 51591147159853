import { Routes, Route, Navigate } from 'react-router-dom'
import TalentsOverview from 'views/application/talent/overview'

import pathUtils from 'utils/pathUtils'
import TalentDetail from 'views/application/talent/detail'

const routes = [
  {
    path: pathUtils.TALENT_OVERVIEW,
    element: <TalentsOverview />,
  },
  {
    path: pathUtils.TALENT_DETAIL,
    element: <TalentDetail />,
  },
  {
    path: '*',
    element: <Navigate to={pathUtils.TALENT_OVERVIEW} replace />,
  },
]

const TalentRoutes: React.FC = () => {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
    </Routes>
  )
}

export default TalentRoutes
