import React, { useRef } from "react";

interface IPaginatedDiv {
    className?: string;
    children?: any;
    onLimit?: () => void; // Funtion to call when we reach scroll limit
    count?: number; // Max amount of items to paginated
    currentCount?: number; // currentAmount of items fetched
    loading?: boolean;
}

const PaginatedDiv = ({ className = "", children, count = 0, currentCount = 0, loading, onLimit = () => null }: IPaginatedDiv) => {
    const ref = useRef(null);

    const handleScroll = () => {
        if (!ref.current) return;
        if ((currentCount >= count) || loading) return;
        const { scrollTop, scrollHeight, clientHeight } = ref.current;
        if (scrollTop + clientHeight > scrollHeight - 200) onLimit();
    };

    return (
        <div
            className={"overflow-x-hidden overflow-y-scroll h-full " + className}
            onScroll={handleScroll}
            ref={ref}
        >
            {children}
        </div>
    );
};

export default PaginatedDiv;
