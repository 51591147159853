import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import boardAPI from "network/board";
import { IBoard } from "./types";

export default function useBoardDetail() {
  const { id } = useParams();
  const [board, setBoard] = useState<IBoard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  console.log("param: ", id);

  const fetchBoardById = async () => {
    try {
      setIsLoading(true);
      const response = await boardAPI.getBoard(id);
      if (!response?.question) {
        throw new Error(JSON.stringify(response));
      }

      setBoard([response.question]);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBoardById();
  }, [id]);

  return { board: board[0], isLoading };
}
