const JobIcon = (props: INavIcon) => {
  const fillColor = props.light ? "#FFFFFF" : "#2F3454";

  return (
    <svg
      width="30"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 0C5.38 0 3.25 2.13 3.25 4.75C3.25 7.32 5.26 9.4 7.88 9.49C7.96 9.48 8.04 9.48 8.1 9.49C8.12 9.49 8.13 9.49 8.15 9.49C8.16 9.49 8.16 9.49 8.17 9.49C10.73 9.4 12.74 7.32 12.75 4.75C12.75 2.13 10.62 0 8 0Z"
        fill={fillColor}
      />
      <path
        d="M13.08 12.1499C10.29 10.2899 5.73996 10.2899 2.92996 12.1499C1.65996 12.9999 0.959961 14.1499 0.959961 15.3799C0.959961 16.6099 1.65996 17.7499 2.91996 18.5899C4.31996 19.5299 6.15996 19.9999 7.99996 19.9999C9.83996 19.9999 11.68 19.5299 13.08 18.5899C14.34 17.7399 15.04 16.5999 15.04 15.3599C15.03 14.1299 14.34 12.9899 13.08 12.1499Z"
        fill={fillColor}
      />
    </svg>
  );
};

export default JobIcon;
