import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import { useDispatch } from "react-redux";
import { AppDispatch } from "app/store";
import pathUtils from "utils/pathUtils";
import { toast } from "react-toastify";
import InvoiceOverview from "views/application/invoice/overview";
import InvoiceDetail from "views/application/invoice/detail";
import { getInvoiceList } from "redux/slices/invoice";

const routes = [
  {
    path: pathUtils.INVOICE_OVERVIEW,
    element: <InvoiceOverview />,
  },
  {
    path: pathUtils.INVOICE_DETAILS,
    element: <InvoiceDetail />,
  },
];

const InvoiceRoutes: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const fetchData = async () => {
    try {
      await dispatch(getInvoiceList());
    } catch (e) {
      toast.error("Error while retrieving invoice data");
      console.error(e);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
    </Routes>
  );
};

export default InvoiceRoutes;
