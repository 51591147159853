import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'

import { FreelancerProfile } from 'types'
import TalentTable from './components/TalentTable'
import TalentFilterPaginated, {
  ITalentFilterGet,
} from '../components/TalentFilterPaginated'
import PagesBar from 'components/V2/Pagination/PagesBar'
import Loader from 'components/V2/Loader/Loader'
import { Sort } from 'types/Sort'
import { ITalentFilters } from 'types/Filters/TalentFilters'

export const PAGE_SIZE = 25

const TalentsOverview = () => {
  const [talents, setTalents] = useState<FreelancerProfile[] | undefined>()
  const [getTalent, setGetTalent] = useState<ITalentFilterGet>()
  const [count, setCount] = useState<number | undefined>()
  const [sort, setSort] = useState<Sort | undefined>()
  // Some filters come from the table and not from the Filter compoenent
  const [tableFilters, setTableFilters] = useState<ITalentFilters>({});

  const fetchPage = useMemo(
    () =>
      async (offset: number): Promise<any[] | undefined> => {
        if (!getTalent) return
        try {
          const response = await getTalent(PAGE_SIZE, offset, undefined, sort && [sort], tableFilters)
          if (!response.success) throw new Error(JSON.stringify(response))
          return response.talents
        } catch (e) {
          toast.error('Error while fetching page')
          console.error(e)
        }
      },
    [getTalent, sort, tableFilters]
  )

  const fetchCount = useMemo(
    () => async () => {
      if (!getTalent) return
      try {
        const response = await getTalent(PAGE_SIZE, undefined, true, undefined, tableFilters)
        if (!response.success) throw new Error(JSON.stringify(response))
        setCount(response.count)
      } catch (e) {
        toast.error('Error while fetching total amount of talent')
        console.error(e)
      }
    },
    [getTalent, tableFilters]
  )

  useEffect(() => {
    setTalents(undefined)
    setCount(undefined)
    fetchCount()
  }, [getTalent, tableFilters])

  return (
    <div className="pt-[28x] w-full h-full flex flex-col px-[35px]">
      <div className="flex flex-col mt-[52px] w-full">
        <span className="text-[24px] font-poppins font-bold flex ">
          Talent Database:&nbsp;
          {count === undefined ? <Loader className='w-[30px] h-[30px]' /> : <span>{count}</span>}
        </span>
        {/* Filters */}
        <TalentFilterPaginated onFilter={(f) => setGetTalent(() => f)} />
      </div>

      <div className="flex flex-col flex-1 w-full overflow-auto mt-[21px] items-center pb-[100px]">
        <TalentTable onFiltersChange={setTableFilters} onSortChange={setSort} data={talents} refresh={setTalents} />

        <PagesBar
          page_size={PAGE_SIZE}
          className="mt-[8px]"
          count={count}
          fetchPage={fetchPage}
          onLoading={() => setTalents(undefined)}
          onPageChange={(data) => setTalents(data)}
        />
      </div>
    </div>
  )
}

export default TalentsOverview
