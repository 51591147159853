import { useState } from "react";
import boardAPI from "network/board";

interface ICuratedStatus {
  curated: boolean;
  hidden: boolean;
}

export default function useBoardBlock(props: ICuratedStatus, onRefresh: () => void) {
  const [status, setStatus] = useState<ICuratedStatus>(props);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(false);

  const handleClick = (target: "curated" | "hidden") => {
    setIsDone(false);
    setStatus((prevStatus) => {
      const updatedStatus = { ...prevStatus, [target]: !prevStatus[target] };

      if (updatedStatus[target]) {
        // If the target status is being set to true,
        // ensure the other status is set to false
        return {
          curated: target === "curated" ? true : false,
          hidden: target === "hidden" ? true : false,
        };
      }

      return updatedStatus;
    });
  };

  const handleSaveClick = async (boardID: number) => {
    try {
      setIsPending(true);
      const response = await boardAPI.curateBoard({
        question_id: boardID,
        isCurated: status.curated,
        isHidden: status.hidden,
      });

      await onRefresh();

      console.log("response: ", response);
      setIsDone(true);
    } catch (e) {
      console.error(e);
    } finally {
      setIsPending(false);
    }
  };

  const isEnabled = props.curated !== status.curated || props.hidden !== status.hidden;

  return { status, isEnabled, isPending, isDone, handleClick, handleSaveClick };
}
