import { RootState } from 'app/store';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

export default function RoleList(): ReactElement {
  const roles = useSelector((root: RootState) => root.prototype.roles);

  return (
    <div className='w-full h-fit border border-light-gray rounded-md overflow-hidden'>
      <div className='h-8 bg-[#F0F1F2] leading-8 text-[#8A8A8A] font-semibold text-base font-inter px-4'>
        Job titles/roles
      </div>
      <ul className='max-h-[500px] overflow-y-auto'>
        {roles.map((role, index) => (
          <li
            key={role.id}
            className='h-8 font-inter font-semibold leading-8 px-4 border-b border-b-light-gray truncate last:border-none'
          >
            {index + 1}. {role.name}
          </li>
        ))}
      </ul>
    </div>
  );
}
