import Amplify from 'aws-amplify';
import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import AppRoutes from 'routes';

import { loadAuth } from 'redux/slices/authentication';
import { useAppDispatch } from 'redux/hooks/redux-hooks';

import stageUtils from 'utils/stageUtils';
import 'react-loading-skeleton/dist/skeleton.css';

const awsconfig = stageUtils.getAWSConfig();
Amplify.configure(awsconfig);

const App: React.FC = () => {
  const dispatch = useAppDispatch();

  const init = async () => {
    await dispatch(loadAuth());
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Router>
      <AppRoutes />
    </Router>
  );
};

export default App;
