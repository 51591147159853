import useBoard from "hooks/useBoard";
import BoardBlock from "./components/BoardBlock";
import DebouncedInput from "./components/DebouncedInput";
import { SearchIcon } from "./components/icons";

import { useAppDispatch, useAppSelector } from "redux/hooks/redux-hooks";
import { ColorRing } from "react-loader-spinner";
import { useEffect } from "react";
import { getTalentBannedFromCommunity } from "redux/slices/banned_talent";
import Loader from "components/V2/Loader/Loader";
import BanCard from "./components/BanCard";

export default function BoardManagement() {
  const { data, isLoading, tab, totalCount, setTab, onRefresh, setQuery } = useBoard();
  const dispatch = useAppDispatch();
  const banned_from_community = useAppSelector((root) => root.banned_talent.from_community);
  const banned_profiles = banned_from_community ? Object.values(banned_from_community) : null;
  const count = tab === "banned users" ? (banned_profiles?.length || 0) : data.length;

  // Update banned slice if we enter this page
  useEffect(() => { dispatch(getTalentBannedFromCommunity()) }, [])

  return (
    <main className="pt-7 flex-1 overflow-y-auto h-screen">
      <header className="px-2 lg:px-16 max-w-[1090px]">
        <h1 className="mb-4 font-poppins text-2xl font-bold">Community boards</h1>

        <DebouncedInput
          placeholder="Search through talent by name, email or skills"
          icon={<SearchIcon />}
          onChange={setQuery}
        />
        <p className="mt-9 font-poppins text-base font-semibold">
          Total boards
          <span className="pl-5 font-poppins text-base font-semibold">{totalCount}</span>
          <span className="block mt-5">
            Select boards to be added to curated boards by checking off the box and pressing save.
            To remove selected curated boards go to the “Curated boards” tab and uncheck the box and
            press save.
          </span>
        </p>

        {/* Tabs */}
        <div className="mt-12 font-inter text-base font-semibold flex flex-wrap gap-[12px] md:gap-x-[60px]">
          {["all boards", "curated boards", "hidden boards", "banned users"].map((item) => (
            <span
              className={`first-letter:uppercase cursor-pointer border-b-2 ${tab === item ? "border-secondary" : "border-transparent"
                }`}
              onClick={() => setTab(item)}
              key={item}
            >
              {item}{tab === item ? `(${count})` : ""}
            </span>
          ))}
        </div>
      </header>

      {(!tab || tab.endsWith("boards")) &&
        <div className="pl-2 lg:pl-16 w-full max-w-[1090px] 2xl:max-w-[1440px]">
          {data?.length > 0 && (
            <section className="mt-10 flex flex-col lg:grid lg:grid-cols-2 lg:gap-x-[120px] gap-y-[60px]">
              {data.map((board) => (
                <BoardBlock key={board.id} board={board} onRefresh={onRefresh} />
              ))}
            </section>
          )}
          {isLoading && (
            <div className="w-full h-[600px] flex items-center justify-center">
              <ColorRing
                visible={true}
                height="120"
                width="120"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass=""
                colors={["#FF8134", "#FF5757", "#5F5FFF", "#2F3454", "transparent"]}
              />
            </div>
          )}
        </div>
      }

      {tab === "banned users" && (
        <div className="pl-2 lg:pl-16 flex flex-wrap gap-[20px] pt-[50px]">
          {banned_profiles ?
            banned_profiles.map(p => <BanCard key={p.freelancer_id} profile={p} />)
            :
            <Loader />
          }
        </div>
      )}
    </main>
  );
}
