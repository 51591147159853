import BadgeV2 from "components/V2/Badges/BadgeV2";
import { IBoard } from "hooks/types";
import { User } from "types";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";
import { CommentIcon, LikeIcon, ViewIcon } from "./icons";
import { ReactNode } from "react";

export default function BoardCard({
  board,
  preview = false,
}: {
  board: IBoard;
  preview?: boolean;
}) {
  return (
    <div className="flex flex-col h-full border border-dark-gray rounded-lg bg-white p-5">
      <div className="flex items-center gap-x-3">
        <UserAvatarLogo user={board.User as User} className="!w-[60px] !h-[60px]" />
        <div className="pt-1">
          <span className="text-[#201000] font-poppins text-base font-semibold">
            {board.User.first_name} {board.User.last_name}
          </span>
          <br />
          <span className="text-[#A9ACB1] text-sm font-inter font-semibold pt-[10px]">
            {board.freelancerProfile?.Role?.name}
          </span>
        </div>
      </div>

      <h3 className="mt-5 text-[#201000] font-poppins text-xl font-semibold">{board.heading}</h3>
      <div className="my-5 w-full h-[220px] rounded-lg overflow-hidden">
        {board?.images?.length > 0 ? (
          <img src={board.images[0]} className="w-full h-full object-cover" />
        ) : (
          <span className="flex w-full h-[220px] items-center justify-center bg-light-gray">
            No Images
          </span>
        )}
      </div>

      <p className={`font-inter text-base ${preview ? "truncated-lines" : ""}`}>{board.content}</p>
      <div className="flex my-5 flex-wrap gap-x-4 gap-y-[10px]">
        {board.hashtags.map((tag) => (
          <BadgeV2
            color="pink"
            addClass="px-4 py-2 text-base leading-4 font-semibold font-inter"
            key={tag.id}
          >
            {tag.name}
          </BadgeV2>
        ))}
      </div>

      <div className="mt-auto flex justify-between">
        <IconWrapper>
          <CommentIcon />
          {board.answers.length}
        </IconWrapper>

        <IconWrapper>
          <LikeIcon />
          {board.questionLikes.length}
        </IconWrapper>

        <IconWrapper>
          <ViewIcon />
          {board.views}
        </IconWrapper>
      </div>
    </div>
  );
}

function IconWrapper({ children }: { children: ReactNode }) {
  return (
    <div className="flex gap-x-3 items-center font-inter text-sm font-semibold text-[#8A8A8A]">
      {children}
    </div>
  );
}
