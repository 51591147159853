import { ReactElement, ReactNode, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

export default function DatabaseLayout({
  children,
}: {
  children?: ReactNode;
}): ReactElement {
  const location = useLocation();

  return (
    <main className='w-full'>
      <div className='sticky top-0 w-full h-[200px] bg-theme pt-10 px-10 font-poppins font-bold text-2xl text-white flex flex-col justify-between'>
        Database Management
        <div className='w-full h-11 bg-white rounded-t-lg border-b border-[#F0F1F2]'>
          <ol className='font-inter font-semibold flex gap-x-20 text-black text-base pt-3 px-8 pb-2'>
            {data.map((elem) => (
              <li
                key={elem.index}
                className={`relative ${
                  location.pathname === elem.link
                    ? 'border-b-4 border-theme'
                    : ''
                }`}
              >
                <NavLink to={elem.link}>{elem.text}</NavLink>
              </li>
            ))}
          </ol>
        </div>
      </div>
      <div className='px-10 pb-10'>
        <div className='w-full h-[750px] bg-white rounded-b-lg overflow-scroll'>
          {children}
        </div>
      </div>
    </main>
  );
}

interface ISubRoute {
  index: string;
  link: string;
  text: string;
}

const data: ISubRoute[] = [
  {
    index: '0x00',
    link: '/database',
    text: 'All',
  },
  {
    index: '0x01',
    link: '/database/industries',
    text: 'Industries',
  },
  {
    index: '0x02',
    link: '/database/roles',
    text: 'Job titles/roles',
  },
  {
    index: '0x03',
    link: '/database/skills',
    text: 'Skills',
  },
];
