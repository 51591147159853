import { ReactElement, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import IconV2 from 'components/V2/Icons/IconV2';
import DropdownV2 from 'components/V2/Dropdown/DropdownV2';
import InputV2 from 'components/V2/Input/InputV2';
import Button from 'components/Button/ButtonV2';
import useAddSkillModal from '../hooks/useAddSkillModal';

interface PropsType {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function AddSkillModal(props: PropsType): ReactElement {
  const {
    pending,
    data,
    type,
    validation,
    skillName,

    addSkillHandler,
    setSkillName,
    setType,
    closeHandler,
  } = useAddSkillModal(props.setOpen);

  return (
    <Transition appear show={props.open} as={Fragment}>
      <Dialog as='div' className='relative z-10' onClose={closeHandler}>
        <Transition.Child
          as={Fragment}
          enter='ease-out duration-300'
          enterFrom='opacity-0'
          enterTo='opacity-100'
          leave='ease-in duration-200'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <div className='fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm' />
        </Transition.Child>

        <div className='fixed inset-0 overflow-y-auto'>
          <div className='flex min-h-full items-center justify-center p-4 text-center'>
            <Transition.Child
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 scale-95'
              enterTo='opacity-100 scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 scale-100'
              leaveTo='opacity-0 scale-95'
            >
              <Dialog.Panel className='w-full max-w-md transform overflow-hidden rounded-lg bg-white p-[24px_36px_36px_36px]'>
                <button onClick={closeHandler} className='flex ml-auto mb-7'>
                  <IconV2 iconType={'CLOSE'} iconClassName='w-5 h-5' />
                </button>

                <Dialog.Title as='h3' className='text-xl font-semibold'>
                  Add a new skill
                </Dialog.Title>
                <div className='mt-6 flex flex-col items-center'>
                  <label className='font-poppins text-xs'>Select role </label>
                  <DropdownV2
                    disabled={pending}
                    placeholder='Select a category'
                    normalCase
                    selectedValue={type}
                    data={data.map((role) => {
                      return {
                        index: role.role_id,
                        value: role.name,
                      };
                    })}
                    onChange={(idx) => setType(data[idx].name)}
                    className='mt-4 w-[300px] h-12 text-left'
                  />
                  <label className='mt-6 font-poppins text-xs'>skill</label>
                  <InputV2
                    disabled={pending}
                    value={skillName}
                    onChange={(e) => setSkillName(e.target.value)}
                    className='w-[300px] mt-4'
                    placeholder='Type in your job title or role'
                  />
                </div>

                <div className='pt-12 relative w-[300px] mx-auto flex justify-center'>
                  {type && skillName && (
                    <span className='w-[300px] text-left absolute top-1 font-inter font-semibold text-xs text-[#D32F2F]'>
                      {validation.message}
                      {validation.reference}
                    </span>
                  )}

                  <Button
                    className='w-[160px]'
                    loading={pending}
                    onClick={addSkillHandler}
                    disabled={validation.valid === 'INVALID'}
                  >
                    Add skill
                  </Button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}
