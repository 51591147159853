import { Routes, Route } from "react-router-dom";
import BoardManagement from "views/application/board";
import BoardDetailPage from "views/application/board/BoardDetailPage";

const routes = [
  {
    path: "/",
    element: <BoardManagement />,
  },
  {
    path: "/detail/:id",
    element: <BoardDetailPage />,
  },
];

const BoardManagementRoutes: React.FC = () => {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
    </Routes>
  );
};

export default BoardManagementRoutes;
