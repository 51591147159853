import { Routes, Route } from 'react-router-dom'

import pathUtils from 'utils/pathUtils'
import LinkedinUsersOverview from 'views/application/linkedin_users/overview'

const routes = [
  {
    path: pathUtils.LINKEDIN_USERS_OVERVIEW,
    element: <LinkedinUsersOverview />,
  },
]

const LinkedinUsersRoutes: React.FC = () => {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
    </Routes>
  )
}

export default LinkedinUsersRoutes
