import { useEffect, useMemo, useState } from "react";
import boardApi from "network/board";
import { IBoard } from "./types";
import { useSearchParams } from "react-router-dom";

type TTab = "hidden boards" | "curated boards" | "all boards" | "banned users";

export default function useBoard() {
  const [boards, setBoards] = useState<IBoard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [query, setQuery] = useState<string>("");

  const [searchParam, setSearchParam] = useSearchParams();

  const tab: TTab = (searchParam.get("tab") || "all boards") as TTab;

  const fetchBoards = async () => {
    try {
      setIsLoading(true);
      const response = await boardApi.getBoards({
        popular: false,
        query: {
          search: query,
        },
      });
      if (!response.success) throw new Error(JSON.stringify(response));
      setBoards(response?.active || []);
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBoards();
  }, [query]);

  const data = useMemo(() => {
    return boards.filter((board) =>
      tab === "curated boards" ? board.curated : tab === "hidden boards" ? board.hidden : true,
    );
  }, [searchParam, boards, query]);

  const setTab = (tab: string) => {
    setSearchParam(`tab=${tab}`);
  };

  return {
    data,
    isLoading,
    tab,
    totalCount: boards.length,
    setTab,
    onRefresh: fetchBoards,
    setQuery,
  };
}
