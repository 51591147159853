import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import moment from "moment";
import type { PayloadAction } from "@reduxjs/toolkit";
import applicationAPI from "network/application";
import { Invoice } from "types";

export const dueItems = ["Due to receipt", "Net 15 days", "Net 30 days"];

export interface InvoiceState {
  isLoading: boolean;
  confirming: boolean;
  invoices: Invoice[];
}

const initialState: InvoiceState = {
  isLoading: false,
  confirming: false,
  invoices: [],
};

export const getInvoiceList = createAsyncThunk("getInvoiceList", async () => {
  const result = await applicationAPI.getInvoiceList();
  return result;
});

export const confirmInvoice = createAsyncThunk("confirmInvoice", async (id: string) => {
  const result = await applicationAPI.confirmInvoice(id);
  return result;
});

export const invoiceSlice = createSlice({
  name: "invoice",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(getInvoiceList.pending, (state) => {
      state.isLoading = true;
      return state;
    });
    builder.addCase(getInvoiceList.rejected, (state) => {
      state.isLoading = false;
      return state;
    });
    builder.addCase(getInvoiceList.fulfilled, (state, action: PayloadAction<Invoice[]>) => {
      const payload = calculateDueDate(action.payload);
      state.invoices = payload;
      state.isLoading = false;
      return state;
    });
    builder.addCase(confirmInvoice.pending, (state) => {
      state.confirming = true;
      return state;
    });
    builder.addCase(confirmInvoice.rejected, (state) => {
      state.confirming = false;
      return state;
    });
    builder.addCase(confirmInvoice.fulfilled, (state, action: PayloadAction<Invoice>) => {
      const invoices = [...state.invoices];
      const { id, date_processed } = action.payload;
      const idx = invoices.findIndex((invoice) => invoice.id === id);
      invoices[idx] = {
        ...invoices[idx],
        date_processed,
      };
      state.invoices = invoices;
      state.confirming = false;
      return state;
    });
  },
});

const calculateDueDate = (invoices: Invoice[]) => {
  const data = [...invoices];
  invoices.forEach((invoice, idx) => {
    const date_issued = moment(invoice?.date_issued).utc();
    const currentDate = moment().utc();
    const index = dueItems.findIndex((item) => item.toLowerCase() === invoice?.date_due);
    const date_due = index * 15;
    const due_days = date_issued.diff(currentDate, "days") + date_due;
    data[idx] = {
      ...invoice,
      due_days,
    };
  });
  return data;
};

// Action creators are generated for each case reducer function
export const {} = invoiceSlice.actions;

export default invoiceSlice.reducer;
