import { FreelancerProfile } from "types";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";
import BanButton from "./BanButton";


const BanCard = ({ profile }: { profile: FreelancerProfile }) => {
    return (
        <div className="w-[300px]">
            <div className="flex items-center gap-x-3">
                <UserAvatarLogo user={profile.User} className="!w-[60px] !h-[60px]" />
                <div className="pt-1">
                    <span className="text-[#201000] font-poppins text-base font-semibold">
                        {profile.User.first_name} {profile.User.last_name}
                    </span>
                    <br />
                    <span className="text-[#A9ACB1] text-sm font-inter font-semibold pt-[10px]">
                        {profile.User.email_id}
                    </span>
                </div>
            </div>
            <BanButton className="w-full mt-[20px]" freelancer={profile} />
        </div>
    );
}

export default BanCard;