import { useState } from "react";
import { toast } from "react-toastify";
import DropdownV2 from "components/V2/Dropdown/DropdownV2";
import IconV2 from "components/V2/Icons/IconV2";
import ReputationApi from "network/reputation/reputation";
import { ReputationTag } from "network/reputation/types";
import { IReputation } from "types/Reputation";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";
import { assigneesData, statusIcons, statusTitles } from "views/application/reputation/constants";
import Loader from "components/V2/Loader/Loader";

interface ITable {
  data?: IReputation[];
  refresh: () => void;
  type: ReputationTag;
}

const Table = ({ data, refresh, type }: ITable) => {
  const [assigning, setAssigning] = useState<number>();

  const assign = async (index: number, rep_id: number) => {
    setAssigning(rep_id);
    try {
      const assignee_id = assigneesData[index].id;
      const response = await ReputationApi.assign(type, rep_id, assignee_id);
      const rep: IReputation = response.data;
      if (!rep) throw new Error(response);
      const old = data?.find(r => r.id === rep.id);
      if (!old) throw new Error("Old rep was not found");
      old.Assignee = rep.Assignee;
      old.assignee_id = rep.assignee_id;
      refresh();
    } catch (e) {
      toast.error('Error while assigning')
      console.error(e)
    }
    setAssigning(undefined);
  }

  return (<table className="w-full mt-5">
    <thead >
      <tr>
        <TH className="!rounded-tl-xl border-l-[1px] border-l-black ">Order</TH>
        <TH>Profile</TH>
        <TH>Email</TH>
        <TH>Status</TH>
        <TH>Assign</TH>
        <TH className="!rounded-tr-xl">Docs sumbitted</TH>
      </tr>
    </thead>
    <tbody >
      {data?.map((r) =>
        <tr key={r.id}>
          <TD className="border-l-[1px]">{r.id}</TD>
          <TD>
            <div className="flex gap-3 items-center self-start ">
              <UserAvatarLogo className="w-[45px]" user={r.Freelancer.User} />
              <p> {r.Freelancer.User.full_name}</p>
            </div>
          </TD>
          <TD>{r.Freelancer.User.email_id}</TD>
          <TD className={r.status === "DENIED" ? "text-coral-red" : "text-blue2"}>{statusTitles[r.status]}</TD>
          <TD className="flex-col gap-3">
            <div className="flex gap-[26px] items-center">
              <p>Verifier</p>
              {assigning === r.id ?
                <Loader className="w-5 h-5" /> :
                <IconV2 iconType={statusIcons[r.status]} />
              }
            </div>
            <DropdownV2
              className="w-fit !h-[37px]"
              disabled={r.status !== "SUBMITTED" || assigning === r.id}
              buttonClass="w-[100px] !h-full pr-[12px] !font-inter !text-[14px] !font-bold !border-none !bg-odf-light"
              selectedIndex={assigneesData.findIndex(a => a.id === r.assignee_id)}
              onChange={(index) => assign(index, r.id)}
              data={assigneesData}
            />
          </TD>
          <TD className="justify-end">
            <a href={`/reputation/detail/${r.id}/${type}`} target="_blank" className="flex gap-3">
              View all
              <IconV2 iconType="TRIANGLE-ARROW-SOLID" iconClassName="rotate-90 w-4 h-4 ml-3 mt-[2px]" />
            </a>
          </TD>
        </tr>
      )}
    </tbody>
  </table>);
}


const TH = ({ children, className = "" }) => (
  <th className="!px-[0.5px] first:!pl-0">
    <div className={"bg-[#3B3B3B] p-5 h-[74px] font-poppins text-white font-semibold text-sm text-start \
     flex items-center " + className}>
      {children}
    </div>
  </th>)

const TD = ({ children, className = "" }) => (
  <td className="!p-0 !border-0">
    <div className={"p-5 min-h-[113px] font-inter font-semibold text-sm text-start \
        border-black border-r-[1px] border-b-[1px] flex  " + className} >
      {children}
    </div>
  </td>)

export default Table;