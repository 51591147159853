import { ColorRing } from "react-loader-spinner";
import { Link } from "react-router-dom";

import Button from "components/Button/ButtonV2";
import { IBoard } from "hooks/types";
import BoardCard from "./BoardCard";
import useBoardBlock from "hooks/useBoardBlock";
import BanButton from "./BanButton";


export default function BoardBlock({ board, onRefresh }: { board: IBoard; onRefresh: () => void }) {
  const { status, isEnabled, isPending, isDone, handleSaveClick, handleClick } = useBoardBlock(
    board,
    onRefresh,
  );

  // For some stupid reason boards do not have the users attached to the freelancer so add it 
  const ban_profile = board.freelancerProfile;
  ban_profile.User = board.User;

  return (
    <div className="flex flex-col">
      <div className="flex flex-col md:flex-row max-md:gap-[10px] md:items-center mb-5">
        <label className="font-inter text-sm font-semibold flex items-center gap-x-3">
          <input type="checkbox" checked={status.curated} onChange={() => handleClick("curated")} />
          Add to curated
        </label>
        <label className="md:ml-10 font-inter text-sm font-semibold items-center flex gap-x-3">
          <input type="checkbox" checked={status.hidden} onChange={() => handleClick("hidden")} />
          Add to hidden
        </label>

        <Button
          onClick={() => handleSaveClick(board.id)}
          className="md:ml-auto w-fit px-5 flex items-center gap-x-2 h-10"
          disabled={!isEnabled || isPending}
        >
          {isPending && (
            <ColorRing
              visible={true}
              height="20"
              width="20"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={["#FF8134", "#FF5757", "#5F5FFF", "#2F3454", "transparent"]}
            />
          )}
          Save{isDone ? "d" : ""}
        </Button>
      </div>

      <BanButton onBan={onRefresh} freelancer={ban_profile} />

      <Link
        to={`/board/detail/${board.id}`}
        className="h-full mt-[20px] hover:scale-[101%] transition-transform duration-200"
      >
        <BoardCard board={board} preview />
      </Link>
    </div>
  );
}
