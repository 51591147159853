import { ReactElement } from 'react';

import IndustryList from './components/List.Industry';
import RoleList from './components/List.Role';
import SkillList from './components/List.Skill';

export default function ViewAllPage(): ReactElement {
  return (
    <>
      <div className='pt-8 px-6'>
        <div className='w-full flex gap-x-2'>
          <IndustryList />
          <RoleList />
          <SkillList />
        </div>
      </div>
    </>
  );
}
