import * as Sentry from '@sentry/react';
import { useState, useEffect } from 'react';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { useAppDispatch } from 'redux/hooks/redux-hooks';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import OnboardingAPI from 'network/onboarding';
import Button from 'components/Button/ButtonV2';
import LogoIcon from 'assets/icons/logo.svg';
import AuthUtils from 'utils/authUtils';
import Heading from 'components/Heading/HeadingV2';

import { loadAuth } from 'redux/slices/authentication';
import { clearInfo, setItem } from 'utils/storageUtils';
import InputV2 from 'components/V2/Input/InputV2';

const LoginPanel: React.FC = () => {
  const [loginInfo, setLoginInfo] = useState({
    email: '',
    password: '',
  });

  useEffect(() => {
    document.title = 'Login | Outdefine';
  }, []);

  const [isLoading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleGoogleSignIn = async (e: any) => {
    e.preventDefault();
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { email, password } = loginInfo;
    if (email !== '' && password !== '') {
      setItem('email', email);
    } else {
      clearInfo();
    }
    try {
      setLoading(true);
      const user = await Auth.signIn({
        username: email,
        password: password,
      });
      const userData = await OnboardingAPI.getUserDetail();
      if (user && userData?.success) {
        dispatch(loadAuth());
        navigate('/welcome');
      } else {
        toast.error('User does not exist on database', { theme: 'colored' });
      }

      setLoading(false);
    } catch (err: any) {
      Sentry.captureException(err);
      console.log('login err: ', err);
      setLoading(false);
      toast.error(AuthUtils.getAuthError(err), { theme: 'colored' });
      /**
       * if user is not confirmed yet, bring to /confirmUser screen
       */
      if (err.code === 'UserNotConfirmedException') {
        navigate('/confirmUser');
      }
    }
  };

  return (
    <div className='max-w-[275px] flex flex-col w-full md:w-[275px]'>
      <img src={LogoIcon} alt='Equi' width={42} className='hidden md:block' />
      <Heading
        className='font-bold text-[24px] text-black pt-6 pb-4 hidden md:block'
        variant='h5'
      >
        Admin Login
      </Heading>
      <form onSubmit={handleSubmit} autoComplete='off'>
        <InputV2
          placeholder='Email'
          className='mt-3 font-inter text-xs'
          name='email'
          type='email'
          value={loginInfo.email}
          onChange={(e: any) =>
            setLoginInfo({ ...loginInfo, email: e.target.value })
          }
          required
        />
        <InputV2
          placeholder='Password'
          className='mt-3 mb-3 font-inter text-xs'
          name='password'
          type='password'
          value={loginInfo.password}
          onChange={(e: any) =>
            setLoginInfo({ ...loginInfo, password: e.target.value })
          }
          required
        />
        <div className='flex items-center justify-center mt-4'>
          <Button type='submit' className='w-full' loading={isLoading}>
            Log in
          </Button>
        </div>
      </form>
    </div>
  );
};

export default LoginPanel;
