
import jobAPI from "network/job"
import Button from "components/Button/ButtonV2";
import ModalV2 from "components/Modal/ModalV2";
import BadgeV2 from "components/V2/Badges/BadgeV2";
import IconV2 from "components/V2/Icons/IconV2";
import usePrototypeUtils from "hooks/usePrototypeUtils";
import { useState } from "react";
import { FreelancerProfile } from "types";
import { Company } from "types/Company";
import { JobPosting } from "types/JobPosting";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";
import CompanyLogo from "../../components/CompanyLogo";
import { toast } from "react-toastify";
import { getJobPrimarySkills } from "utils/jobUtils";

interface ISubmitRecommendationModal {
    isOpen: boolean,
    onClose: () => void,
    recommendatedTalent: FreelancerProfile[],
    job: JobPosting,
    company: Company
}

const SubmitRecommendationModal = ({ isOpen, onClose, job, company, recommendatedTalent }: ISubmitRecommendationModal) => {

    const { getSkillsFromJSON } = usePrototypeUtils();
    const [submitting, setSubmitting] = useState<boolean>(false);

    const onSubmit = async () => {
        setSubmitting(true);
        try {
            const data = recommendatedTalent.map(({ freelancer_id }) => ({ freelancer_id, job_id: job.id }))
            const response = await jobAPI.recommendTalentsToJob(data);
            if (!response.success) throw Error(response);
            toast.success("Talents were recommended for the job!");
            onClose();
        } catch (e) {
            toast.error("Something went wrong while recommending the talent");
            console.error(e);
        }
        setSubmitting(false);
    }

    return (
        <ModalV2 isOpen={isOpen} >
            <div className="flex flex-col w-[534px] mx-auto mt-[263px] pt-[32px] pl-[34px] pr-[31px] bg-white mb-[260px]">
                <div onClick={onClose} className="ml-auto w-fit hover:cursor-pointer ">
                    <IconV2 iconType="CLOSE" />
                </div>

                <span className="font-poppins font-semibold text-[20px]">
                    Confirm submission
                </span>

                {/* Job Information */}
                <span className="font-poppins font-semibold text-[16px] mt-[34px]">
                    Job opening:
                </span>

                <div className="mt-[21px] flex gap-x-[12px] mb-[45px]">
                    <CompanyLogo className="!mx-0 w-[32px]" company={company} />
                    <div className="flex flex-col">
                        {/* Job name and exp level */}
                        <div className="flex justify-start gap-x-[42px]">
                            <span className="font-poppins font-semibold text-[14px]">{job.job_title}</span>
                            <div className="flex gap-x-[10px]">
                                <IconV2 iconType="EDUCATION" />
                                <span className="font-inter font-semibold text-dark-gray text-[12px]">{job.experience_level}</span>
                            </div>
                        </div>

                        <span className="font-inter font-semibold text-dark-gray text-[12px]">{company.name}</span>

                        {/* Tags */}
                        <div className="flex gap-x-[26px] mt-[13px]">
                            <div className="flex gap-[10px]">
                                <IconV2 iconType="COMPANY" />
                                <span className="font-inter font-semibold text-dark-gray text-[12px]">{company.industry}</span>
                            </div>
                            <div className="flex gap-[10px]">
                                <IconV2 iconType="LOCATION" />
                                <span className="font-inter font-semibold text-dark-gray text-[12px]">{job.location}</span>
                            </div>
                            <div className="flex gap-[10px]">
                                <IconV2 iconType="LOCATION" />
                                <span className="font-inter font-semibold text-dark-gray text-[12px]">{company.city}</span>
                            </div>
                        </div>

                        {/* Skill Badges */}
                        <div className="flex gap-x-[9px] mt-[10px]">
                            {getJobPrimarySkills(job).map(skill =>
                                <BadgeV2 key={skill.name} color="pink" starInBadge >
                                    {skill.name}
                                </BadgeV2>
                            )}
                        </div>
                    </div>
                </div>

                {/* Recommended talent */}
                <span className="font-poppins font-semibold text-[16px] mt-[34px] mb-[30px]">
                    Outdefine Recommended:
                </span>

                <div className="max-h-[350px] overflow-y-auto flex flex-col gap-y-[23px]">
                    {recommendatedTalent.map(t =>
                        <div key={t.freelancer_id} className="flex gap-x-[14px]">

                            <UserAvatarLogo user={t.User} withRedBadge className="w-[32px] h-[32px]" redBadgeclassName="w-[40px] h-[40px] !mt-[-20px]" />

                            <div className="flex flex-col gap-y-[11px]">
                                {/* Talent header */}
                                <div className="flex gap-[20px]">
                                    <span className="font-poppins font-semibold text-[14px]">
                                        {`${t.User.first_name} ${t.User.last_name}`}
                                    </span>
                                    <div className="flex gap-x-[10px]">
                                        <IconV2 iconType="EDUCATION" />
                                        <span className="font-inter font-semibold text-dark-gray text-[12px]">
                                            {t.level_of_experience}
                                        </span>
                                    </div>
                                </div>

                                {/* Talent tags */}
                                <div className="flex gap-x-[17px]">
                                    <div className="flex gap-x-[10px]">
                                        <IconV2 iconType="USER" />
                                        <span className="font-inter font-semibold text-dark-gray text-[12px] w-[108px]">
                                            {t.Role?.name || 'N/A'}
                                        </span>
                                    </div>
                                    <div className="flex gap-x-[10px]">
                                        <IconV2 iconType="LOCATION" />
                                        <span className="font-inter font-semibold text-dark-gray text-[12px] w-[50px]">
                                            {JSON.parse(t.roles_open_to || '[]').join(', ') || "n/a"}
                                        </span>
                                    </div>
                                    <div className="flex gap-x-[10px]">
                                        <IconV2 iconType="LOCATION" />
                                        <span className="font-inter font-semibold text-dark-gray text-[12px] w-[108px]">
                                            {t.city}
                                        </span>
                                    </div>
                                </div>

                                {/* Skill Badges */}
                                <div className="flex flex-wrap gap-[9px] mt-[10px]">
                                    {t.skills?.filter(skill => skill.freelancer_skill.is_primary).map(skill =>
                                        <BadgeV2 key={skill.name} color="pink" starInBadge >
                                            {skill.name}
                                        </BadgeV2>
                                    )}
                                </div>
                            </div>
                        </div>
                    )
                    }
                </div>

                {/* Submit Button */}
                <Button
                    onClick={onSubmit}
                    className="font-poppins font-semibold text-[14px] w-[283px] h-[40px] mx-auto mt-[45px] mb-[38px]"
                    loading={submitting}
                >
                    Add to clients recommended
                </Button>

            </div>
        </ModalV2>
    );
}

export default SubmitRecommendationModal;