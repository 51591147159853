import { useEffect, useState } from "react";
import { debounce } from "lodash";

import profileAPI from "network/profile";
import IconV2 from "components/V2/Icons/IconV2";
import InputV2 from "components/V2/Input/InputV2";
import ENUMS from "constants/enums";
import { ITalentFilters } from "types/Filters/TalentFilters";
import Button from "components/Button/ButtonV2";
import DropdownV2 from "components/V2/Dropdown/DropdownV2";
import { Sort } from "types/Sort";
import CountryDropdown from "components/V2/CountryDropdown/CountryDropdown";

const dropdown_filters = [
    "All candidates",
    "Trusted talent",
];

const CategoryButton = ({ text, set, active, withCheck = false }:
    { text: string, set: (v: boolean) => void, active: boolean, withCheck?: boolean }) => (
    <Button
        className={`flex items-center gap-[12px] w-fit h-[40px] rounded-3xl !px-[16px] !py-[8px] font-poppins font-bold text-[14px]
        ${active ? "bg-blue2-hue !text-blue2" : "bg-[#F0F1F2] !text-inactive-gray"} `}
        onClick={() => set(!active)}
    >
        {text}
        {withCheck && <IconV2 iconType="CHECK-CIRCLE" iconClassName={`${active ? "fill-blue2" : "fill-inactive-gray"}`} />}
    </Button>
)

export type ITalentFilterGet = (page_size: number, offset?: number, count?: boolean, sort?: [Sort], _filters?: ITalentFilters) => any;

interface ITalentFilterPaginated {
    onFilter: (_: ITalentFilterGet) => void,
    className?: string,
}

// Helper
const global: any = {};
const debounceSearch = debounce(() => global.onSearch(), 1000);

const TalentFilterPaginated = ({ className = "", onFilter }: ITalentFilterPaginated) => {

    const [searchValue, setSearchValue] = useState<string>("");
    const [filter, setFilter] = useState<number>(0);
    const [signUpDate, setSignUpDate] = useState<{ from?: string, to?: string }>({ to: (new Date()).toISOString().split('T')[0] })
    const [prfCompleted, setPrfCompleted] = useState<boolean>(false);
    const [prof40, setProf40] = useState<boolean>(false);
    const [prof70, setProf70] = useState<boolean>(false);
    const [trusted, setTrusted] = useState<boolean>(false);
    const [declined, setDeclined] = useState<boolean>(false);
    const [country, setCountry] = useState<string>();

    // Apply filters
    const sValue = searchValue.toLowerCase();

    const onSearch = () => {
        if (!onFilter) return

        // Calculate the filters neeeded for endpoint
        const filters: ITalentFilters = {}
        const { from, to } = signUpDate;

        if (searchValue) filters.keyword = sValue;
        if (prfCompleted) filters.profile_completed = true;
        if (prof40) filters.min_profile_percentage = 40;
        if (prof70) filters.min_profile_percentage = 70;
        if (declined) filters.is_trusted_talent = ENUMS.trustedTalent[1];
        if (trusted) filters.is_trusted_talent = ENUMS.trustedTalent[2];
        if (country) filters.talent_location = [country];
        if (to && from) filters.sign_up_date = [from, to];

        // Dropdown filters
        switch (filter) {
            case 1:
                filters.profile_completed = true;
                break;
            case 2:
                filters.is_trusted_talent = ENUMS.trustedTalent[2];
                break;
            default:
                break;
        }


        // Create the function that retrieves the talent according to filter paginated
        const getPaginatedTalent = async (page_size: number, offset?: number, count?: boolean, sort?: [Sort], _filters?: ITalentFilters) =>
            profileAPI.getTalentPaginated({ page_size, offset, filters: { ...filters, ..._filters }, count, sort });
        onFilter(getPaginatedTalent);
    };
    global.onSearch = onSearch;

    // Debounced search
    useEffect(() => { debounceSearch(); }, [searchValue]);

    useEffect(() => { onSearch(); },
        [prfCompleted, prof40, prof70, trusted, declined, country, signUpDate, filter]);

    return (
        <div className={`flex flex-col ${className}`}>

            {/* Dropdown filters */}
            <div className="flex gap-[20px] my-[20px]">
                <span className="font-inter font-bold text-[14px]">Filter by:</span>
                <DropdownV2
                    className="w-[250px]"
                    buttonClass="w-fit h-[21px] pr-[50px] !font-inter  !text-[14px] border-none "
                    selectedIndex={filter}
                    onChange={setFilter}
                    data={dropdown_filters.map(f => ({ value: f }))}
                />
            </div>

            {/* Search bar */}
            <InputV2
                className="w-[50%]"
                value={searchValue} onChange={(e) => setSearchValue(e.target.value)}
                icon={<IconV2 iconType="SEARCH" iconClassName="w-6 h-6 translate-y-[1px]" />}
                placeholder="Search for talent by skills, role, email or name "
            />

            {/* Filters */}
            <div className="flex w-full items-center gap-[40px]">
                <div className="w-fit flex gap-[10px] items-center my-[20px]">
                    <span className="w-fit font-poppins text-[16px] font-bold">Filter by sign up date:</span>
                    <InputV2
                        type="date"
                        wrapClassName="w-[190px]"
                        name="from"
                        placeholder="MM/DD/YYYY"
                        onChange={(e) => setSignUpDate({ ...signUpDate, from: e.target.value })}
                    />

                    <InputV2
                        type="date"
                        wrapClassName="w-[190px]"
                        name="to"
                        placeholder="MM/DD/YYYY"
                        defaultValue={signUpDate.to}
                        onChange={(e) => setSignUpDate({ ...signUpDate, to: e.target.value })}

                    />
                </div>
                <div className="w-fit flex gap-[10px] items-center my-[20px]">
                    <span className="w-fit font-poppins text-[16px] font-bold">Filter by country:</span>
                    <CountryDropdown value={country} className="w-[190px]" onChange={setCountry} />
                </div>
            </div>

            {/* Category filters */}
            <div className="flex mt-[20px] gap-[20px] w-full flex-wrap">
                <CategoryButton text="Profile completed" active={prfCompleted} set={setPrfCompleted} withCheck />
                <CategoryButton text="40% complete profile" active={prof40} set={setProf40} withCheck />
                <CategoryButton text="70% complete profile" active={prof70} set={setProf70} withCheck />
                <CategoryButton text="Trusted talent" active={trusted} set={setTrusted} />
                <CategoryButton text="Declined talent" active={declined} set={setDeclined} />
            </div>


        </div>
    );
}

export default TalentFilterPaginated;