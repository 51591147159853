const WELCOME = "/welcome";
const SIGN_IN = "/login";
const SIGN_UP = "/signup";
const FORGOT_PASSWORD = "/forgotpassword";
const CONFIRM_USER = "/confirmUser";
const RESET_PASSWORD = "/resetPassword";
const DASHBOARD = "/";
const TALENT = "/talent/*";
const TALENT_OVERVIEW = "/overview";
const TALENT_DETAIL = "/details/:email";
const MANAGEMENT = "/management";
const COMPANY = "/company/*";
const COMPANY_OVERVIEW = "/overview";
const COMPANY_DETAILS = "/details/:id";
const DATABASE = "/database/*";
const DATABASE_INDUSTRY = "/industries";
const DATABASE_ROLE = "/roles";
const DATABASE_SKILL = "/skills";

const BOARD_MANAGEMENT = "/board/*";
const INVOICE = "/invoice/*";
const INVOICE_OVERVIEW = "/overview";
const INVOICE_DETAILS = "/details/:id";

const LINKEDIN_USERS = "/linkedin_users/*";
const LINKEDIN_USERS_OVERVIEW = "/overview";

const REPUTATION = "/reputation/*";
const REPUTATION_OVERVIEW = "/overview";
const REPUTATION_DETAIL = "/detail/:id/:type";

export default {
  WELCOME,
  SIGN_IN,
  SIGN_UP,
  FORGOT_PASSWORD,
  CONFIRM_USER,
  RESET_PASSWORD,
  // main
  DASHBOARD,
  TALENT,
  TALENT_OVERVIEW,
  TALENT_DETAIL,
  MANAGEMENT,
  COMPANY,
  COMPANY_OVERVIEW,
  COMPANY_DETAILS,
  DATABASE,
  DATABASE_INDUSTRY,
  DATABASE_ROLE,
  DATABASE_SKILL,
  BOARD_MANAGEMENT,
  INVOICE,
  INVOICE_OVERVIEW,
  INVOICE_DETAILS,
  LINKEDIN_USERS,
  LINKEDIN_USERS_OVERVIEW,
  REPUTATION,
  REPUTATION_OVERVIEW,
  REPUTATION_DETAIL
};
