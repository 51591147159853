import React, { useEffect } from 'react';

import Application from './application';
import Authentication from './authentication';

import LoadingScreen from 'components/LoadingScreen';
import { useAppSelector } from 'redux/hooks/redux-hooks';

const AppRoutes = () => {
  const { isFetching, isAuthenticated } = useAppSelector(
    (state) => state.authentication
  );

  return (
    <>
      {isFetching ? (
        <LoadingScreen />
      ) : isAuthenticated ? (
        <Application />
      ) : (
        <Authentication />
      )}
    </>
  );
};

export default AppRoutes;
