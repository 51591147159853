import { useEffect, useState } from "react";
import IconV2 from "../Icons/IconV2";
import utils from "utils/utils";

interface IPagesBar {
    count?: number; // amount of results
    className?: string;
    page_size: number; // size of the page
    fetchPage: (offset: number) => Promise<any[] | undefined>; // function to get the data given an offset
    onPageChange: (pageData: any[]) => void; // Report new page data
    onLoading: () => void;
}

// This component is in charge of administrating pages data 
// when doing pagination with the offset/limit technique
const PagesBar = ({ count, page_size, className = "", fetchPage, onPageChange, onLoading }: IPagesBar) => {

    const [pages, setPages] = useState<{ [page: number]: any[] }>({}); // pages data
    const [page, setPage] = useState<number | undefined>(); // current page
    const offset = ((page || 0) - 1) * page_size;

    // Function for fetching page
    const fetchData = async () => {
        if (!page) return;
        onLoading();
        const data = await fetchPage(offset);
        if (!data) return;
        const _pages = { ...pages };
        _pages[page] = data;
        setPages(_pages);
        onPageChange(data); // report
    };

    // If the method to get the pages changes clean the data
    useEffect(() => {
        setPages({});
        setPage(undefined)
    }, [fetchPage]);
    useEffect(() => {
        if (!Object.keys(pages).length) setPage(1);
    }, [pages]);

    // Handle page change and fetch data
    useEffect(() => {
        if (!page) return
        const data = pages[page];
        if (!data) fetchData();
        else onPageChange(data);
    }, [page]);

    if (!page) return null;
    // Nothing to do if there are no results
    if (count === 0) return null;

    const PageDiv = ({ i }) => (
        <div onClick={() => setPage(i)} key={i}
            className={`rounded-md w-[32px] h-[32px] font-inter text-[16px] flex items-center justify-center
            hover:cursor-pointer hover:bg-odf-light
            ${page === i ? 'text-white bg-odf' : 'text-black bg-[#F0F1F2]'}
        `}>
            {i}
        </div>
    );


    // If count has not arrived yet show 5 pages in the meanwhile
    const pagesAmount = count ? Math.ceil(count / page_size) : 5;

    // Calculate the range of 10 pages buttons we gonna display 
    const leftPage = Math.max(1, page - 4);
    const rightPage = Math.min(page + 5 + (4 - (page - leftPage)), pagesAmount);

    return (
        <div className={"flex gap-x-[4px] items-center " + className}>
            {page !== 1 && <div className="mr-[10px]" onClick={() => setPage(page - 1)}>
                <IconV2
                    iconType="ARROW-TRIANGLE-LEFT"
                    iconClassName=""
                />
            </div>}

            {utils.getRange(leftPage, rightPage + 1).map((i) =>
                <PageDiv key={i} i={i} />
            )}

            {
                rightPage < pagesAmount &&
                <>
                    ...
                    <PageDiv i={pagesAmount} />
                </>
            }

            {page !== pagesAmount && <div className="ml-[10px]" onClick={() => setPage(page + 1)}>
                <IconV2
                    iconType="ARROW-TRIANGLE-RIGHT"
                    iconClassName=""
                />
            </div>}
        </div>
    );
}

export default PagesBar;