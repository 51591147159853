
import { useForm } from "react-hook-form";

import FormStarRating from "components/V2/StarRating/FormStarRating";
import { AssessmentInfo, FreelancerProfile } from "types";
import { FormTextareaV2 } from "components/V2/Textarea";
import Button from "components/Button/ButtonV2";

interface IBehavioralInterview {
    assessment: AssessmentInfo,
}

function getDefaultValues(assessment: AssessmentInfo) {
    return {
        rating: (JSON.parse(assessment.rating || '{}')).behavioralInterviewRating,
        notes: (JSON.parse(assessment.notes_taken || '{}')).behavioralInterviewNotes,
    }
}

const BehavioralInterview = ({ assessment }: IBehavioralInterview) => {

    const { control, handleSubmit } = useForm({
        defaultValues: getDefaultValues(assessment)
    });

    return (
        <div className="flex flex-col">
            {/* Rating Behaviour */}
            <span className="font-poppins text-[16px] font-semibold mt-[30px]">
                Behavioral interview
            </span>
            <span className="font-inter text-[12px] mt-[10px]">
                Rate your interview using our 1-5 star system, 1 being strong NO hire
                and 5 being a strong hire.
            </span>
            <div className="mt-[10px]">
                <FormStarRating isReadOnly control={control} name="rating" />
            </div>

            {/* Notes Behaviour */}
            <span className="font-poppins text-[12px] font-semibold mt-[13px]">Add notes</span>
            <FormTextareaV2
                control={control}
                disabled
                name={"notes"}
                className="mt-[18px] text-[12px] h-[108px]"
                placeholder="Write down some notes"
            />

            <div className="w-full flex justify-end">
                <Button
                    className="px-4"
                    type="submit"
                    variant="secondary"
                    disabled={true}
                >
                    Save changes
                </Button>
            </div>
        </div>
    );
}

export default BehavioralInterview;