import { API } from 'aws-amplify';
import awsUtils from 'utils/awsUtils';

const apiName = awsUtils.getAPIName();

const getCompanyIdFromUserId = async (id: number) => {
  const authHeader = await awsUtils.getAuthHeader();
  const path = '/client/profile/' + id;
  const myInit = {
    headers: {
      ...authHeader,
    },
    body: {},
  };

  const result = await API.get(apiName, path, myInit);
  return result;
};

// const getCompanyProfile = async (id: number) => {
//   const authHeader = await awsUtils.getAuthHeader();
//   const path = '/client/company/' + id;
//   const myInit = {
//     headers: {
//       ...authHeader,
//     },
//     body: {},
//   };

//   const result = await API.get(apiName, path, myInit);
//   return result;
// };

const updateCompanyProfile = async (detail: ICompanyPreference) => {
  const authHeader = await awsUtils.getAuthHeader();
  const path = '/client/company';
  const myInit = {
    headers: {
      ...authHeader,
    },
    body: JSON.stringify({ ...detail }),
  };

  const result = await API.post(apiName, path, myInit);
  return result;
};

async function getTeamMembersByCompanyId(company_id: number) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/client/profile/getTeamMembers`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify({ company_id }),
  };

  const result = await API.post(apiName, path, myInit);
  return result;
}

async function updateClientProfile(data: IClientProfileUserAppended) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/client/profile/updateClientProfile`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(data),
  };

  const result = await API.post(apiName, path, myInit);
  return result;
}

async function updateTeamMember(data: IClientProfileUserAppended) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/client/profile/updateTeamMember`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(data),
  };

  const result = await API.post(apiName, path, myInit);
  return result;
}

const CompanyProfileAPI = {
  // getCompanyProfile,
  getCompanyIdFromUserId,
  updateCompanyProfile,
  getTeamMembersByCompanyId,
  updateClientProfile,
  updateTeamMember,
};

export default CompanyProfileAPI;
