import IconV2 from "components/V2/Icons/IconV2";
import ReputationApi from "network/reputation/reputation";
import { ReputationTag } from "network/reputation/types";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IReputationMetrics } from "types/Reputation";
import Metrics from "./components/Metrics";
import { reputationTitles } from "./constants";

const ReputationPage = () => {

  const [metrics, setMetrics] = useState<IReputationMetrics>();

  const fetchMetrics = async () => {
    try {
      const resp = await ReputationApi.metrics();
      if (!resp?.metrics) throw new Error(resp);
      setMetrics(resp.metrics);
    } catch (e) {
      console.error(e);
      toast.error("Error occured fetching reputation metrics");
    }
  }
  useEffect(() => { fetchMetrics() }, [])

  return (<div className="w-full h-full p-[70px]">
    <p className="font-poppins text-xl font-semibold mb-10">Verifications</p>
    <Metrics metrics={metrics} />
    <p className="font-poppins text-base font-semibold mt-10">Hello Team</p>
    <p className="font-inter text-base mt-2">Start verifying by choosing a verification type. When chosen you will see all Outdefine talent that has submitted a verification under that type. </p>

    <div className="grid grid-cols-2 gap-5 mt-10">
      <Button tag="identity_proof" amount={metrics?.reputations.ReputationIdentityProof.TOTAL} />
      <Button tag="personhood" amount={metrics?.reputations.ReputationPersonhood.TOTAL} />
    </div>
  </div>);
}

const Button = ({ amount, tag }: { amount?: string | number, tag: ReputationTag }) => {
  const navigate = useNavigate();
  return <button onClick={() => navigate(`/reputation/overview?type=${tag}`)}
    className="w-full border-[#3B3B3B] border-[1px] p-5 rounded-lg flex hover:bg-[#ECECFF] items-center">
    <div>
      <p className="font-poppins font-semibold text-xl">{reputationTitles[tag]}</p>
      <p className="text-[14px] font-inter mt-2 text-start">View all submissions.</p>
    </div>
    <p className="ml-auto font-inter text-xl text-blue2 font-semibold">{amount || ""}</p>
    <IconV2 iconType="TRIANGLE-ARROW-SOLID" iconClassName="rotate-90 ml-5" />

  </button>
}

export default ReputationPage;