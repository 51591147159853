import Button from "components/Button/ButtonV2";
import CheckBoxV2 from "components/V2/Buttons/CheckBoxV2";
import IconV2 from "components/V2/Icons/IconV2";
import Loader from "components/V2/Loader/Loader";
import ReputationApi from "network/reputation/reputation";
import { ReputationTag } from "network/reputation/types";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { IReputation } from "types/Reputation";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";
import { assigneesData, statusIcons } from "../constants";
import Label from "./components/Form/Label";
import { pages } from "./components/Forms";

const ReputationDetail = () => {
  const navigate = useNavigate();
  const [reputation, setReputation] = useState<IReputation>();
  const Assignee = reputation?.Assignee;
  const [localStatus, setLocalStatus] = useState<"verified" | "denied">();
  const [notes, setNotes] = useState("");
  const [showNotes, setShowNotes] = useState(false);
  const [showAssign, setShowAssign] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [assigning, setAssigning] = useState(false);
  const [assignee, setAssignee] = useState<number>();
  const _assignee = assignee || reputation?.assignee_id;
  const [page, setPage] = useState(0);
  const lVerified = localStatus === "verified";
  const lDenied = localStatus === "denied";
  const submitted = reputation?.status === "SUBMITTED"
  let canSubmit = !!localStatus && submitted;
  if (lDenied && !notes) canSubmit = false;

  const params = useParams();
  const id = params.id;
  const type: ReputationTag = params.type as ReputationTag || "identity_proof";
  const _pages = pages[type];
  const Page = _pages[page].Comp;
  const lastPage = !_pages[page + 1];


  const fetchReputation = async () => {
    if (!id) return;
    const reputation = await ReputationApi.getOne(id, type);
    setReputation(reputation);
  };
  useEffect(() => { fetchReputation() }, [id]);

  const submit = async () => {
    setSubmitting(true);
    if (!reputation) return;
    let response;
    try {
      switch (localStatus) {
        case "denied":
          response = await ReputationApi.reject(reputation.id, type, notes);
          if (!response.success) throw new Error("Error on rejection");
          break;
        case "verified":
          response = await ReputationApi.approve(reputation.id, type);
          if (!response.success) throw new Error("Error on approval");
          break;
        default:
          break;
      }
      setReputation(response.data);
      toast.success("Reviewed");
    } catch (e: any) {
      toast.error(e.message);
    }
    setSubmitting(false);
  }

  const assign = async () => {
    if (!reputation || !assignee) return;
    setAssigning(true);
    try {
      const response = await ReputationApi.assign("identity_proof", reputation.id, assignee);
      const rep: IReputation = response.data;
      if (!rep) throw new Error(response);
      setReputation(rep);
    } catch (e) {
      toast.error('Error while assigning')
      console.error(e)
    }
    setAssigning(false);
  }

  if (!reputation) return <Loader />

  return (<div className="w-full h-full p-[50px] overflow-scroll font-inter">
    <div
      className="flex items-center hover:cursor-pointer w-fit"
      onClick={() => navigate(`/reputation/overview`)}
    >
      <IconV2 iconType="TRIANGLE-ARROW-SOLID" iconClassName="-rotate-90 fill-blue2 h-3 w-3" />
      <span className="font-inter ml-3">Back</span>
    </div>
    <div className="flex gap-10">

      {/* Left section */}
      <div className="flex flex-col w-[444px] border-r-[1px] border-black h-fit [&>div]:border-b-[1px] [&>div]:p-5 [&>div]:pr-20 ">
        {/* Verifier */}
        <div className="">
          <div className="flex items-center">
            <p className="font-semibold font-inter pl-2">Verifier</p>
            <ToggleButton onClick={() => setShowAssign(!showAssign)} show={showAssign} />
          </div>

          {showAssign && <div >
            <p className="font-semibold font-inter pl-2 my-4">Assigned to</p>
            <div className="flex flex-col gap-4 ml-1 mb-5">
              {assigneesData.map(a => <CheckBoxV2
                disabled={!submitted || !!Assignee}
                key={a.id}
                value={a.id}
                selected={_assignee === a.id}
                onClick={(id) => setAssignee(id)}
              >
                {a.value}
              </CheckBoxV2>)}
            </ div >
            <Button onClick={assign} loading={assigning} disabled={!submitted || !!Assignee || !assignee}
              variant="primary2" >{!!Assignee ? "Assigned" : "Assign"}</Button>
          </div>}
        </div>
        {/* Pages index */}
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-3">
            <p className="font-semibold font-inter pl-2">Documents submitted</p>
            <IconV2 iconType={statusIcons[reputation.status]} />
          </div>
          {_pages.map((p, i) => <PageButton page={i} key={i} text={p.title} selected={page} onClick={setPage} />)}
        </div>
        {/* Denied notes section */}
        <div className="">
          <div className="flex items-center">
            <p className="font-semibold font-inter pl-2">Notes Added</p>
            <ToggleButton onClick={() => setShowNotes(!showNotes)} show={showNotes} />
          </div>
          {showNotes && <div className="w-full max-h-[400px] border-[1px] border-coral-red p-5 rounded-2xl text-sm mt-5 ">
            {reputation.notes}
          </div>}
        </div>
      </div>

      {/* Right section */}
      <div className="flex flex-col py-10">
        {/* User being viewed */}
        <div className="flex items-center mb-10">
          <UserAvatarLogo className="w-8 h-8" user={reputation.Freelancer.User} />
          <p className="text-sm font-bold ml-3">
            {reputation.Freelancer.User.full_name}
          </p>
          <div className="px-3 py-2 text-blue2 text-sm font-bold bg-[#ECECFF] rounded-3xl ml-5">
            Viewing
          </div>
        </div>

        {/* Read only Form section */}
        <Page repu={reputation} />

        {/* Verify buttons */}
        {lastPage && <div className="mt-10">
          <Label>Verify or deny information</Label>
          <p className="mt-[10px] w-[439px]">
            Once you have gone through and cross checked this information, mark it verified or denied.
          </p>
          <div className="flex mt-[10px] gap-[66px] font-inter text-sm font-semibold">
            <button disabled={!submitted} onClick={() => setLocalStatus("denied")} className={`flex gap-3 items-center ${!lDenied && "opacity-70"} ${submitted && "hover:opacity-100"} `}>
              <IconV2 iconType="CLOSE-CIRCLE" iconClassName="w-6 h-6 fill-coral-red" />
              <p className="text-coral-red">Mark denied</p>
            </button>
            <button disabled={!submitted} onClick={() => setLocalStatus("verified")} className={`flex gap-3 items-center ${!lVerified && "opacity-70"}  ${submitted && "hover:opacity-100"} `}>
              <IconV2 iconType="CHECK-CIRCLE" iconClassName="w-6 h-6" />
              <p className="text-blue2">Mark verified</p>
            </button>
          </div>

          {
            lDenied && <div> <textarea
              disabled={!submitted}
              value={notes}
              placeholder="Leave notes for the talent letting them know what is missing or why it was denied."
              onChange={e => setNotes(e.target.value)}
              className="rounded-2xl border-[1px] !border-coral-red bg-[#F9F9F9] w-[331px] h-[166px] p-5 mt-5 " />
              <p className="text-sm text-coral-red mt-1">Required*</p>
            </div>
          }
        </div>}

        <div className="mb-10" />

        {/* Buttons */}
        {lastPage && <Button
          disabled={!canSubmit}
          loading={submitting}
          onClick={submit}
          variant="primary2"
          className="w-[331px] text-start mt-5">
          {submitted ? "Submit Review" : "Reviewed"}
        </Button>}
        {!lastPage && <Button onClick={() => setPage(page + 1)} variant="primary2" className="w-[331px] text-start mt-5">
          Next
        </Button>}
        {!!_pages[page - 1] && <Button onClick={() => setPage(page - 1)} variant="secondary2" className="w-[331px] text-start mt-5">
          Back
        </Button>}
      </div>
    </div>
  </div>);
}

const ToggleButton = ({ show, onClick }) => <button className="ml-auto" onClick={onClick}><IconV2
  iconType="TRIANGLE-ARROW-SOLID"
  iconClassName={`${show ? "" : "rotate-180"}  h-3 w-3 `} /></button>

const PageButton = ({ page, text, selected, onClick }: { page: number, text: string, selected: number, onClick: (page) => void }) => {
  const active = selected === page;
  return (
    <button onClick={() => onClick(page)}
      className={"rounded-3xl font-bold text-start px-2 " + (active ? "bg-[#ECECFF] text-blue2 !px-5 py-3" : "text-[#6A6A6A]")}>
      {text}
    </button>
  );
};

export default ReputationDetail;