import InputV2 from "components/V2/Input/InputV2";
import Label from "./Label";

interface IInput {
  label?: string;
  text?: string;
  className?: string
}

const Input = ({ text, className, label }: IInput) => {
  return (
    <div className={"w-[331px] text-sm " + className}>
      {label && <Label>{label}</Label>}
      <InputV2 disabled={true} value={text} />
    </div>

  );
}

export default Input;