import { API } from 'aws-amplify';
import awsUtils from 'utils/awsUtils';

async function createRole(data: {
  job_type_id: number | string;
  name: string;
}) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();

  const path = '/jobTypes/role/add';

  const myInit = {
    headers: {
      ...authHeader,
    },
    body: JSON.stringify(data),
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

async function createSkill(data: { role_id: number | string; name: string }) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();

  const path = '/jobTypes/skill/add';

  const myInit = {
    headers: {
      ...authHeader,
    },
    body: JSON.stringify(data),
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

export default {
  createRole,
  createSkill,
};
