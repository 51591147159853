import { ReactSVGElement } from "react";

import { ReactComponent as Add } from "assets/V2/svg/add.svg";
import { ReactComponent as ArrowRight } from "assets/V2/svg/arrow-right.svg";
import { ReactComponent as Badge } from "assets/V2/svg/badge.svg";
import { ReactComponent as Bank } from "assets/V2/svg/bank.svg";
import { ReactComponent as BriefcaseWhite } from "assets/V2/svg/briefcase-white.svg";
import { ReactComponent as BookmarkFilled } from "assets/V2/svg/bookmark-filled.svg";
import { ReactComponent as Bookmark } from "assets/V2/svg/bookmark.svg";
import { ReactComponent as Code } from "assets/V2/svg/code.svg";
import { ReactComponent as CodeWhite } from "assets/V2/svg/code-white.svg";
import { ReactComponent as Close } from "assets/V2/svg/close.svg";
import { ReactComponent as Clock } from "assets/V2/svg/clock.svg";
import { ReactComponent as ClockSand } from "assets/V2/svg/clock-sand.svg";
import { ReactComponent as Company } from "assets/V2/svg/company.svg";
import { ReactComponent as Date } from "assets/V2/svg/date.svg";
import { ReactComponent as Document } from "assets/V2/svg/document.svg";
import { ReactComponent as DollaBlueCircle } from "assets/V2/svg/dollar-blue-circle.svg";
import { ReactComponent as Edit } from "assets/V2/svg/edit.svg";
import { ReactComponent as Education } from "assets/V2/svg/education.svg";
import { ReactComponent as Location } from "assets/V2/svg/location.svg";
import { ReactComponent as Instagram } from "assets/V2/svg/instagram.svg";
import { ReactComponent as Linkedin } from "assets/V2/svg/linkedin.svg";
import { ReactComponent as Github } from "assets/V2/svg/github.svg";
import { ReactComponent as Twitter } from "assets/V2/svg/twitter.svg";
import { ReactComponent as MoneyWallet } from "assets/V2/svg/money-wallet.svg";
import { ReactComponent as Rate } from "assets/V2/svg/rate.svg";
import { ReactComponent as RatingStar } from "assets/V2/svg/rating-star.svg";
import { ReactComponent as RedStar } from "assets/V2/svg/red-star.svg";
import { ReactComponent as Token } from "assets/V2/svg/token.svg";
import { ReactComponent as TriangleArrowSolid } from "assets/V2/svg/triangle-arrow-solid.svg";
import { ReactComponent as Search } from "assets/V2/svg/search.svg";
import { ReactComponent as Portfolio } from "assets/V2/svg/portfolio.svg";
import { ReactComponent as Upload } from "assets/V2/svg/upload.svg";
import { ReactComponent as UploadWhite } from "assets/V2/svg/upload-white.svg";
import { ReactComponent as User } from "assets/V2/svg/user.svg";
import { ReactComponent as UserOrange } from "assets/V2/svg/user-orange.svg";
import { ReactComponent as UserOutline } from "assets/V2/svg/user-outline.svg";
import { ReactComponent as World } from "assets/V2/svg/world.svg";
import { ReactComponent as OutdefineLogo } from "assets/V2/svg/dark-logo.svg";
import { ReactComponent as Astronaut } from "assets/V2/svg/astronaut.svg";
import { ReactComponent as AstronautWalkLeft } from "assets/V2/svg/astronaut-walk-left.svg";
import { ReactComponent as AstronautWalkRight } from "assets/V2/svg/astronaut-walk-right.svg";
import { ReactComponent as ArrowUp } from "assets/V2/svg/arrow-up.svg";
import { ReactComponent as ArrowDown } from "assets/V2/svg/arrow-down.svg";
import { ReactComponent as ArrowDown2 } from "assets/V2/svg/arrow-down-2.svg";
import { ReactComponent as Dollar } from "assets/V2/svg/dollar.svg";
import { ReactComponent as ButtonBackRect } from "assets/V2/svg/btn-back-rectangle.svg";
import { ReactComponent as Shadow } from "assets/V2/svg/shadow.svg";
import { ReactComponent as SMS } from "assets/V2/svg/sms.svg";
import { ReactComponent as Phone } from "assets/V2/svg/phone.svg";
import { ReactComponent as BackArrow } from "assets/V2/svg/back-arrow.svg";
import { ReactComponent as NextArrow } from "assets/V2/svg/next-arrow.svg";
import { ReactComponent as Copy } from "assets/V2/svg/copy.svg";
import { ReactComponent as Eye } from "assets/V2/svg/eye.svg";
import { ReactComponent as Calendar } from "assets/V2/svg/calendar.svg";
import { ReactComponent as DiagArrowDownRed } from "assets/V2/svg/diag-arrow-down-red.svg";
import { ReactComponent as DiagArrowUpGreen } from "assets/V2/svg/diag-arrow-up-green.svg";
import { ReactComponent as UserOrange2 } from "assets/V2/svg/user-orange2.svg";
import { ReactComponent as UserCheckedBlue } from "assets/V2/svg/user-checked-blue.svg";
import { ReactComponent as BriefcaseRed } from "assets/V2/svg/briefcase-red.svg";
import { ReactComponent as Clipboard } from "assets/V2/svg/clipboard.svg";
import { ReactComponent as OutdefineReviewed } from "assets/V2/svg/outdefine-reviewed.svg";
import { ReactComponent as BookWhite } from "assets/V2/svg/book-white.svg";
import { ReactComponent as MissingAssessment } from "assets/V2/svg/missing-assessment.svg";
import { ReactComponent as ArrowTriangleLeft } from "assets/V2/svg/arrow-triangle-left.svg";
import { ReactComponent as ArrowTriangleRight } from "assets/V2/svg/arrow-triangle-right.svg";
import { ReactComponent as BlueDocumentFavorite } from "assets/V2/svg/blue-document-favorite.svg";
import { ReactComponent as RedEmail } from "assets/V2/svg/red-email.svg";
import { ReactComponent as OrangeDocument } from "assets/V2/svg/orange-document.svg";
import { ReactComponent as ReceiveSquare } from "assets/V2/svg/receive-square.svg";
import { ReactComponent as Msa } from "assets/V2/svg/msa.svg";
import { ReactComponent as BriefcaseOrange } from "assets/V2/svg/briefcase-orange.svg";
import { ReactComponent as OutdefLogoBlue2 } from "assets/V2/svg/outdef-logo-blu2.svg";
import { ReactComponent as Check } from "assets/V2/svg/check.svg";
import { ReactComponent as CheckCircle } from "assets/V2/svg/check-circle.svg";
import { ReactComponent as CloseCircle } from "assets/V2/svg/close-circle.svg";
import { ReactComponent as MinusCircle } from "assets/V2/svg/minus-circle.svg";
import { ReactComponent as Search2 } from "assets/V2/svg/search2.svg";
import { ReactComponent as Behance } from "assets/V2/svg/behance.svg";
import { ReactComponent as Dribble } from "assets/V2/svg/dribble.svg";

const IconV2: React.FC<IIconV2> = (props): ReactSVGElement => {
  const className = props?.iconClassName ?? "";
  const color = props?.fill ?? "";
  const attribute = { className };

  const icons = {
    ADD: <Add {...attribute} />,
    "ARROW-RIGHT": <ArrowRight {...attribute} />,
    BADGE: <Badge {...attribute} />,
    BANK: <Bank {...attribute} />,
    "BTN-BACK-RECTANGLE": <ButtonBackRect {...attribute} />,
    BRIEFCASEWHITE: <BriefcaseWhite {...attribute} />,
    BOOKMARK: <Bookmark {...attribute} />,
    BOOKMARKFILLED: <BookmarkFilled {...attribute} />,
    CODE: <Code {...attribute} />,
    CODEWHITE: <CodeWhite {...attribute} />,
    CLOSE: <Close {...attribute} />,
    CLOCK: <Clock {...attribute} />,
    "CLOCK-SAND": <ClockSand {...attribute} />,
    COMPANY: <Company {...attribute} />,
    DATE: <Date {...attribute} />,
    DOCUMENT: <Document {...attribute} />,
    "DOLLAR-BLUE-CIRCLE": <DollaBlueCircle {...attribute} />,
    EDIT: <Edit {...attribute} />,
    EDUCATION: <Education {...attribute} />,
    GITHUB: <Github {...attribute} />,
    TWITTER: <Twitter {...attribute} />,
    LOCATION: <Location {...attribute} />,
    "OUTDEF-LOGO": <OutdefineLogo {...attribute} />,
    LINKEDIN: <Linkedin {...attribute} />,
    INSTAGRAM: <Instagram {...attribute} />,
    "MONEY-WALLET": <MoneyWallet {...attribute} />,
    PORTFOLIO: <Portfolio {...attribute} />,
    RATE: <Rate {...attribute} />,
    RATINGSTAR: <RatingStar {...attribute} />,
    REDSTAR: <RedStar {...attribute} />,
    SEARCH: <Search {...attribute} />,
    TOKEN: <Token {...attribute} />,
    "TRIANGLE-ARROW-SOLID": <TriangleArrowSolid {...attribute} />,
    UPLOAD: <Upload {...attribute} />,
    UPLOADWHITE: <UploadWhite {...attribute} />,
    USER: <User {...attribute} />,
    "USER-ORANGE": <UserOrange {...attribute} />,
    USEROUTLINE: <UserOutline {...attribute} />,
    WORLD: <World {...attribute} />,
    ASTRONAUT: <Astronaut {...attribute} />,
    "ASTRONAUT-WALK-LEFT": <AstronautWalkLeft />,
    "ASTRONAUT-WALK-RIGHT": <AstronautWalkRight {...attribute} />,
    "ARROW-UP": <ArrowUp {...attribute} {...attribute} />,
    "ARROW-DOWN": <ArrowDown {...attribute} />,
    "ARROW-DOWN-2": <ArrowDown2 {...attribute} />,
    DOLLAR: <Dollar {...attribute} />,
    SHADOW: <Shadow {...attribute} />,
    EMAIL: <SMS {...attribute} />,
    PHONE: <Phone {...attribute} />,
    "BACK-ARROW": <BackArrow {...attribute} />,
    "NEXT-ARROW": <NextArrow {...attribute} />,
    COPY: <Copy {...attribute} />,
    EYE: <Eye {...attribute} />,
    CALENDAR: <Calendar {...attribute} />,
    "DIAG-ARROW-UP-GREEN": <DiagArrowUpGreen {...attribute} />,
    "DIAG-ARROW-DOWN-RED": <DiagArrowDownRed {...attribute} />,
    "USER-ORANGE2": <UserOrange2 {...attribute} />,
    "USER-CHECKED-BLUE": <UserCheckedBlue {...attribute} />,
    "BRIEFCASE-RED": <BriefcaseRed {...attribute} />,
    "BRIEFCASE-ORANGE": <BriefcaseOrange {...attribute} />,
    CLIPBOARD: <Clipboard {...attribute} />,
    "OUTDEFINE-REVIEWED": <OutdefineReviewed {...attribute} />,
    "MISSING-ASSESSMENT": <MissingAssessment {...attribute} />,
    "BOOK-WHITE": <BookWhite {...attribute} />,
    "ARROW-TRIANGLE-LEFT": <ArrowTriangleLeft {...attribute} />,
    "ARROW-TRIANGLE-RIGHT": <ArrowTriangleRight {...attribute} />,
    "BLUE-DOCUMENT-FAVORITE": <BlueDocumentFavorite {...attribute} />,
    "RED-EMAIL": <RedEmail {...attribute} />,
    "ORANGE-DOCUMENT": <OrangeDocument {...attribute} />,
    "RECEIVE-SQUARE": <ReceiveSquare {...attribute} fill={color} />,
    MSA: <Msa {...attribute} />,
    "OUTDEF-LOGO-BLUE2": <OutdefLogoBlue2 {...attribute} />,
    CHECK: <Check {...attribute} />,
    "CHECK-CIRCLE": <CheckCircle {...attribute} />,
    "CLOSE-CIRCLE": <CloseCircle {...attribute} />,
    "MINUS-CIRCLE": <MinusCircle {...attribute} />,
    "SEARCH2": <Search2 {...attribute} />,
    BEHANCE: <Behance {...attribute} />,
    DRIBBLE: <Dribble {...attribute} />,
  };

  return icons[props.iconType] ?? icons.EDIT;
};

export default IconV2;
