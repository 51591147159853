const CompanyIcon = (props: INavIcon) => {
    const fillColor = props.light ? '#FFFFFF' : '#2F3454';
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.6 4.15C10.6 4.34 10.44 4.5 10.25 4.5H9.12C6.96 
            4.5 5.2 6.26 5.2 8.42V17.65C5.2 17.84 5.04 18 4.85 18H4.15C2.96 18 2 17.04 2 
            15.85V4.15C2 2.96 2.96 2 4.15 2H8.45C9.64 2 10.6 2.96 10.6 4.15Z" fill={fillColor}/>
            <path d="M21.9999 4.15V15.85C21.9999 17.04 21.0399 18 19.8499
             18H19.2199C19.0299 18 18.8699 17.84 18.8699 17.65V8.42C18.8699
              6.26 17.1099 4.5 14.9499 4.5H13.7499C13.5599 4.5 13.3999 4.34 13.3999
               4.15C13.3999 2.96 14.3599 2 15.5499 2H19.8499C21.0399 2 21.9999 2.96
                21.9999 4.15Z" fill={fillColor}/>
            <path d="M14.9502 6H9.1202C7.7802 6 6.7002 7.08 6.7002
             8.42V19.58C6.7002 20.92 7.7802 22 9.1202 22H10.7502C11.0302 22 11.2502 21.78
              11.2502 21.5V19C11.2502 18.59 11.5902 18.25 12.0002 18.25C12.4102
               18.25 12.7502 18.59 12.7502 19V21.5C12.7502 21.78 12.9702 22
                13.2502 22H14.9602C16.2902 22 17.3702 20.92 17.3702 19.59V8.42C17.3702 7.08 16.2902 
                6 14.9502 6ZM14.0002 14.75H10.0002C9.5902 14.75 9.2502 14.41 9.2502 14C9.2502 13.59 9.5902 13.25 
                10.0002 13.25H14.0002C14.4102 13.25 14.7502 13.59 14.7502 14C14.7502 14.41 14.4102 14.75 14.0002 
                14.75ZM14.0002 11.75H10.0002C9.5902 11.75 9.2502 11.41 9.2502 11C9.2502 10.59 9.5902 10.25 10.0002 
                10.25H14.0002C14.4102 10.25 14.7502 10.59 14.7502 11C14.7502 11.41 14.4102 
                11.75 14.0002 11.75Z" fill={fillColor}/>
        </svg>
    );
  };
  
  export default CompanyIcon;
  


