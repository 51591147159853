import { useState } from 'react'
import Chart from 'react-apexcharts'
import { DashboardTime, Stats } from '../types'

interface IData {
  stats: Stats
  name: string
  color: string
}

interface IDashboardCharts {
  data: IData[]
  time: DashboardTime
  title: string
}

const DashboardCharts = ({ data, time, title }: IDashboardCharts) => {
  const [selectedTab, setSelectedTab] = useState<string>('Overview')
  let series = data.map((d) => {
    return {
      axis: d.stats.axis[time],
      color: d.color,
      name: d.name,
    }
  })
  if (selectedTab !== 'Overview')
    series = [series[data.findIndex((d) => d.name === selectedTab)]];

  const Tab = ({ name }) => (
    <span
      onClick={() => setSelectedTab(name)}
      className={`text-inactive-gray font-semibold text-[10px] rounded-xl px-[16px] py-[3px] h-[21px] 
            flex justify-center items-center cursor-pointer
            ${selectedTab === name ? 'bg-white !text-black ' : ''
        } hover:bg-white hover:!text-black`}
    >
      {name}
    </span>
  )

  return (
    <div className="w-full h-[450px] max-h-[450px] bg-white px-[20px] flex flex-col">
      <div className="flex flex-wrap gap-y-2 justify-between pt-[18px] pb-[12px] ">
        <span className="font-poppins font-bold text-[16px] ">{title}</span>
        <div className="flex flex-wrap items-center h-fit p-[5px] bg-[#D9D9D9] rounded-xl gap-x-[5px]">
          <Tab name="Overview" />
          {data.map((d) => (
            <Tab key={d.name} name={d.name} />
          ))}
        </div>
      </div>
      <div className="w-full flex-1">
        <Chart
          series={series.map((s) => ({
            name: s.name,
            data: s.axis.y_axis,
            color: s.color,
          }))}
          width="100%"
          height="100%"
          type="line"
          options={{
            chart: {
              id: 'basic-bar',
              height: '80%',
            },
            stroke: {
              width: 2,
            },
            legend: {
              fontFamily: 'Inter',
              fontSize: '14px',
              fontWeight: 600,
              offsetY: 20,
              position: 'top',
              horizontalAlign: 'left',
              labels: {
                colors: '#949494',
              },
              itemMargin: {
                horizontal: 10,
              },
              markers: {
                width: 15,
                height: 3,
                radius: 3,
                offsetY: -3,
              },
            },
            xaxis: {
              categories: series[0].axis.x_axis,
              axisBorder: {
                strokeWidth: 3,
                color: '#655B96',
              },
              axisTicks: {
                height: 8,
                color: '#655B96',
              },
              labels: {
                style: {
                  fontFamily: 'Inter',
                  fontSize: '11px',
                  fontWeight: 500,
                  colors: '#949494',
                },
              },
              ...options[time].xaxis
            },
            yaxis: {
              axisBorder: {
                show: true,
                width: 1,
                color: '#655B96',
              },
              axisTicks: {
                show: true,
                width: 8,
                color: '#655B96',
              },
              labels: {
                style: {
                  fontFamily: 'Inter',
                  fontSize: '11px',
                  fontWeight: 500,
                  colors: '#949494',
                },
              },
            },
            grid: {
              xaxis: {
                lines: {
                  show: true,
                },
              },
              yaxis: {
                lines: {
                  show: true,
                },
              },
            },
          }}
        />
      </div>
    </div>
  )
}

// We might want to twick some css depending on the time (for example Years time has a very loong x axis)
const options: { [key in DashboardTime]: ApexCharts.ApexOptions } = {
  "Month": {},
  "Week": {},
  "Year": {},
  "Years": {
    xaxis: {
      labels: {
        rotate: -90,
        style: {
          fontFamily: 'Inter',
          fontSize: '5px',
          fontWeight: 200,
          colors: '#949494',
        },
      }
    }
  },
  "3 Months": {},
  "6 Months": {},
  "12 Months": {}
}

export default DashboardCharts
