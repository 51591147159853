import { useLocation } from "react-router-dom";

import DashboardIcon from "./icons/dashboard";
import JobIcon from "./icons/talent-job";
import AssessmentIcon from "./icons/talent-assessment";
import LogoutIcon from "./icons/logout";
import CompanyIcon from "./icons/company";
import TalentManagementIcon from "./icons/talent-management";
import BoardManagementIcon from "./icons/board-management";
import InvoiceIcon from "./icons/invoice";
import Linkedin from "./icons/linkedin";
import ReputationIcon from "./icons/reputation";

interface IconType {
  active: boolean;
  item: string;
  dotIcon?: boolean;
  groupActive?: boolean;
}

const NavIconV2 = ({ active, item, groupActive, dotIcon }: IconType) => {
  const path = useLocation().pathname;
  const sub_active = path.split("/")[1] === item || (path === "/" && item === "dashboard");
  const _active = sub_active && path.split("/")[2] === undefined;
  const lightStatus = _active || active || groupActive;

  const icons = {
    dashboard: <DashboardIcon light={lightStatus} />,
    talent: <JobIcon light={lightStatus} />,
    companies: <CompanyIcon light={lightStatus} />,
    database: <AssessmentIcon light={lightStatus} />,
    talentmanagement: <TalentManagementIcon light={lightStatus} />,
    boardmanagement: <BoardManagementIcon light={lightStatus} />,
    invoices: <InvoiceIcon light={lightStatus} />,
    linkedinusers: <Linkedin />,
    reputation: <ReputationIcon light={lightStatus} />,
    logout: <LogoutIcon light={lightStatus} />,
  };

  if (dotIcon) {
    return (
      <span className="w-6 h-6 flex">
        <span className={`w-2 h-2 m-auto rounded-full ${lightStatus ? "bg-white" : "bg-odf"}`} />
      </span>
    );
  }
  return icons[item] ?? <span className={"w-6 h-6 bg-odf rounded-full hover:bg-white"} />;
};

export default NavIconV2;
