export function CommentIcon() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.1316 5H5.79831C5.58164 5 5.37331 5.00833 5.17331 5.03333C2.93164 5.225 1.80664 6.55 1.80664 8.99167V12.325C1.80664 15.6583 3.13997 16.3167 5.79831 16.3167H6.13164C6.31497 16.3167 6.55664 16.4417 6.66497 16.5833L7.66497 17.9167C8.10664 18.5083 8.82331 18.5083 9.26497 17.9167L10.265 16.5833C10.39 16.4167 10.59 16.3167 10.7983 16.3167H11.1316C13.5733 16.3167 14.8983 15.2 15.09 12.95C15.115 12.75 15.1233 12.5417 15.1233 12.325V8.99167C15.1233 6.33333 13.79 5 11.1316 5ZM5.55664 11.6667C5.08997 11.6667 4.72331 11.2917 4.72331 10.8333C4.72331 10.375 5.09831 10 5.55664 10C6.01497 10 6.38997 10.375 6.38997 10.8333C6.38997 11.2917 6.01497 11.6667 5.55664 11.6667ZM8.46497 11.6667C7.99831 11.6667 7.63164 11.2917 7.63164 10.8333C7.63164 10.375 8.00664 10 8.46497 10C8.92331 10 9.29831 10.375 9.29831 10.8333C9.29831 11.2917 8.93164 11.6667 8.46497 11.6667ZM11.3816 11.6667C10.915 11.6667 10.5483 11.2917 10.5483 10.8333C10.5483 10.375 10.9233 10 11.3816 10C11.84 10 12.215 10.375 12.215 10.8333C12.215 11.2917 11.84 11.6667 11.3816 11.6667Z"
        fill="#2F3454"
      />
      <path
        d="M18.4566 5.65866V8.99199C18.4566 10.6587 17.9399 11.792 16.9066 12.417C16.6566 12.567 16.3649 12.367 16.3649 12.0753L16.3733 8.99199C16.3733 5.65866 14.4649 3.75033 11.1316 3.75033L6.0566 3.75866C5.76493 3.75866 5.56493 3.46699 5.71493 3.21699C6.33993 2.18366 7.47327 1.66699 9.1316 1.66699H14.4649C17.1233 1.66699 18.4566 3.00033 18.4566 5.65866Z"
        fill="#2F3454"
      />
    </svg>
  );
}

export function LikeIcon() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.60034 15.4086V6.94193C7.60034 6.6086 7.70034 6.2836 7.88367 6.0086L10.1587 2.62526C10.517 2.0836 11.4087 1.70026 12.167 1.9836C12.9837 2.2586 13.5253 3.17526 13.3503 3.99193L12.917 6.71693C12.8837 6.96693 12.9503 7.19193 13.092 7.36693C13.2337 7.52526 13.442 7.62526 13.667 7.62526H17.092C17.7503 7.62526 18.317 7.89193 18.6503 8.3586C18.967 8.8086 19.0253 9.39193 18.817 9.9836L16.767 16.2253C16.5087 17.2586 15.3837 18.1003 14.267 18.1003H11.017C10.4587 18.1003 9.67534 17.9086 9.31701 17.5503L8.25034 16.7253C7.84201 16.4169 7.60034 15.9253 7.60034 15.4086Z"
        fill="#2F3454"
      />
      <path
        d="M4.95039 5.31641H4.09206C2.80039 5.31641 2.27539 5.81641 2.27539 7.04974V15.4331C2.27539 16.6664 2.80039 17.1664 4.09206 17.1664H4.95039C6.24206 17.1664 6.76706 16.6664 6.76706 15.4331V7.04974C6.76706 5.81641 6.24206 5.31641 4.95039 5.31641Z"
        fill="#2F3454"
      />
    </svg>
  );
}

export function ViewIcon() {
  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.5031 7.62506C16.5781 4.60006 13.7614 2.8584 10.7948 2.8584C9.31143 2.8584 7.86976 3.29173 6.55309 4.10007C5.23643 4.91673 4.05309 6.1084 3.08643 7.62506C2.25309 8.9334 2.25309 11.0584 3.08643 12.3667C5.01143 15.4001 7.82809 17.1334 10.7948 17.1334C12.2781 17.1334 13.7198 16.7001 15.0364 15.8917C16.3531 15.0751 17.5364 13.8834 18.5031 12.3667C19.3364 11.0667 19.3364 8.9334 18.5031 7.62506ZM10.7948 13.3667C8.92809 13.3667 7.42809 11.8584 7.42809 10.0001C7.42809 8.14173 8.92809 6.6334 10.7948 6.6334C12.6614 6.6334 14.1614 8.14173 14.1614 10.0001C14.1614 11.8584 12.6614 13.3667 10.7948 13.3667Z"
        fill="#2F3454"
      />
      <path
        d="M10.7947 7.61621C9.48634 7.61621 8.41968 8.68288 8.41968 9.99954C8.41968 11.3079 9.48634 12.3745 10.7947 12.3745C12.103 12.3745 13.178 11.3079 13.178 9.99954C13.178 8.69121 12.103 7.61621 10.7947 7.61621Z"
        fill="#2F3454"
      />
    </svg>
  );
}

export function BackIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.81 22H16.19C19.83 22 22 19.83 22 16.19L22 7.82C22 4.17 19.83 2 16.19 2H7.82C4.18 2 2.01 4.17 2.01 7.81L2.01 16.19C2 19.83 4.17 22 7.81 22ZM9.21 11.47L12.74 7.94C12.89 7.79 13.08 7.72 13.27 7.72C13.46 7.72 13.65 7.79 13.8 7.94C14.09 8.23 14.09 8.71 13.8 9L10.8 12L13.8 15C14.09 15.29 14.09 15.77 13.8 16.06C13.51 16.35 13.03 16.35 12.74 16.06L9.21 12.53C8.91 12.24 8.91 11.76 9.21 11.47Z"
        fill="#2F3454"
      />
    </svg>
  );
}

export function SearchIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.5 9C16.5 12.5579 13.399 15.5 9.5 15.5C5.60096 15.5 2.5 12.5579 2.5 9C2.5 5.44208 5.60096 2.5 9.5 2.5C13.399 2.5 16.5 5.44208 16.5 9Z"
        stroke="black"
      />
      <path
        d="M18.1367 18C17.9155 18 17.6943 17.9139 17.5346 17.754L15.2488 15.4666C14.9171 15.1345 14.9171 14.5934 15.2488 14.249C15.5806 13.917 16.1214 13.917 16.4654 14.249L18.7512 16.5365C19.0829 16.8686 19.0829 17.4097 18.7512 17.754C18.5791 17.9139 18.3579 18 18.1367 18Z"
        fill="#292D32"
      />
    </svg>
  );
}
