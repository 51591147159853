import { useEffect, useMemo, useState } from "react";
import moment from "moment";
import { toast } from "react-toastify";

import tokenAPI from "network/token";
import LinkedinApplyUsersApi from "network/external/linkedin_apply_users";
import Button from "components/Button/ButtonV2";
import IconV2 from "components/V2/Icons/IconV2";
import InputV2 from "components/V2/Input/InputV2";
import useDebounce from "hooks/useDebounce";
import { ILinkedinApplyUsersFilters } from "types/Filters/LinkedinApplyUsers";
import { ILinkedinApplyUser } from "types/LinkedinApplyUser";
import PagesBar from "components/V2/Pagination/PagesBar";
import Loader from "components/V2/Loader/Loader";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";

const PAGE_SIZE = 15;

const LinkedinUsersOverview = () => {
  const [data, setData] = useState<ILinkedinApplyUser[]>();
  const [count, setCount] = useState<number | undefined>()
  const [filters, setFilters] = useState<ILinkedinApplyUsersFilters>({});
  const [inviting, setInviting] = useState<boolean>(false);
  const setFilter = <Key extends keyof ILinkedinApplyUsersFilters>
    (field: Key, value: ILinkedinApplyUsersFilters[Key]) => { setFilters({ ...filters, [field]: value }); }

  const debounceSetFilter = useDebounce(setFilter);

  // Fetch one page
  const fetchPage = useMemo(() =>
    async (offset: number): Promise<any[] | undefined> => {
      try {
        const response = await LinkedinApplyUsersApi.getAll({
          ...filters,
          page_size: PAGE_SIZE,
          offset
        })
        if (!response.success) throw new Error(JSON.stringify(response))
        return response.users
      } catch (e) {
        toast.error('Error while fetching page')
        console.error(e)
      }
    }, [filters]
  );

  // Fetching count is expensive do it separetly
  const fetchCount = async () => {
    try {
      const response = await LinkedinApplyUsersApi.getAll({
        ...filters,
        count: true,
        page_size: PAGE_SIZE
      })
      if (!response.success) throw new Error(JSON.stringify(response))
      setCount(response.count)
    } catch (e) {
      toast.error('Error while fetching total amount of talent')
      console.error(e)
    }
  };
  useEffect(() => { fetchCount() }, [filters]);

  // Function to invite to sign up 
  const invite = async (email: string) => {
    setInviting(true);
    try {
      const response = await tokenAPI.inviteFriends([email]);
      if (!response.data.success) throw new Error(JSON.stringify(response));
      const talent = data?.find(t => t.email === email);
      // Locally update the referral on success
      if (talent) talent.AnyReferral = { createdAt: new Date() } as any;
      setData(data && [...data]);
    } catch (e) {
      toast.error('Error while sending invite')
      console.error(e)
    }
    setInviting(false);
  }

  const { signed_up, invited } = filters;

  return (
    <div className="w-full h-full flex flex-col px-[50px]">
      <div className="flex flex-col mt-[52px] w-full gap-5">
        <span className="text-[24px] font-poppins font-bold flex ">
          Linkedin Database:&nbsp;
          {count === undefined ? <Loader className='w-[30px] h-[30px]' /> : <span>{count}</span>}
        </span>
        {/* Filters */}
        {/* Search bar */}
        <InputV2
          className="w-[50%]"
          onChange={(e) => debounceSetFilter("keyword", e.target.value)}
          icon={<IconV2 iconType="SEARCH" iconClassName="w-6 h-6 translate-y-[1px]" />}
          placeholder="Search for talent by skills, role, email or name "
        />
        {/* Categories */}
        <div className="flex gap-[20px] w-full flex-wrap">
          <CategoryButton text="Invited to Outdefine" active={!!invited} set={(v) => setFilter("invited", v || undefined)} />
          <CategoryButton text="Signed up" active={!!signed_up} set={(v) => setFilter("signed_up", v || undefined)} />
        </div>
      </div>

      <div className="flex flex-col flex-1 w-full overflow-auto mt-[21px] items-center pb-[100px]">
        {/* Data */}
        {/* Table */}
        <table className="table-fixed break-words w-full  border-separate border-spacing-y-6 
            font-bold font-poppins text-[14px]">
          <thead >
            <tr className="[&>th]:bg-white [&>th]:py-[20px] [&>th]:h-[72px]">

              <th className="rounded-bl-lg rounded-tl-lg">Profile</th>
              <th>Name</th>
              <th>Role</th>
              <th>Location</th>
              <th>Email/Phone</th>
              <th>Linkedin url</th>
              <th>Region</th>
              <th className="rounded-br-lg rounded-tr-lg ">Signed up</th>
            </tr>
          </thead>


          <tbody >
            {(data || []).map(t => (
              <tr key={t.email} className="mt-[20px] [&>td]:bg-white [&>td]:py-[20px] [&>td]:h-[72px] [&>td]:border-none" >
                <td className="rounded-bl-lg rounded-tl-lg pl-[20px]">
                  <UserAvatarLogo className="w-[45px]" user={{ avatar: t.profile_image } as any} />
                </td>
                <td>{t.full_name}</td>
                <td>{t.role}</td>
                <td>{t.location}</td>
                <td>{`${t.email} \n ${t.phone_number}`}</td>
                <td><a className="text-blue2 underline" target="_blank" href={t.linkedin_url}>{t.linkedin_url}</a></td>
                <td>{t.region}</td>
                <td className="rounded-br-lg rounded-tr-lg font-inter font-semibold text-sm text-start">
                  <div className="flex flex-col gap-3">
                    {t.User && <p className="text-blue2">Signed up</p>}
                    {!!t.AnyReferral && <p>{moment(t.AnyReferral.createdAt).format("MM/DD/YYYY")}</p>}
                    <p>Status &nbsp;&nbsp;
                      <span className={t.AnyReferral ? "text-blue2" : "text-[#717171]"}>
                        {t.AnyReferral ? "Invited" : "Not invited"}
                      </span>
                    </p>
                    <Button onClick={() => invite(t.email)} disabled={!!t.AnyReferral} loading={inviting} variant="primary2">
                      Send ODF invite
                    </Button>
                  </div>
                </td>
              </tr>))
            }
          </tbody>
        </table>

        {/* Loader */}
        {!data && <div className="w-full h-[100px]"><Loader /></div>}


        {/* Pages bar */}
        <PagesBar
          page_size={PAGE_SIZE}
          className="mt-[8px]"
          count={count}
          fetchPage={fetchPage}
          onLoading={() => setData(undefined)}
          onPageChange={(data) => setData(data)}
        />
      </div>

    </div>);
}

const CategoryButton = ({ text, set, active, withCheck = false }:
  { text: string, set: (v: boolean) => void, active: boolean, withCheck?: boolean }) => (
  <Button
    className={`flex items-center gap-[12px] w-fit h-[40px] rounded-3xl !px-[16px] !py-[8px] font-poppins font-bold text-[14px] text-white
      ${active ? "bg-blue2 " : "bg-blue2-hue "} `}
    onClick={() => set(!active)}
  >
    {text}
    {withCheck && <IconV2 iconType="CHECK-CIRCLE" iconClassName={`${active ? "fill-blue2" : "fill-inactive-gray"}`} />}
  </Button>
)

export default LinkedinUsersOverview;