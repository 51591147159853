import React, { ReactElement } from "react";
import { getCountries, getCountryCallingCode } from "react-phone-number-input";
import en from "react-phone-number-input/locale/en.json";
import DropdownV2 from "../Dropdown/DropdownV2";

// Most popular dial_codes should be on top
const _order = {
  IN: 5,
  PK: 4,
  US: 3,
  NG: 2,
  MX: 1,
};

export const dialCodes = getCountries().map((country, index) => {
  const code = `+${getCountryCallingCode(country)}`;
  return (
    {
      id: index,
      value: `${en[country]} ${code}`,
      code,
      country_code: country,
    });
}).sort((a, b) => {
  // Put the most popular dial_codes at the start
  const [orderA, orderB] = [_order[a.country_code], _order[b.country_code]]
  if (orderA || orderB) {
    return (orderA || 0) < (orderB || 0) ? 1 : -1;
  }
  return a.value < b.value ? -1 : 1;
});
dialCodes.forEach((d, index) => { d.id = index; });

type IDialCodeObject = typeof dialCodes[0];

export interface IDialCodeInput extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "value" | "onChange"> {
  value?: IDialCodeObject;
  ref?: any;
  onChange?: (_: IDialCodeObject) => void;
}

const DialCodeInput = ({
  ...props
}: IDialCodeInput): ReactElement => {
  const index = props?.value?.id;
  const onChange = (idx): void => {
    const value: any = dialCodes[idx];
    props.onChange && props.onChange(value);
  };

  return <DropdownV2
    data={dialCodes}
    {...props}
    selectedIndex={index}
    onChange={onChange}
  />;
};

export default DialCodeInput;
