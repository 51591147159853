const DashboardIcon = (props: INavIcon) => {
  const fillColor = props.light ? '#FFFFFF' : '#2F3454';
  return (
    <svg
      width='24'
      height='24'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M7.24 2H5.34C3.15 2 2 3.15 2 5.33V7.23C2 9.41 3.15 10.56 5.33 10.56H7.23C9.41 10.56 10.56 9.41 10.56 7.23V5.33C10.57 3.15 9.42 2 7.24 2Z'
        fill={fillColor}
      />
      <path
        d='M18.6695 2H16.7695C14.5895 2 13.4395 3.15 13.4395 5.33V7.23C13.4395 9.41 14.5895 10.56 16.7695 10.56H18.6695C20.8495 10.56 21.9995 9.41 21.9995 7.23V5.33C21.9995 3.15 20.8495 2 18.6695 2Z'
        fill={fillColor}
      />
      <path
        d='M18.6695 13.43H16.7695C14.5895 13.43 13.4395 14.58 13.4395 16.76V18.66C13.4395 20.84 14.5895 21.99 16.7695 21.99H18.6695C20.8495 21.99 21.9995 20.84 21.9995 18.66V16.76C21.9995 14.58 20.8495 13.43 18.6695 13.43Z'
        fill={fillColor}
      />
      <path
        d='M7.24 13.43H5.34C3.15 13.43 2 14.58 2 16.76V18.66C2 20.85 3.15 22 5.33 22H7.23C9.41 22 10.56 20.85 10.56 18.67V16.77C10.57 14.58 9.42 13.43 7.24 13.43Z'
        fill={fillColor}
      />
    </svg>
  );
};

export default DashboardIcon;
