import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import dateUtils from "utils/dateUtils";
import utils from "utils/utils"
import Loader from "components/V2/Loader/Loader";
import jobAPI from "network/job"
import { JobPosting } from "types/JobPosting";
import { FreelancerProfile } from "types";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";
import IconV2 from "components/V2/Icons/IconV2";
import JobOfferModal from "../JobOfferModal";
import { JobContract } from "types/JobContract";
import MainTable from "components/V2/MainTable/MainTable";
import SkillsCell from "components/V2/MainTable/SkillsCell";
import TableWrapper from "./TableWrapper";

interface IHiredData extends FreelancerProfile {
    isRecommended: boolean;
    contractTermHours: string;
    contractCompensation: string;
    contractTerm: string;
    contract: JobContract;
}

interface IHired {
    job: JobPosting
}

const Hired = ({ job }: IHired) => {

    const [data, setData] = useState<IHiredData[]>();
    const [showModals, setShowModals] = useState<{ [key: number]: boolean }>({});
    const setModal = (id, open: boolean) => {
        const shows = { ...showModals };
        shows[id] = open;
        setShowModals(shows);
    }

    const fetchData = async () => {
        try {
            // Fetch the data
            const recommended = await jobAPI.getRecommendedTalentsForJob(job.id);
            if (!recommended.success) throw new Error("Error while retrieving recommended talent: " + recommended.message)
            const response = await jobAPI.getJobContractsByJobId(job.id);
            if (!response.success) throw new Error("Error while retrieving contracts: " + response.message);
            const { contracts } = response;

            // Process the data
            const data = {};
            const showModals = {};

            contracts.forEach(c => {
                const freelancer: IHiredData = c.FreelancerProfile;
                freelancer.isRecommended = false;
                freelancer.contractCompensation = `$${c.hourly_rate}/hr ${utils.capitalizeFirstLetter(c.pay_frequency)}`;
                freelancer.contractTerm =
                    `${dateUtils.getLocalDateFromUTCDate(c.contract_start).date}-${dateUtils.getLocalDateFromUTCDate(c.contract_end).date}`;
                freelancer.contractTermHours = `${c.term_of_hours}/hrs ${utils.capitalizeFirstLetter(c.term_of_hours_duration)}`;
                data[freelancer.freelancer_id] = freelancer;
                freelancer.contract = c;
                showModals[freelancer.freelancer_id] = false;
            })

            // Mark the recommended ones
            recommended.jobs.forEach(d => {
                const freelancer: IHiredData = d.Freelancer;
                if (data[freelancer.freelancer_id]) data[freelancer.freelancer_id].isRecommended = true;
            })

            setData(Object.values(data));
            setShowModals(showModals);

        } catch (e) {
            toast.error("Error while fetching hired talent data");
            console.error(e);
        }
    }
    useEffect(() => { if (job) fetchData() }, [job])

    if (!data) return <Loader />

    return (
        <TableWrapper>
            <MainTable
                heads={
                    <tr>
                        <th>Photo</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Years of experience</th>
                        <th>Compensation</th>
                        <th>Skills</th>
                        <th>Term/hours</th>
                        <th>Location</th>
                        <th>Contract term</th>
                    </tr>
                }
                rows={
                    data.map(d =>
                        <tr key={d.freelancer_id}>
                            <td> <UserAvatarLogo user={d.contract.User} withRedBadge={d.isRecommended} /></td>
                            <td>{`${d.contract.User.first_name} ${d.contract.User.last_name}`}</td>
                            <td>{d.Role?.name}</td>
                            <td>{d.years_of_experience}</td>
                            <td>{d.contractCompensation}</td>
                            <td>
                                <SkillsCell skills={d.skills} />
                            </td>
                            <td>{d.contractTerm}</td>
                            <td>{utils.capitalizeFirstLetter(d.contract.location)}</td>
                            <td>
                                <div>{d.contractTerm}</div>
                                <div
                                    onClick={() => setModal(d.freelancer_id, true)}
                                    className="h-[32px] w-[32px] mx-auto rounded-md bg-[#F4F4F4] flex items-center justify-center hover:cursor-pointer">
                                    <IconV2 iconType="EYE" />
                                </div>
                            </td>
                        </tr>

                    )
                }
            />

            {/* Modals for each offer detail */}
            {data.map(d =>
                <JobOfferModal
                    key={d.freelancer_id}
                    freelancer={d}
                    isOpen={showModals[d.freelancer_id]}
                    onClose={() => setModal(d.freelancer_id, false)}
                    offer={d.contract}
                />
            )}
        </TableWrapper>
    );
}

export default Hired;