import { Routes, Route } from 'react-router-dom';

import pathUtils from 'utils/pathUtils';

import ViewAllPage from 'views/application/database/View.All';
import IndustryViewPage from 'views/application/database/View.Industry';
import RoleViewPage from 'views/application/database/View.Role';
import SkillViewPage from 'views/application/database/View.Skill';
import DatabaseLayout from 'views/application/database/components/Layout.Database';

const routes = [
  {
    path: '/',
    element: <ViewAllPage />,
  },
  {
    path: pathUtils.DATABASE_INDUSTRY,
    element: <IndustryViewPage />,
  },
  {
    path: pathUtils.DATABASE_ROLE,
    element: <RoleViewPage />,
  },
  {
    path: pathUtils.DATABASE_SKILL,
    element: <SkillViewPage />,
  },
];

const DatabaseRoutes: React.FC = () => {
  return (
    <DatabaseLayout>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
      </Routes>
    </DatabaseLayout>
  );
};

export default DatabaseRoutes;
