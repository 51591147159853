import { API } from "aws-amplify";
import awsUtils from "utils/awsUtils";

// import types
export interface ICommentBoard {
  id?: number;
  question_id?: number;
  content?: string;
  votes?: number;
  views?: number;
}
interface IGetBoardsFilters {
  popular?: boolean;
  query?: {
    // Meta:
    limit?: number; // page size
    skip?: number; // offeset if doing offset pagination
    total?: boolean; // add total count or not

    // Filters
    search?: string;
    freelancer_id?: number;
  }
}

const getBoards = async ({ popular, query }: IGetBoardsFilters) => {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  let path = popular ? `/board/question/popular?` : `/board/question?`;

  // Add query params
  if (query) {
    Object.keys(query).forEach((key) => (path += `&${key}=${query[key]}`));
  }

  const myInit = {
    headers: {
      ...authHeader,
    },
  };

  return API.get(apiName, path, myInit);
};

const getBoard = async (boardId) => {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/board/question/dynamic/id/${boardId}`;
  const myInit = {
    headers: {
      ...authHeader,
    },
  };

  const response = await API.get(apiName, path, myInit);
  if (!response.question) throw new Error("Board not found");
  response.question = response.question;

  return response;
};

const curateBoard = async (input: {
  question_id: number;
  isCurated: boolean;
  isHidden: boolean;
}) => {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/board/question/curate`;
  const myInit = {
    headers: {
      ...authHeader,
    },
    body: JSON.stringify(input),
  };

  const response = await API.put(apiName, path, myInit);

  return response;
};

export default {
  getBoards,
  getBoard,
  curateBoard,
};
