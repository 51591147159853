import React from 'react';
import { Controller } from 'react-hook-form';
import StarRating from 'react-svg-star-rating';

type Props = {
  control: any;
  name: string;
  rules?: any;
  isReadOnly?: boolean;
};

const FormStarRating = (props: Props) => {
  const { control, name, isReadOnly } = props;
  return (
    <Controller
      control={control}
      name={name}
      rules={props.rules}
      render={({ field: { onChange, value } }) => (
        <StarRating
          innerRadius={30}
          initialRating={value}
          handleOnClick={onChange}
          containerClassName='flex'
          isReadOnly={isReadOnly}
        />
      )}
    />
  );
};

export default FormStarRating;
