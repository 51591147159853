
import { isProduction } from "helpers/env";
import { ReputationTag } from "network/reputation/types";
import { ReputationStatus } from "types/Reputation";

export const reputationTitles: { [key in ReputationTag]: string } = {
    "identity_proof": "Proof of identity",
    "personhood": "Personhood"
}

export const statusTitles: { [key in ReputationStatus]: string } = {
    "DENIED": "Denied",
    "SUBMITTED": "Pending",
    "VERIFIED": "Verfied"
}

export const assigneesData = isProduction ? [
    { id: 349, value: "Raman" },
    { id: 348, value: "Ignacio" },
    { id: 11492, value: "Gonzalo" }
] : [
    { id: 175, value: "Jean" },
    { id: 9, value: "Romil" }
];

export const statusIcons: { [key in ReputationStatus]: any } = {
    "DENIED": "CLOSE-CIRCLE",
    "SUBMITTED": "MINUS-CIRCLE",
    "VERIFIED": "CHECK-CIRCLE"
}