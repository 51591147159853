import { useState } from "react";
import Button from "components/Button/ButtonV2";
import { useDispatch } from "react-redux";
import { AppDispatch } from "app/store";
import { confirmInvoice } from "redux/slices/invoice";

interface Props {
  id: string;
}

const ConfirmButton = ({ id }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const dispatch = useDispatch<AppDispatch>();
  const handler = async () => {
    try {
      setIsLoading(true);
      await dispatch(confirmInvoice(id));
    } catch (e) {}
    setIsLoading(false);
  };

  return (
    <Button
      onClick={handler}
      className="font-inter font-semibold text-sm text-white !py-3 !px-5 bg-[#5F5FFF] rounded-xl w-fit"
      loading={isLoading}
    >
      Mark as paid
    </Button>
  );
};

export default ConfirmButton;
