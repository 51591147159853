import BadgeV2 from "components/V2/Badges/BadgeV2";
import IconV2 from "components/V2/Icons/IconV2";
import { useAppSelector } from "redux/hooks/redux-hooks";
import { FreelancerProfile } from "types";
import { getResumeName } from "utils/talent/utils";
import utils from "utils/utils";
import ExpEducationDetail from "views/application/talent/components/ExpEducationDetail";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";

interface IProfile {
    talent: FreelancerProfile
}

const Profile = ({ talent }: IProfile) => {

    const { User, Role, resume_presigned } = talent;
    const industries = useAppSelector((root) => root.prototype.data);
    const industry = industries.find(i => i.job_type_id === Role?.jobTypeJobTypeId);
    const hourlyRate = talent.hourly_rate ? `$${talent.hourly_rate}/hr` : "N/A";

    return (
        <div className="flex flex-col py-[55px] pl-[193px] pr-[301px]">

            {/* Header */}
            <div className="flex flex-col items-center">
                <UserAvatarLogo user={User} className="!w-[100px] !h-[100px] mb-[5px]" />
                <span className="font-poppins font-bold text-[20px]">{`${User.first_name} ${User.last_name}`}</span>
                {!!Role && <span className="font-inter font-semibold text-[16px] mt-[8px]">{Role.name}</span>}
                <div className="flex gap-x-[25px] mt-[14px]">
                    <div className="flex gap-x-[8px]">
                        <IconV2 iconType="EDUCATION" iconClassName="w-[17px]" />
                        <span className="font-inter font-semibold text-[14px] text-dark-gray">{talent.years_of_experience || "N/A"}</span>
                    </div>
                    <div className="flex gap-x-[8px]">
                        <IconV2 iconType="DOLLAR-BLUE-CIRCLE" iconClassName="w-[17px]" />
                        <span className="font-inter font-semibold text-[14px] text-dark-gray">{hourlyRate}</span>
                    </div>
                    <div className="flex gap-x-[8px]">
                        <IconV2 iconType="LOCATION" iconClassName="w-[17px]" />
                        <span className="font-inter font-semibold text-[14px] text-dark-gray">{talent.city || "N/A"}</span>
                    </div>
                </div>
            </div>

            {/* About me */}
            <div className="flex flex-col mt-[35px]">
                <span className="font-poppins font-semibold text-[20px]" >About me</span>
                <span className="font-inter text-[12px] mt-[10px]">{talent.summary || "N/A"}</span>
                {!!Role && <BadgeV2 addClass="mt-[13px]">{Role.name}</BadgeV2>}

                <div className="flex gap-x-[110px] mt-[48px]">
                    <div>
                        <span className="font-poppins font-semibold text-[14px]">Experience level</span>
                        <div className="flex flex-wrap gap-x-[6px] mt-[6px]">
                            <BadgeV2>{talent.level_of_experience || "N/A"}</BadgeV2>
                            <BadgeV2>{talent.years_of_experience || "N/A"}</BadgeV2>
                        </div>
                    </div>
                    <div>
                        <span className="font-poppins font-semibold text-[14px]">Hourly rate</span>
                        <BadgeV2 addClass="mt-[6px]">{hourlyRate}</BadgeV2>
                    </div>
                    <div>
                        <span className="font-poppins font-semibold text-[14px]">Open to</span>
                        <div className="flex flex-wrap gap-x-[6px] mt-[6px]">
                            {JSON.parse(talent.terms_open_to || "[]").map(
                                ({ value }) => <BadgeV2 key={value} color="orange" addClass="capitalize">{utils.capitalizeFirstLetter(value)}</BadgeV2>)
                            }
                        </div>
                    </div>
                </div>
                <div className="mt-[40px]">
                    <span className="font-poppins font-semibold text-[14px]">Skills</span>
                    <div className="flex flex-wrap gap-[6px] mt-[6px]">
                        {
                            talent.skills.filter(skill => skill.freelancer_skill.is_primary).map(({ name }) =>
                                <BadgeV2 key={name} starInBadge color="pink">{name}</BadgeV2>
                            )
                        }
                        {
                            talent.skills.filter(skill => !skill.freelancer_skill.is_primary).map(({ name }) =>
                                <BadgeV2 key={name} >{name}</BadgeV2>
                            )
                        }
                    </div>
                </div>
            </div>

            {/* Assessment */}
            {!!Role &&
                <div className="mt-[40px]">
                    <span className="font-poppins font-semibold text-[20px]">Assessments</span>

                    <div className="flex w-[358px] mt-[22px] pl-[11px] pt-[27px] pb-[36px] border-solid border-[1px] border-dark-gray rounded-lg">
                        <div className="flex w-[52px] h-[52px] items-center justify-center bg-odf rounded-lg">
                            <IconV2 iconType="CODEWHITE" iconClassName="w-[26px] h-[26px]" />
                        </div>
                        <div className="flex flex-col ml-[16px]">
                            <span className="font-poppins font-bold text-[16px]">{Role.name}</span>
                            <BadgeV2 addClass="mt-[6px]">{industry ? industry.name : "N/A"}</BadgeV2>
                        </div>
                    </div>

                </div>}

            {/* Resume */}
            {resume_presigned &&
                <a href={resume_presigned} target="_blank" className="mt-[20px] cursor-pointer">
                    <span className="font-poppins font-semibold text-[20px]">Resume</span>
                    <div className="flex w-[358px] mt-[6px] px-[17px] pt-[19px] pb-[16px] border-solid border-[1px] border-dark-gray rounded-lg">
                        <div className="flex w-[43px] h-[43px] items-center justify-center bg-odf rounded-lg">
                            <IconV2 iconType="BOOK-WHITE" iconClassName="w-[26px] h-[26px]" />
                        </div>
                        <div className="flex flex-col ml-[19px]">
                            <span className="font-poppins font-bold text-[16px]">Resume</span>
                            <span className="font-semibold font-inter text-[14px]">{getResumeName(talent)}</span>
                        </div>

                    </div>
                </a>
            }

            {/* Education and Experience */}
            <ExpEducationDetail talent={talent} />
        </div>
    );
}

export default Profile;