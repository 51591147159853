import { useEffect, useState } from "react";

import jobAPI from "network/job";
import IconV2 from "components/V2/Icons/IconV2";
import { JobPosting } from "types/JobPosting";
import dateUtils from "utils/dateUtils";
import { Company } from "types/Company";
import CompanyLogo from "../../components/CompanyLogo";
import BadgeV2 from "components/V2/Badges/BadgeV2";
import { outdefineURLs } from "config/outdefine/outdefine-urls";
import FindTalent from "./Tabs/FindTalent.Tab";
import MatchedRecommended from "./Tabs/MatchedRecommended.Tab";
import Interview from "./Tabs/Interview.Tab";
import Offers from "./Tabs/Offers.Tab";
import Hired from "./Tabs/Hired.Tab";
import MainTable from "components/V2/MainTable/MainTable";
import MainTabs from "components/V2/MainTabs/MainTabs";
import { getJobPrimarySkills } from "utils/jobUtils";
import { getCurrencySymbol } from "utils/utils";

interface IJobPost {
  company: Company;
  job: JobPosting;
}

const JobPost = ({ job, company }: IJobPost) => {
  const [applications, setApplications] = useState<any[]>([]);

  // Fetch all the applications to this job
  const fetchApplications = async () => {
    const { applications } = await jobAPI.getJobApplications({ job_id: job.id });
    setApplications(applications);
  };
  useEffect(() => {
    if (job.id) fetchApplications();
  }, [job]);

  let compensation = "";
  if (job.hourly_min_rate || job.hourly_max_rate)
    compensation = `${getCurrencySymbol(job.currency)}${job.hourly_min_rate}-${job.hourly_max_rate}`;
  if (job.salary_period) compensation += `/${job?.salary_period === "Hourly" ? "hr" : "yr"}`

  return (
    <div>
      <div className="flex flex-col gap-6">
        {/* Job information */}
        <MainTable
          heads={
            <tr>
              <th>Logo</th>
              <th>Company name</th>
              <th>Open role</th>
              <th>Experience needed</th>
              <th>Compensation</th>
              <th>Skills needed</th>
              <th>Location</th>
              <th>Date posted</th>
              <th>Submittals</th>
              <th>View job post</th>
            </tr>
          }
          rows={[
            <tr key={0}>
              <td>
                <div className="w-[75px] flex items-center mx-auto">
                  <CompanyLogo company={company} />
                </div>
              </td>
              <td>{company.name}</td>
              <td>{job.job_title}</td>
              <td>{job.experience_level}</td>
              <td>{compensation}</td>
              <td>
                <div className="flex justify-center flex-wrap gap-[8px]">
                  {getJobPrimarySkills(job).map((s) => (
                    <BadgeV2 key={s.name} color="pink" starInBadge>
                      {s.name}
                    </BadgeV2>
                  ))}
                </div>
              </td>
              <td>{job.location}</td>
              <td>{dateUtils.getLocalDateFromUTCDate(job.date_posted).date}</td>
              <td>{applications.length}</td>
              <td>
                <a
                  href={outdefineURLs.frontend.jobPost(job.id)}
                  className="h-[32px] w-[32px] mx-auto rounded-md bg-[#F4F4F4] flex items-center justify-center"
                >
                  <IconV2 iconType="EYE" />
                </a>
              </td>
            </tr>,
          ]}
        />

        {/* Talents */}
        <MainTabs
          tabs={[
            "Matched/Applied talent",
            "Find talent",
            "Interview invite",
            "Extended offers",
            "Hired",
          ]}
          contents={[
            <MatchedRecommended job={job} />,
            <FindTalent job={job} company={company} />,
            <Interview job={job} />,
            <Offers job={job} />,
            <Hired job={job} />,
          ]}
          tabNavigations={["matched", "find", "invited", "offers", "hired"]}
        />
      </div>
    </div>
  );
};

export default JobPost;
