import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import StarRating from "react-svg-star-rating";
import stageUtils from 'utils/stageUtils';

import utils from "utils/utils";
import profileAPI from "network/profile";
import Loader from "components/V2/Loader/Loader";
import UserAvatarLogo from "../components/UserAvatarLogo";
import IconV2 from "components/V2/Icons/IconV2";
import BadgeV2 from "components/V2/Badges/BadgeV2";
import { averageRating, getResumeName } from "utils/talent/utils";
import EditTalentContactModal from "./components/EditTalentContactModal";
import EditTalentModal from "./components/EditTalentModal";
import MainTabs from "components/V2/MainTabs/MainTabs";
import Assesments from "./components/Tabs/Assessments/Assesments.tab";
import TalentProfile from "./components/Tabs/TalentProfile/TalentProfile.tab";
import { FreelancerProfileWithRewards } from "types/FreelancerProfileWithRewards";
import ENUMS from "constants/enums";
import { FreelancerProfile, InternalRating } from "types";
import DropdownV2 from "components/V2/Dropdown/DropdownV2";

const ratingStyles: { [key in InternalRating]: string } = {
    "A": "!bg-[#DEF3DE] text-green",
    "B": "!bg-orange-hue text-orange",
    "C": "!bg-odf-light text-odf",
    "F": "!bg-coral-red-hue text-coral-red",
}
const ratingValues = [{ value: '-' }, { value: 'A' }, { value: 'B' }, { value: 'C' }, { value: 'F' }];

const TalentDetail = () => {
    const { email } = useParams();
    const navigate = useNavigate();
    const [freelancer, setFreelancer] = useState<FreelancerProfileWithRewards>();
    const [editingRating, setEditingRating] = useState<boolean>(false);
    const [editingTalentContact, setEditingTalentContact] = useState<boolean>(false);
    const [editingTalent, setEditingTalent] = useState<boolean>(false);
    const publicProfileLink = `${stageUtils.getWebsiteLink()}talent/${freelancer?.User.slug}`;

    const onCopy = () => {
        navigator.clipboard.writeText(window.location.href);
        toast.success("Copied!")
    }

    const fetchData = async () => {
        if (!email) return;

        try {
            const user = await profileAPI.getUserWithEmail(email);
            const freelancer = await profileAPI.getFreelancerById(user.data.user_id);
            if (!freelancer.data || !freelancer.data.freelancer_id) throw new Error(freelancer.message);
            setFreelancer(freelancer.data);
        } catch (e) {
            toast.error("Error while fetching talent data");
            console.error(e);
        }
    }
    useEffect(() => { fetchData() }, []);
    const { totalRewards, totalReferralRewards } = useMemo(() => {
        if (!freelancer) return {};
        return {
            totalRewards: freelancer.Rewards.reduce((acc, r) => acc + r.amount, 0),
            totalReferralRewards: freelancer.Rewards.reduce((acc, r) => acc + (r.received_from === ENUMS.achievements.REFERRAL_FOR_SIGNUP ? r.amount : 0), 0),
        }
    }, [freelancer])

    if (!freelancer) return <Loader />

    const { User, internal_rating, freelancer_id } = freelancer;
    const { website_link, linkedin_link, github_link } = freelancer.FreelancerProfileSocialLink || {};
    const skills = freelancer.skills || [];
    const terms_open_to = freelancer.terms_open_to ? JSON.parse(freelancer.terms_open_to) : [];
    const locations = freelancer.roles_open_to ? JSON.parse(freelancer.roles_open_to) : [];
    const rating = averageRating(freelancer.User?.AssessmentInfo?.rating);
    const resume = getResumeName(freelancer);

    const setRating = async (index: number) => {
        const internalRating = (index ? ratingValues[index].value : null) as any;
        setEditingRating(true);
        try {
            const response = await profileAPI.updatePreference
                ({ freelancer_id, internal_rating: internalRating } as FreelancerProfile);
            if (!response.success) throw new Error(JSON.stringify(response));
            freelancer.internal_rating = internalRating;
        } catch (e) {
            console.error(e);
            toast.error('Error while marking rating')
        }
        setEditingRating(false)
    }

    return (
        <div className="w-full h-screen overflow-auto">
            {/* Profile data */}
            <div>
                {/* Go back row */}
                <div className="flex pl-[37px] pr-[25px] h-[53px] w-full items-center">
                    <div className="flex items-center hover:cursor-pointer" onClick={() => navigate(`/talent/overview`)}>
                        <IconV2 iconType="BACK-ARROW" iconClassName="w-[25px] h-[25px]" />
                        <span className="text-[14px] font-inter font-semibold ml-[15px]">Back</span>
                    </div>
                </div>

                {/* First row */}
                <div className="flex items-end h-fit py-[11px] pl-[37px] pr-[25px] border-solid border-y-[1px] border-dark-gray">
                    <div className="flex items-center gap-x-[18px]">
                        <UserAvatarLogo user={User} className="!w-[70px] !h-[70px]" />
                        <div className="flex flex-col">
                            <span className="font-poppins font-semibold text-[16px]">{`${User.first_name} ${User.last_name}`}</span>
                            <span className="font-inter font-semibold text-[14px]">{freelancer?.Role?.name || "N/A"}</span>
                        </div>
                    </div>
                    <div className="flex flex-1 items-end justify-between ml-[60px] mb-[12px]">
                        <div className="flex gap-x-[48px]">
                            <div className="flex gap-[12px]">
                                <IconV2 iconType="EDUCATION" iconClassName="w-[17px]" />
                                <span className="font-inter font-semibold text-[12px] text-dark-gray">{freelancer.years_of_experience}</span>
                            </div>
                            <div className="flex gap-[12px]">
                                <IconV2 iconType="DOLLAR-BLUE-CIRCLE" iconClassName="w-[17px]" />
                                <span className="font-inter font-semibold text-[12px] text-dark-gray">{`$${freelancer.hourly_rate}/hr`}</span>
                            </div>
                            <div className="flex gap-[12px]">
                                <IconV2 iconType="LOCATION" iconClassName="w-[17px]" />
                                <span className="font-inter font-semibold text-[12px] text-dark-gray">{freelancer.city}</span>
                            </div>
                        </div>
                        <div className="flex gap-[13px]">
                            <a href={publicProfileLink} target="_blank" className="w-[32px] h-[32px] bg-[#F4F4F4] flex items-center rounded-lg">
                                <IconV2 iconType="EYE" iconClassName="mx-auto w-[20px]" />
                            </a>
                            <div onClick={onCopy} className="w-[32px] h-[32px] bg-[#F4F4F4] flex items-center rounded-lg hover:cursor-pointer">
                                <IconV2 iconType="CLIPBOARD" iconClassName="w-[20px] mx-auto" />
                            </div>
                            <div onClick={() => setEditingTalentContact(true)} className="w-[32px] h-[32px] bg-[#F4F4F4] flex items-center rounded-lg hover:cursor-pointer">
                                <IconV2 iconType="EDIT" iconClassName="mx-auto w-[20px]" />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Second row */}
                <div className="flex pt-[15px] pb-[13px] px-[37px] justify-between border-solid border-b-[1px] border-dark-gray">
                    <div className="flex gap-[28px]">
                        <span className="font-inter font-semibold text-[14px]">Contact</span>
                        <div className="flex gap-[10px]">
                            <IconV2 iconType="EMAIL" />
                            <span className="font-inter text-[14px]">{User.email_id}</span>
                        </div>
                        <div className="flex gap-[10px]">
                            <IconV2 iconType="PHONE" />
                            <span className="font-inter text-[14px]">{freelancer.User.full_phone}</span>
                        </div>
                    </div>
                    <div className="flex gap-[28px]">
                        <span className="font-inter font-semibold text-[14px]">Talent socials</span>
                        <div className="flex gap-[24px]">
                            {!!website_link && <a target="_blank" href={utils.hrefLink(website_link)}><IconV2 iconType="PORTFOLIO" /></a>}
                            {!!linkedin_link && <a target="_blank" href={utils.hrefLink(linkedin_link)}><IconV2 iconType="LINKEDIN" /></a>}
                            {!!github_link && <a target="_blank" href={utils.hrefLink(github_link)}><IconV2 iconType="GITHUB" /></a>}
                        </div>
                    </div>
                </div>

                {/* Third row */}
                <div className="relative flex pt-[29px] px-[37px] pb-[73px]">
                    <div className="flex flex-col gap-y-[22px] w-[60%]">
                        <div className="flex gap-x-[12px]">
                            <span className="font-inter text-[14px] min-w-[120px]">Primary skills</span>
                            <div className="flex flex-wrap gap-[8px]">
                                {
                                    skills.filter(skill => skill.freelancer_skill.is_primary).map(skill =>
                                        <BadgeV2 key={skill.name} starInBadge color="pink">{skill.name}</BadgeV2>
                                    )
                                }
                            </div>
                        </div>
                        <div className="flex gap-x-[12px]">
                            <span className="font-inter text-[14px] min-w-[120px]">Secondary skills</span>
                            <div className="flex flex-wrap gap-[8px]">
                                {
                                    skills.filter(skill => !skill.freelancer_skill.is_primary).map(skill =>
                                        <BadgeV2 key={skill.name} >{skill.name}</BadgeV2>
                                    )
                                }
                            </div>
                        </div>
                        <div className="flex gap-x-[12px]">
                            <span className="font-inter text-[14px] min-w-[120px]">Employment term</span>
                            <div className="flex flex-wrap gap-[8px]">
                                {terms_open_to.length ? terms_open_to.map(term =>
                                    <BadgeV2 key={term.value} color="orange">{utils.capitalizeFirstLetter(term.value)}</BadgeV2>
                                ) :
                                    <span className="font-inter text-[14px] font-semibold">N/A</span>
                                }
                            </div>
                        </div>
                        <div className="flex gap-x-[12px]">
                            <span className="font-inter text-[14px] min-w-[120px]">Location open to</span>
                            <div className="flex flex-wrap gap-[8px]">
                                {locations.length ? locations.map(l =>
                                    <BadgeV2 key={l.value} color="orange">{utils.capitalizeFirstLetter(l.value)}</BadgeV2>
                                ) :
                                    <span className="font-inter text-[14px] font-semibold">N/A</span>
                                }
                            </div>
                        </div>
                        <div className="flex gap-x-[12px]">
                            <span className="font-inter text-[14px] min-w-[120px]">City, country</span>
                            <span className="font-inter text-[14px] font-semibold">{freelancer.city}</span>
                        </div>
                        <div className="flex gap-x-[17px]">
                            <span className="font-inter text-[14px] min-w-[120px]">Total token earnings</span>
                            <span className="font-inter text-[14px] font-semibold flex gap-[16px]">
                                <IconV2 iconType="TOKEN" iconClassName="w-[20px] h-[20px]" />
                                {totalRewards}
                            </span>
                        </div>
                        <div className="flex gap-x-[17px] items-center">
                            <span className="font-inter text-[14px] min-w-[120px]">A,B,C,F</span>
                            <span className="font-inter text-[14px] font-semibold flex gap-[16px]">
                                `  {editingRating ?
                                    <Loader className="w-[30px] h-[30px] mx-auto" />
                                    :
                                    <DropdownV2
                                        buttonClass={"w-[70px] h-[37px] !px-[16px] !font-inter !text-[14px] !border-none text-blue2 " +
                                            (ratingStyles[internal_rating as InternalRating] || " !bg-blue2-hue")}
                                        selectedValue={internal_rating}
                                        onChange={(index) => setRating(index)}
                                        data={ratingValues}
                                    />
                                }
                            </span>
                        </div>
                    </div>
                    <div className="flex flex-col gap-y-[22px] w-[40%]">
                        <div className="flex gap-x-[12px]">
                            <span className="font-inter text-[14px] min-w-[170px]">Hourly wage</span>
                            <span className="font-inter text-[14px] font-semibold">{`$${freelancer.hourly_rate}/ hr USD`}</span>
                        </div>
                        <div className="flex gap-x-[12px]">
                            <span className="font-inter text-[14px] min-w-[170px]">Years of experience</span>
                            <span className="font-inter text-[14px] font-semibold">{freelancer.years_of_experience}</span>
                        </div>
                        <div className="flex gap-x-[12px]">
                            <span className="font-inter text-[14px] min-w-[170px]">Experience level</span>
                            <span className="font-inter text-[14px] font-semibold">{freelancer.level_of_experience}</span>
                        </div>
                        <div className="flex gap-x-[12px] items-center">
                            <span className="font-inter text-[14px] min-w-[170px]">Overall interview rating</span>
                            <StarRating
                                isReadOnly
                                unit="float"
                                innerRadius={30}
                                initialRating={rating}
                                containerClassName="flex justify-center"
                            />
                            <span className="font-inter text-[14px] font-semibold">{rating.toFixed(1)} star avg </span>
                        </div>
                        <div className="flex gap-x-[12px]">
                            <span className="font-inter text-[14px] min-w-[170px]">Resume</span>
                            {resume ?
                                <a href={freelancer.resume_presigned} target="_blank" className="font-inter text-[14px] font-semibold underline">{resume}</a>
                                :
                                <span className="font-inter text-[14px] font-semibold">N/A</span>
                            }
                        </div>
                        <div className="flex gap-x-[17px]">
                            <span className="font-inter text-[14px] min-w-[120px]">Total referral earnings</span>
                            <span className="font-inter text-[14px] font-semibold flex gap-[16px]">
                                <IconV2 iconType="TOKEN" iconClassName="w-[20px] h-[20px]" />
                                {totalReferralRewards}
                            </span>
                        </div>
                    </div>

                    <div onClick={() => setEditingTalent(true)}
                        className="w-[32px] h-[32px] bg-[#F4F4F4] flex items-center rounded-lg hover:cursor-pointer absolute right-[26px] top-[14px]">
                        <IconV2 iconType="EDIT" iconClassName="mx-auto w-[20px]" />
                    </div>
                </div>
            </div>

            {/* Tabs */}
            <MainTabs
                tabNavigations={["assessments", "profile"]}
                tabs={["Assessments", "Talent profile"]}
                contents={[
                    <Assesments talent={freelancer} />,
                    <TalentProfile talent={freelancer} />
                ]}
            />

            {/* Modals */}
            <EditTalentContactModal
                talent={freelancer}
                onSuccess={() => fetchData()}
                onClose={() => setEditingTalentContact(false)}
                isOpen={editingTalentContact}
            />

            <EditTalentModal
                talent={freelancer}
                onSuccess={() => fetchData()}
                onClose={() => setEditingTalent(false)}
                isOpen={editingTalent}
            />
        </div>
    );
}

export default TalentDetail;