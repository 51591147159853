import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import applicationAPI from "network/application";
import IconV2 from "components/V2/Icons";
import InputV2 from "components/V2/Input/InputV2";
import { Company } from "types/Company";
import ArrowButton from "components/ArrowButton";
import CompanyLogo from "../components/CompanyLogo";
import dateUtils from "utils/dateUtils";
import Loader from "components/V2/Loader/Loader";
import MainTable from "components/V2/MainTable/MainTable";
import { Sort } from "types/Sort";
import utils from "utils/utils";
import { THsort } from "components/V2/THsort/THsort";

function getLastPosted(company: Company) {
    const { JobsPosting } = company;

    const dates = (JobsPosting || []).map(job => new Date(job.date_posted));

    let lastDate;
    dates.forEach(date => {
        const time = date.getTime()
        if (!lastDate || time > lastDate.getTime()) lastDate = date;
    });

    return lastDate ? dateUtils.getLocalDateFromUTCDate(lastDate.toString()).date : null;
}

const CompanyOverview = () => {
    const navigate = useNavigate();
    const [searchValue, setSearchValue] = useState<string>("");
    const [sort, setSort] = useState<{ sort: Sort, getField?: Function } | undefined>();
    const _setSort = (field, order, getField) => order ? setSort({ sort: [field, order], getField }) : setSort(undefined);
    console.log(sort)
    const [field, order] = [sort?.sort[0], sort?.sort[1]];
    const [companies, setCompanies] = useState<Company[]>();
    const fetchCompanies = async () => {
        const data = await applicationAPI.getCompanyList();
        setCompanies(data);
    }
    useEffect(() => { fetchCompanies() }, [])

    // Filter by search input
    const sValue = searchValue.toLowerCase()
    const filtered = useMemo(() =>
        companies?.filter(c => c.name.toLowerCase().includes(sValue)
            || (c.JobsPosting || []).some(({ job_title }) => job_title.toLowerCase().includes(sValue))), [companies, searchValue]);
    // Order if required
    const ordered = useMemo(() =>
        sort && field && filtered ?
            utils.sortData(filtered, sort.getField || field, { date: field === "_last", order }) : filtered,
        [filtered, sort])

    if (!companies) return <Loader />

    const gotoDetailedView = async (id: number) => {
        navigate(`/company/details/${id}`);
    };

    return (
        <div className="w-full h-full flex flex-col">
            <div className="flex flex-col ml-[35px] mt-[52px] w-[80%]">
                <span className="text-[24px] font-poppins font-bold mb-[34px]">Companies</span>
                <InputV2 value={searchValue} onChange={(e) => setSearchValue(e.target.value)} icon={
                    <IconV2
                        iconType="SEARCH"
                        iconClassName="w-6 h-6 translate-y-[1px]"
                    />
                } placeholder="Search through our companies or by active jobs titles" />
            </div>

            <div className="h-full overflow-y-auto mt-[55px] flex-1">
                <MainTable
                    heads={
                        <tr>
                            <th>Logo</th>
                            <THsort sort={sort?.sort} field="name" onClick={_setSort} text="Company name" />
                            <THsort sort={sort?.sort} field="industry" onClick={_setSort} text="Industry" />
                            <THsort sort={sort?.sort} field="_active" getField={(c) => c?.JobsPosting?.length || 0} onClick={_setSort} text="Active jobs" />
                            <THsort sort={sort?.sort} field="number_of_employees" onClick={_setSort} text="Number of employees" />
                            <THsort sort={sort?.sort} field="website" onClick={_setSort} text="Website" />
                            <THsort sort={sort?.sort} field="_last" getField={getLastPosted} onClick={_setSort} text="Last posted" />
                            <THsort sort={sort?.sort} field="createdAt" onClick={_setSort} text="Sign up" />
                            <th />
                        </tr>
                    }
                    rows={
                        ordered.map(c => (
                            <tr key={c.company_id}>
                                <td>
                                    <div className="w-[75px] flex items-center mx-auto">
                                        <CompanyLogo company={c} />
                                    </div>
                                </td>
                                <td>{c.name}</td>
                                <td>{c.industry}</td>
                                <td>{c?.JobsPosting?.length || 0}</td>
                                <td>{c.number_of_employees}</td>
                                <td>{c.website}</td>
                                <td>{getLastPosted(c) || '-'}</td>
                                <td>{dateUtils.getLocalDateFromUTCDate(c.createdAt).date}</td>
                                <td>
                                    <div className="flex w-[80px] justify-center items-center">
                                        <ArrowButton
                                            direction="NEXT"
                                            onClick={() => gotoDetailedView(c.company_id)}
                                        />
                                    </div>
                                </td>
                            </tr>
                        )
                        )
                    } />

            </div>
        </div>);
}

export default CompanyOverview;