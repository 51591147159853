import StarRating from "react-svg-star-rating";
import Loader from "components/V2/Loader/Loader";
import jobAPI from "network/job"
import { useEffect, useState } from "react";
import { FreelancerProfile } from "types";
import utils from "utils/utils"
import { JobPosting } from "types/JobPosting";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";
import { averageRating } from "utils/talent/utils";
import usePrototypeUtils from "hooks/usePrototypeUtils";
import ArrowButton from "components/ArrowButton";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import MainTable from "components/V2/MainTable/MainTable";
import SkillsCell from "components/V2/MainTable/SkillsCell";
import TableWrapper from "./TableWrapper";

interface IMatchedRecommendedData extends FreelancerProfile {
    application_status: string;
    isRecommended: boolean;
}

interface IMatchedRecommended {
    job: JobPosting
}

const MatchedRecommended = ({ job }: IMatchedRecommended) => {

    const navigate = useNavigate();
    const { getJobMatchLevel } = usePrototypeUtils();
    const [data, setData] = useState<IMatchedRecommendedData[]>();

    // Fetch both applicants and recommended talent
    const fetchData = async () => {
        try {
            const recommended = await jobAPI.getRecommendedTalentsForJob(job.id);
            if (!recommended.jobs) throw new Error("Error fetching recommendation: " + recommended.message);
            const { applications } = await jobAPI.getJobApplications({ job_id: job.id });
            if (!applications) throw new Error("Error fetching applicants: " + applications.message);
            const data = {};

            applications.forEach((d) => {
                const freelancer: IMatchedRecommendedData = d.FreelancerProfile;
                freelancer.isRecommended = false;
                freelancer.application_status = utils.capitalizeFirstLetter(d.application_status);
                data[freelancer.freelancer_id] = freelancer;
            })

            recommended.jobs.forEach((d) => {
                const freelancer: IMatchedRecommendedData = d.Freelancer;
                const applicant = data[freelancer.freelancer_id];
                freelancer.isRecommended = true;
                freelancer.application_status = applicant ? applicant.application_status : "Recommended";
                data[freelancer.freelancer_id] = freelancer;
            })

            setData(Object.values(data));
        } catch (e) {
            toast.error("Error while fetching applicants data");
            console.error(e);
        }

    }
    useEffect(() => {
        if (!job) return;
        fetchData();
    }, [job])

    const goToDetailedtTalent = async (email) => navigate(`/talent/details/${email}`);

    if (!data) return <Loader />

    return (
        <TableWrapper>
            <MainTable
                heads={
                    <tr>
                        <th>Photo</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Years of experience</th>
                        <th>Compensation</th>
                        <th>Skills</th>
                        <th>Application status</th>
                        <th>Job match %</th>
                        <th></th>
                    </tr>
                }
                rows={
                    data.map(talent =>
                        <tr key={talent.freelancer_id}>
                            <td><UserAvatarLogo user={talent.User} withRedBadge={talent.isRecommended} /></td>
                            <td>{`${talent.User.first_name} ${talent.User.last_name}`}</td>
                            <td>{talent.Role?.name || 'N/A'}</td>
                            <td>{talent.years_of_experience}</td>
                            <td>{`$${talent.hourly_rate}/hr`}</td>
                            <td>
                                <SkillsCell skills={talent.skills} />
                            </td>
                            <td>{talent.application_status}</td>
                            <td>{getJobMatchLevel(talent, job).string}</td>
                            <td>
                                <div className="flex w-[80px] justify-center items-center">
                                    <ArrowButton
                                        direction="NEXT"
                                        onClick={() => goToDetailedtTalent(talent.User.email_id)}
                                    />
                                </div>
                            </td>
                        </tr>
                    )
                }
            />
        </TableWrapper>
    );
}

export default MatchedRecommended;