import { Routes, Route } from 'react-router-dom'

import pathUtils from 'utils/pathUtils'
import ReputationPage from 'views/application/reputation'
import ReputationDetail from 'views/application/reputation/detail'
import ReputationOverview from 'views/application/reputation/overview'

const routes = [
    {
        path: "/",
        element: <ReputationPage />,
    },
    {
        path: pathUtils.REPUTATION_OVERVIEW,
        element: <ReputationOverview />,
    },
    {
        path: pathUtils.REPUTATION_DETAIL,
        element: <ReputationDetail />,
    },
]

const ReputationRoutes: React.FC = () => {
    return (
        <Routes>
            {routes.map((route, index) => (
                <Route key={index} {...route} />
            ))}
        </Routes>
    )
}

export default ReputationRoutes
