import { ReactElement, useState } from 'react';

import Button from 'components/Button/ButtonV2';
import RoleOfJobTypeList from './components/List.RoleOfJobType';
import AddRoleModal from './components/Modal.AddRole';
import DebouncedInput from './components/DebouncedInput';

export default function RoleViewPage(): ReactElement {
  const [open, setOpen] = useState<boolean>(false);

  const [keyword, setKeyword] = useState<string>('');

  return (
    <>
      <div className='p-8'>
        <div className='flex justify-between items-center'>
          <span className='font-poppins font-semibold text-xl'>
            Job titles/roles
          </span>
          <Button
            onClick={() => setOpen(true)}
            className='font-poppins font-semibold text-sm leading-4 '
          >
            Add a job title or role
          </Button>
        </div>

        <div className='pt-5'>
          <DebouncedInput
            placeholder='Search through our job titles, skills and industries we support'
            value={keyword}
            onChange={setKeyword}
          />
        </div>

        <div className='pt-5 flex gap-x-4'>
          <RoleOfJobTypeList index={0} keyword={keyword} />
          <RoleOfJobTypeList index={1} keyword={keyword} />
          <RoleOfJobTypeList index={2} keyword={keyword} />
        </div>
      </div>
      <AddRoleModal open={open} setOpen={setOpen} />
    </>
  );
}
