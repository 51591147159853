import { API } from "aws-amplify";

import Button from "components/Button/ButtonV2";
import InputV2 from "components/V2/Input/InputV2";
import TextareaV2 from "components/V2/Textarea/TextareaV2";
import { ChangeEvent, SyntheticEvent, useState } from "react";

import awsUtils from "../../../utils/awsUtils";
import { toast } from "react-toastify";

export default function TestEmailSimulator() {
  const [form, setForm] = useState({
    subject: "",
    title: "",
    content: "",
    actionButton: "",
    styleCSS: "",
  });

  const changeHandler = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target;
    setForm({ ...form, [name]: value });
  };

  const submitHandler = async (e: SyntheticEvent) => {
    e.preventDefault();
    try {
      const authHeader = await awsUtils.getAuthHeader();
      const apiName = awsUtils.getAPIName();
      const path = `/triggerEmailSimulation`;
      const myInit = {
        headers: {
          ...authHeader,
        },
        body: JSON.stringify(form),
      };
      const result = await API.post(apiName, path, myInit);
      console.log("result: ", result);
    } catch (e) {
      console.error(e);
      toast.error("Error occured.");
    }
  };

  return (
    <div className="pt-20 flex flex-col items-center w-full">
      <h1 className="text-center text-2xl font-semibold">Test Emails Easily</h1>

      <form onSubmit={submitHandler} className="mt-10 min-w-[600px]" noValidate>
        <label className="block mb-4">
          Subject:
          <InputV2
            placeholder="Welcome to Outdefine"
            name="subject"
            value={form.subject}
            onChange={changeHandler}
          />
        </label>

        <label className="block mb-4">
          Title:
          <TextareaV2
            placeholder="Hi James! Congrats your board has been selected as curated by the Outdefine community."
            name="title"
            value={form.title}
            onChange={changeHandler}
          />
        </label>

        <label className="block mb-4">
          Content:
          <TextareaV2
            placeholder={`<a
            class="btn-link"
            style="width: fit-content; background-color: #5F5FFF; border-radius: 8px; color: white; font-weight: 600; font-family: Poppins; font-size: 14px; padding: 16px 20px; display: block; margin-top: 32px; text-decoration: none;" target="_blank" href="https://outdefine.com/community/board/">View your board</a>`}
            name="content"
            value={form.content}
            onChange={changeHandler}
          />
        </label>

        <label className="block mb-4">
          Button Link:
          <TextareaV2
            placeholder={`<a>Button Link to Append</a>`}
            name="actionButton"
            value={form.actionButton}
            onChange={changeHandler}
          />
        </label>

        <label className="block mb-4">
          Extra CSS Style:
          <TextareaV2
            placeholder={`.modal { display: flex }`}
            name="styleCSS"
            value={form.styleCSS}
            onChange={changeHandler}
          />
        </label>

        <Button type="submit" className="mx-auto flex">
          Give it a try
        </Button>
      </form>
    </div>
  );
}
