import { ReputationTag } from 'network/reputation/types';
import IdentityProofPages from './IdentityProof';
import PersonhoodPages from './Personhood';

export interface IPageProps {
  repu
}

interface IPage {
  Comp: (props: IPageProps) => JSX.Element;
  title: string;
}

export const pages: { [key in ReputationTag]: IPage[] } = {
  "identity_proof": IdentityProofPages,
  "personhood": PersonhoodPages
}