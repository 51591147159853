import { Controller } from "react-hook-form";
import UploadResume, { IUploadResume } from "./UploadResume";

interface IFormUploadResume extends IUploadResume {
    name: string;
    control: any;
    rules?: Record<any, any>;
}

export const FormUploadResume = ({
    name,
    control,
    rules,
    ...props
}: IFormUploadResume) => {
    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({ field: { onChange } }) => (
                <UploadResume
                    onChange={onChange}
                    {...props}
                />
            )}
        />
    );
};
