import { useForm } from "react-hook-form";

import Button from "components/Button/ButtonV2";
import IconV2 from "components/V2/Icons/IconV2";
import { FormSkillInputV2 } from "components/V2/SkillInput/SkillInput";
import FormStarRating from "components/V2/StarRating/FormStarRating";
import { FormTextareaV2 } from "components/V2/Textarea";
import { useAppSelector } from "redux/hooks/redux-hooks";
import { AssessmentInfo } from "types";
import Timeline from "components/V2/Timeline/Timeline";
import PassFailButtons from "../../../../PassFailButtons";
import ENUMS from "constants/enums";

interface IMcq {
    assessment: AssessmentInfo,
}

function getDefaultValues(assessment: AssessmentInfo) {
    return {
        rating: (JSON.parse(assessment.rating || '{}')).technicalSkillsRating,
        notes: (JSON.parse(assessment.notes_taken || '{}')).technicalSkillsNotes,
        skills: JSON.parse(assessment.technical_skills || '[]')
    }
}

const Mcq = ({ assessment }: IMcq) => {

    const skills = useAppSelector((root) => root.prototype.skills);
    const { control } = useForm({
        defaultValues: getDefaultValues(assessment)
    });
    const atLeastOne = (arr) => !!arr.length;

    const { mcq_status } = assessment;

    let timeline;
    switch (mcq_status) {
        case "TODO":
            timeline = 0;
            break;
        case "SUBMITTED":
            timeline = 1;
            break;
        case "FAILED":
            timeline = 2;
            break;
        case "PASSED":
            timeline = 2;
            break;
    }

    return (
        <div className="flex flex-col">

            {/* Header */}
            <div className="font-poppins text-[20px] font-semibold border-b pb-1 border-inactive-gray flex flex-row items-end justify-between">
                MCQ Coding Assessment (Engineers only)
                {ENUMS.hackerearth_assessment_type[0] === assessment.confirmed_type &&
                    <PassFailButtons status={mcq_status} />
                }
            </div>

            {/* Introduction section */}
            <span className="font-inter text-[12px] mt-[12px]">
                For engineering talent you will be able to see their MCQ score by using the pdf below.
            </span>
            <Timeline
                current={timeline}
                className="mt-[20px]"
                steps={["Not started MCQ", "In-progress", "Finished/Dropped off"]}
            />


            {/* Form inputs */}
            <div className="w-[300px] mt-[30px]">
                <span className="font-poppins font-semibold text-[16px]">Technical skills assessed</span>
                <FormSkillInputV2
                    icon={<IconV2 iconType="CODE" iconClassName="w-[20px]" />}
                    name="skills"
                    control={control}
                    rules={{ validate: atLeastOne }}
                    skillData={skills}
                    isPrimary={false}
                />
            </div>

            <span className="font-poppins text-[16px] font-semibold mt-[26px]">
                Rating
            </span>
            <span className="font-inter text-[12px] mt-[10px]">
                Rate your MCQ test using our 1-5 star system, 1 being strong NO hire
                and 5 being a strong hire.
            </span>
            <div className="mt-[10px]">
                <FormStarRating control={control} isReadOnly name="rating" />
            </div>

            <span className="font-poppins text-[12px] font-semibold mt-[13px]">Add notes</span>
            <FormTextareaV2
                control={control}
                name={"notes"}
                disabled
                className="mt-[18px] text-[12px] h-[108px]"
                limitText="200 words max"
                placeholder="Write down some notes"
            />

            <div className="w-full flex justify-end">
                <Button
                    className="px-4"
                    disabled
                    type="submit"
                    variant="secondary"
                >
                    Save changes
                </Button>
            </div>

        </div>);
}

export default Mcq;