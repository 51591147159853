import { AppDispatch, RootState } from 'app/store';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import prototypeAPI from 'network/prototype';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getProtoTypes } from 'redux/slices/prototype';

type TValid = 'VALID' | 'WARNING' | 'INVALID';

interface IValidation {
  valid: TValid;
  message: string;
  reference: string;
}

const stringSimilarity = require('string-similarity');

export default function useAddRoleModal(setOpen: (vis: boolean) => void) {
  const dispatch = useDispatch<AppDispatch>();

  const data = useSelector((root: RootState) => root.prototype.data);

  const [type, setType] = useState<any>();
  const [roleName, setRoleName] = useState<string>('');
  const [validation, setValidation] = useState<IValidation>({
    message: '',
    valid: 'INVALID',
    reference: '',
  });
  const [pending, setPending] = useState<boolean>(false);

  const validateRoleName = useCallback((): IValidation => {
    if (!roleName || !type)
      return { valid: 'INVALID', message: 'NON INPUT', reference: '' };

    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].roles.length; j++) {
        const dbRole = data[i].roles[j].name.toLowerCase();
        const roleNameInput = roleName.toLowerCase();
        // Exactly the same
        if (dbRole === roleNameInput) {
          return {
            valid: 'INVALID',
            message: 'Industry already exists, please add a new industry',
            reference: dbRole,
          };
        }

        // The same without whitespace
        else if (
          dbRole.replaceAll(' ', '') === roleNameInput.replaceAll(' ', '')
        ) {
          return {
            valid: 'INVALID',
            message:
              'Seems like an industry already exists, please check spaces in the industry',
            reference: data[i].roles[j].name,
          };
        } else if (
          stringSimilarity.compareTwoStrings(dbRole, roleNameInput) > 0.7
        ) {
          return {
            valid: 'WARNING',
            message: 'Similar one exists : ',
            reference: `"${data[i].roles[j].name}" in ${data[i].name}`,
          };
        }
      }
    }

    return { valid: 'VALID', message: '', reference: '' };
  }, [roleName, type]);

  useEffect(() => {
    setValidation({
      message: '',
      valid: 'INVALID',
      reference: '',
    });

    const timerID = setTimeout(() => {
      const result = validateRoleName();
      setValidation(result);
    }, 1500);

    return () => {
      clearTimeout(timerID);
    };
  }, [roleName, type]);

  const addRoleHandler = async () => {
    const job_type_id = data.find((elem) => elem.name === type)?.job_type_id;
    if (!job_type_id || validation.valid === 'INVALID') {
      return;
    }

    setPending(true);
    if (
      window.confirm(
        validation.valid === 'VALID'
          ? 'Please check if spelling is correct or we have duplicated one in our database. EX: Fullstack engineer vs Full-stack developer.'
          : 'Seems like we already have similar one in our database, do you still want to create?'
      )
    ) {
      try {
        const response = await prototypeAPI.createRole({
          job_type_id,
          name: roleName,
        });
        if (response.success === true) {
          toast.success('Success: A new role added.');
        } else {
          throw new Error('Error from backend');
        }
      } catch (e) {
        toast.error('There was an error while adding a new role.');
      }
    }

    await dispatch(getProtoTypes());
    setPending(false);

    closeHandler();
  };

  const closeHandler = () => {
    setType('');
    setValidation({
      message: '',
      valid: 'INVALID',
      reference: '',
    });
    setRoleName('');
    setOpen(false);
  };

  return {
    pending,
    data,
    type,
    roleName,
    validation,
    setType,
    setRoleName,
    addRoleHandler,
    closeHandler,
  };
}
