import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AssessmentAPI from "network/assessment";
import { AssessmentState } from "redux/types/assessment";
import type { PayloadAction } from "@reduxjs/toolkit";

const initialState: AssessmentState = {
  assessment_type: "RECORD",

  introduction_status: "TODO",

  interview_status: "TODO",

  hackerearth_status: "TODO",
  hackerearth_reviewed: false,

  experience_summary: "",
  recorded_video_link: "",
  portfolio_link: "",
  mcq_taken_count: 0,
  coding_taken_count: 0,
  manually_passed: false,
  booking_uid: "",
  rating: "",
  notes_taken: "",
  coding_skills: "[]",
  technical_skills: "[]",
  interview_technical_skills: "[]",
  HackerearthReport: null,
  HackerearthReportLog: [],
};

export const getAssessmentInfoByEmail = createAsyncThunk(
  "assessment/info/getOneByEmail",
  async (email: string, thunkAPI) => {
    const assessmentData = await AssessmentAPI.getAssessmentInfo(-1)
      .then((response) => response.data?.data)
      .catch((err) => console.error("Assessment data fetch error: ", err));
    return assessmentData;
  }
);

export const AssessmentSlice = createSlice({
  name: "assessment",
  initialState,
  reducers: {
    updateAssessmentDetail: (state, action: PayloadAction<any>) => {
      if (!action.payload) return state;
      state = { ...action.payload };
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAssessmentInfoByEmail.fulfilled, (state, action) => {
      const assessmentData = action.payload;
      state = assessmentData ? { ...assessmentData } : initialState;
      return state;
    });
  },
});

export const { updateAssessmentDetail } = AssessmentSlice.actions;
export default AssessmentSlice.reducer;
