import { ILinkedinApplyUsersFilters } from './../../types/Filters/LinkedinApplyUsers';
import { getQueryFromObject } from 'network/utils';
import { API } from 'aws-amplify';
import awsUtils from 'utils/awsUtils';

const apiName = awsUtils.getAPIName();

const getAll = async (filters: ILinkedinApplyUsersFilters = {}) => {
    const authHeader = await awsUtils.getAuthHeader();
    const query = getQueryFromObject(filters)
    const path = `/external/linkedin_apply_users/?${query}`;
    const myInit = {
        headers: { ...authHeader, },
    };

    const result = await API.get(apiName, path, myInit);
    return result;
};

const LinkedinApplyUsersApi = { getAll };

export default LinkedinApplyUsersApi;