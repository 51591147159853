import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
import moment from "moment";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";
import IconV2 from "components/V2/Icons/IconV2";
import BadgeV2 from "components/V2/Badges/BadgeV2";
import Loader from "components/V2/Loader/Loader";
import { useAppSelector } from "redux/hooks/redux-hooks";
import ConfirmButton from "../components/ConfirmButton";
import Button from "components/Button/ButtonV2";

const InvoiceDetail = () => {
  const { id } = useParams();
  const { invoices } = useAppSelector((state) => state.invoice);

  const invoice = invoices.find((item) => item.id === Number(id));

  if (!invoice) return <Loader />;
  const company = invoice?.Contract.Offer.JobPosting.Company;
  const User = invoice?.Contract.FreelancerProfile.User;
  const offer = invoice?.Contract.Offer;
  const profile = invoice?.Contract.FreelancerProfile;
  const date_start = new Date(offer?.contract_start).toLocaleDateString();
  const date_end = new Date(offer?.contract_end).toLocaleDateString();
  const contract_period = `${date_start} - ${date_end}`;
  const items = invoice?.InvoiceItems || [];
  const job_title = offer?.JobPosting?.job_title || "";
  const date_issued = moment(invoice?.date_issued).utc().format("MM/DD/YYYY");
  const diffDays = invoice?.due_days!;
  const date_processed = moment(invoice?.date_processed).utc().format("MM/DD/YYYY");
  const payment_status = invoice?.date_processed ? "Paid" : diffDays < 0 ? "Overdue" : "Received";
  const date_overDue = moment().add(diffDays, "days").utc().format("MM/DD/YYYY");
  const createdAt = moment(invoice?.createdAt).utc().format("MM/DD/YYYY");

  return (
    <main
      data-cy="invoice"
      className="w-full min-h-screen max-h-screen overflow-x-hidden overflow-y-scroll pt-[50px] px-[50px] pb-8"
    >
      <div className="flex flex-col w-full mx-auto max-w-[1226px]">
        <Link
          to="/invoice/overview"
          className="flex w-fit gap-x-3 items-center text-[#8A8A8A] font-semibold font-inter mb-5"
        >
          <IconV2 iconType="BTN-BACK-RECTANGLE" />
          Back
        </Link>
        <h3 className="text-[20px] font-poppins font-bold">{payment_status} invoice</h3>
        <section className="p-[60px]">
          <div className="flex flex-col gap-y-5 mb-5">
            <div className="flex gap-x-3 items-center">
              <UserAvatarLogo user={User} className="!w-[70px] !h-[70px] rounded-full mx-0" />
              <div className="flex flex-col gap-y-3">
                <span className="font-inter font-semibold text-[14px] text-black">
                  {User.first_name} {User.last_name}
                </span>
                <span className="font-inter font-semibold text-[14px] text-black">
                  {profile?.Role?.name}
                </span>
              </div>
            </div>
            <h3 className="text-[24px] font-poppins font-semibold text-black">{contract_period}</h3>
            <p className="text-[16px] font-inter font-semibold text-black">{invoice?.slug}</p>
          </div>
          <div className="flex flex-col gap-y-5 mb-5">
            <p className="text-base font-poppins font-semibold text-black">Items</p>
            <ul>
              {items.map((val, idx) => (
                <li className="p-5 bg-[#F0F1F2] rounded-lg flex items-center mb-4" key={idx}>
                  <div className="flex flex-col gap-y-3">
                    <span className="font-semibold font-inter text-sm text-[#8A8A8A]">
                      {val.description}
                    </span>
                    <span className="font-inter font-semibold text-base text-black">
                      {Number(val.hours)} hours at ${offer?.hourly_rate}
                    </span>
                  </div>

                  <div className="ml-auto flex items-center gap-x-3">
                    <div className="flex flex-col gap-y-1">
                      <span className="font-inter font-semibold text-2xl text-black">
                        $
                        {val.amount.toLocaleString(undefined, {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                      <span className="font-inter text-sm font-semibold text-[#8A8A8A]">
                        Net amount in ${offer?.JobPosting?.currency}
                      </span>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
            <p className="text-base font-poppins font-semibold text-black">Invoiced to</p>
            <div className="p-5 bg-[#F0F1F2] rounded-lg flex items-center">
              <div className="flex flex-col gap-y-3">
                <span className="font-semibold font-inter text-2xl text-black">
                  {company?.name}
                </span>
                <span className="font-inter font-semibold text-sm text-[#8A8A8A]">
                  {company?.city}
                </span>
              </div>
              <div className="ml-auto flex items-center gap-x-3">
                <div className="flex flex-col gap-y-1">
                  <span className="text-sm font-inter font-semibold text-black">{job_title}</span>
                  <span className="font-inter text-base font-semibold text-[#8A8A8A]">
                    {contract_period}
                  </span>
                </div>
              </div>
            </div>
            <p className="text-[16px] font-poppins font-semibold text-black">Remit to</p>
            <div className="p-5 bg-[#F0F1F2] rounded-lg">
              <div className="grid grid-cols-5 items-center mb-5 gap-x-5">
                <div className="flex flex-col gap-y-3 col-span-3">
                  <span className="text-2xl font-semibold font-inter text-black">Outdefine</span>
                  <span className="font-inter font-semibold text-base text-[#8A8A8A]">
                    250 King Street, Unit 368 , San Francisco, CA 94107, USA
                  </span>
                </div>

                <div className="flex items-center gap-x-3 col-span-2">
                  <div className="flex flex-col gap-y-1">
                    <span className="text-sm font-inter font-semibold text-[#8A8A8A]">
                      Beneficiary
                    </span>
                    <div>
                      <span className="text-sm font-inter font-semibold text-[#8A8A8A] mr-5">
                        Beneficiary name
                      </span>
                      <span className="text-base font-inter font-semibold text-black">
                        LRNED INC
                      </span>
                    </div>
                    <div>
                      <span className="text-sm font-inter font-semibold text-[#8A8A8A] mr-5">
                        IBAN /Account number
                      </span>
                      <span className="text-base font-inter font-semibold text-black">
                        202232550931
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid grid-cols-5 items-center gap-x-5">
                <div className="flex flex-col gap-y-3 col-span-3">
                  <span className="text-2xl font-semibold font-inter text-black">
                    Choice Financial Group
                  </span>
                  <span className="font-inter font-semibold text-base text-[#8A8A8A]">
                    4501 23rd Avenue S, Fargo, ND 58104, USA
                  </span>
                </div>

                <div className="flex items-center gap-x-3 col-span-2">
                  <div className="flex flex-col gap-y-1">
                    <span className="text-sm font-inter font-semibold text-[#8A8A8A]">
                      Receiving bank
                    </span>
                    <div>
                      <span className="text-sm font-inter font-semibold text-[#8A8A8A] mr-5">
                        SWIFT/BIC code
                      </span>
                      <span className="text-base font-inter font-semibold text-black">
                        CHFGUS44021
                      </span>
                    </div>
                    <div>
                      <span className="text-sm font-inter font-semibold text-[#8A8A8A] mr-5">
                        ABA routing number
                      </span>
                      <span className="text-base font-inter font-semibold text-black">
                        091311229
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full h-1 border-t border-t-[#A9ACB1] border-dashed block" />
            <div className="p-5 bg-[#F0F1F2] rounded-lg flex items-center justify-between">
              <span className="text-base font-inter font-semibold text-black">Outdefine fee</span>
              <span className="text-base font-inter font-semibold text-black">
                ${" "}
                {(Number(invoice?.total_amount) / 11).toLocaleString(undefined, {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}
              </span>
            </div>
            <div className="p-5 bg-[#F0F1F2] rounded-lg flex flex-col gap-y-5">
              <div className="bg-[#F0F1F2] rounded-lg flex items-center justify-between">
                <span className="text-base font-inter font-semibold text-black">Date issued</span>
                <span className="text-base font-inter font-semibold text-black">{date_issued}</span>
              </div>
              <div className="bg-[#F0F1F2] rounded-lg flex items-center justify-between">
                <span className="text-base font-inter font-semibold text-black">Due date</span>
                <span className="text-base font-inter font-semibold text-black">
                  {diffDays < 0 ? "Over due" : diffDays === 0 ? "Today" : `${diffDays} days`}
                </span>
              </div>
            </div>
            <h2 className="text-4xl font-poppins font-semibold text-black">
              ${" "}
              {invoice?.total_amount.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
            </h2>
            <p className="text-sm font-inter text-black font-semibold">
              Net amount in ${offer?.JobPosting?.currency}
            </p>
          </div>
          <div className="flex flex-col gap-y-3">
            <BadgeV2
              addClass={`px-3 py-2 font-inter font-semibold !text-sm ${
                payment_status === "Received" && "!bg-[#D9D9FF] !text-[#5F5FFF]"
              }
                ${payment_status === "Overdue" && "!bg-[#FFD1D1] !text-[#D32F2F]"}
                ${payment_status === "Paid" && "!bg-[#26993f26] !text-[#26993F]"}`}
            >
              {payment_status === "Received" && `Received ${createdAt}`}
              {payment_status === "Paid" && `Paid ${date_processed}`}
              {payment_status === "Overdue" && `Overdue ${date_overDue}`}
            </BadgeV2>
            {payment_status === "Paid" && (
              <BadgeV2 addClass="px-3 py-2 font-inter font-semibold !text-sm !bg-[#26993F] !text-white">
                Paid
              </BadgeV2>
            )}
            {payment_status === "Overdue" && (
              <Button className="font-inter font-semibold text-sm text-white !py-3 !px-5 bg-[#FF5757] rounded-xl w-fit">
                Send email
              </Button>
            )}
            {(payment_status === "Received" || payment_status === "Overdue") && (
              <ConfirmButton id={invoice.id.toString()} />
            )}
          </div>
        </section>
      </div>
    </main>
  );
};

export default InvoiceDetail;
