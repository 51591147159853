import IconV2 from 'components/V2/Icons/IconV2'
import { DashboardTime, Stats } from '../types'

interface IHeaderCard {
  title: string
  stats: Partial<Stats> & { count: number } // Only count is mandatory
  time: DashboardTime
  iconType: any
  iconWrapperClassName: string
  total?: number // Amount of total of the given data to calculate percentage
}

const HeaderCard = ({
  title,
  stats,
  time,
  iconType,
  iconWrapperClassName,
  total
}: IHeaderCard) => {
  return (
    <div className="w-full flex flex-col pt-[20px] pl-[20px] pb-[32px] rounded-md bg-white">
      <div
        className={`flex items-center justify-center rounded-full h-[50px] w-[50px] ${iconWrapperClassName}`}
      >
        <IconV2 iconType={iconType} iconClassName="w-[24px]" />
      </div>
      <span className="font-poppins font-bold text-[16px] text-inactive-gray mt-[11px]">
        {title}
      </span>
      <div className="flex items-center flex-wrap mt-[11px] gap-x-5 gap-y-2">
        <span className="font-poppins font-bold text-[24px]">
          {stats.count.toLocaleString()}
          {total != undefined ? ' (' + ((stats.count / total) * 100).toFixed(2) + '%)' : ''}
        </span>
        {stats?.variation?.[time] && (
          <div className="flex items-center gap-x-[6px] h-[26px] px-[7px] rounded-lg bg-success bg-opacity-10">
            <IconV2 iconType="DIAG-ARROW-UP-GREEN" />
            <span className="font-semibold font-inter text-[12px] text-success">
              {Math.ceil((stats.count * Number(stats.variation[time])) / 100)} (
              {stats.variation[time]}%)
            </span>
          </div>
        )}
      </div>
    </div>
  )
}

export default HeaderCard
