import countries from 'countries-list';
import DropdownV2 from '../Dropdown/DropdownV2';

const country_data = Object.values(countries.countries).map((c, i) => ({ value: c.name, id: i + 1 }));
country_data.unshift({ value: "", id: 0 });

interface ICountryDropdown {
    onChange?: (country: string) => void;
    className?: string;
    value?: string;
}

const CountryDropdown = ({ onChange, className, value }: ICountryDropdown) => {

    const icon = <div className='text-[20px]  '>{Object.values(countries.countries).find(c => c.name === value)?.emoji}</div>;

    return (
        <DropdownV2
            icon={icon}
            selectedValue={value}
            className={className}
            data={country_data}
            onChange={(id) => onChange?.(country_data[id].value)}
        />
    );
}

export default CountryDropdown;