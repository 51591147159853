import { API } from "aws-amplify";

import awsUtils from "utils/awsUtils";
import { getItem } from "utils/storageUtils";

async function getUserList() {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/user`;
  const myInit = {
    headers: { ...authHeader },
    response: true,
  };
  const result = await API.get(apiName, path, myInit);
  return result;
}

async function getCompanyList() {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/client/company`;
  const myInit = {
    headers: { ...authHeader },
    response: true,
  };
  const result = await API.get(apiName, path, myInit);
  return result?.data.all;
}

async function createInvoice(invoiceInfo: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/invoice`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(invoiceInfo),
  };
  const result = await API.post(apiName, path, myInit);
  return result.all;
}

async function getInvoiceList() {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/invoice`;
  const myInit = {
    headers: { ...authHeader },
  };
  const result = await API.get(apiName, path, myInit);
  return result.data;
}
async function getInvoiceListByCompanyId(companyId: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/invoice/company/${companyId}`;
  const myInit = {
    headers: { ...authHeader },
  };
  const result = await API.get(apiName, path, myInit);
  return result.data;
}

async function getInvoiceListByFreelanceId(freelancerId: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/invoice/${freelancerId}`;
  const myInit = {
    headers: { ...authHeader },
  };
  const result = await API.get(apiName, path, myInit);
  return result.data;
}

async function getInvoiceById(invoiceId: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/invoice/${invoiceId}`;
  const myInit = {
    headers: { ...authHeader },
  };
  const result = await API.get(apiName, path, myInit);
  return result.data;
}

async function updateInvoice(invoiceInfo: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/invoice`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(invoiceInfo),
  };
  const result = await API.put(apiName, path, myInit);
  return result.all;
}

async function confirmInvoice(invoice_id: string) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/invoice/confirmInvoice`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify({ invoice_id }),
  };
  const result = await API.post(apiName, path, myInit);
  return result.data;
}

async function inviteTeamMembers(inviteInfo: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/client/profile/inviteMembers`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(inviteInfo),
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

async function getTeamMembers(companyId: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/client/profile/getTeamMembers`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(companyId),
  };
  const result = await API.post(apiName, path, myInit);
  return result?.data;
}

async function updateTeamMember(teamMemberInfo: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/client/profile/updateTeamMember`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(teamMemberInfo),
  };
  const result = await API.post(apiName, path, myInit);
  return result.data;
}

async function deleteTeamMember(memberId: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/client/profile/${memberId}`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(memberId),
  };
  const result = await API.del(apiName, path, myInit);
  return result;
}

async function getClientProfile(clientId: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/client/profile/${clientId}`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(clientId),
  };
  const result = await API.get(apiName, path, myInit);
  return result;
}

async function updateClientProfile(clientInfo: any) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/client/profile/updateClientProfile`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify(clientInfo),
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

async function getAllIndustries() {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/jobTypes`;
  const myInit = {
    headers: { ...authHeader },
  };
  const result = await API.get(apiName, path, myInit);
  return result;
}

export default {
  getCompanyList,
  createInvoice,
  getInvoiceList,
  getInvoiceListByFreelanceId,
  getInvoiceListByCompanyId,
  getInvoiceById,
  updateInvoice,
  confirmInvoice,
  getTeamMembers,
  updateTeamMember,
  deleteTeamMember,
  inviteTeamMembers,
  getClientProfile,
  updateClientProfile,
  getUserList,
  getAllIndustries,
};
