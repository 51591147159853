import dateUtils from "utils/dateUtils";
import Loader from "components/V2/Loader/Loader";
import jobAPI from "network/job"
import { useEffect, useState } from "react";
import utils from "utils/utils"
import { JobPosting } from "types/JobPosting";
import { FreelancerProfile } from "types";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";
import { toast } from "react-toastify";
import MainTable from "components/V2/MainTable/MainTable";
import SkillsCell from "components/V2/MainTable/SkillsCell";
import TableWrapper from "./TableWrapper";

interface IInterviewData extends FreelancerProfile {
    isRecommended: boolean;
    application_status: string;
    acceptedDate: string;
}

interface IInterview {
    job: JobPosting
}

const Interview = ({ job }: IInterview) => {
    const [data, setData] = useState<IInterviewData[]>();

    // Fetch both applicants and recommended talent
    const fetchData = async () => {
        try {
            const recommended = await jobAPI.getRecommendedTalentsForJob(job.id);
            if (!recommended.jobs) throw new Error("Error fetching recommendation: " + recommended.message);
            let { applications } = await jobAPI.getJobApplications({ job_id: job.id });
            if (!applications) throw new Error("Error fetching applicants: " + recommended.message);
            applications = applications.filter(a => a.application_status === "INTERVIEW")
            const data = {};

            applications.forEach(d => {
                const freelancer: IInterviewData = d.FreelancerProfile;
                freelancer.isRecommended = false;
                freelancer.application_status = utils.capitalizeFirstLetter(d.application_status);
                freelancer.acceptedDate = d.updatedAt;
                data[freelancer.freelancer_id] = freelancer;
            })

            // Mark the recommended ones
            recommended.jobs.forEach(d => {
                const freelancer: IInterviewData = d.Freelancer;
                if (data[freelancer.freelancer_id]) data[freelancer.freelancer_id].isRecommended = true;
            })

            setData(Object.values(data));
        } catch (e) {
            toast.error("Error while retrieving interviewing applicants data");
            console.error(e);
        }
    }
    useEffect(() => {
        if (!job) return;
        fetchData();
    }, [job])

    if (!data) return <Loader />

    return (
        <TableWrapper>
            <MainTable
                heads={
                    <tr>
                        <th>Photo</th>
                        <th>Name</th>
                        <th>Role</th>
                        <th>Years of Experience</th>
                        <th>Compensation</th>
                        <th>Skills</th>
                        <th>Application status</th>
                        <th>Accepted</th>
                    </tr>
                }
                rows={data.map(talent =>
                    <tr>
                        <td><UserAvatarLogo user={talent.User} withRedBadge={talent.isRecommended} /></td>
                        <td>{`${talent.User.first_name} ${talent.User.last_name}`}</td>
                        <td>{talent.Role?.name || 'N/A'}</td>
                        <td>{talent.years_of_experience}</td>
                        <td>{`$${talent.hourly_rate}/hr`}</td>
                        <td>
                            <SkillsCell skills={talent.skills} />
                        </td>
                        <td>{talent.application_status}</td>
                        <td>
                            {dateUtils.getLocalDateFromUTCDate(talent.acceptedDate).date}
                        </td>
                    </tr>
                )}
            />
        </TableWrapper>
    );
}

export default Interview;