import IconV2 from "components/V2/Icons/IconV2";

const PassFailBadge = ({ passed }: { passed: boolean }) => {
    return (
        <div className={`h-fit px-[12px] py-[8px] flex gap-[12px] rounded-lg items-center justify-center ${passed ? "bg-[#DCFFE4]" : "bg-coral-red-hue"}`}>
            <IconV2 iconClassName={`w-[16px]  h-[16px] ${passed ? "" : "fill-red"}`} iconType={passed ? "CHECK" : "CLOSE"} />
            <span className={`text-[12px] font-inter font-bold ${passed ? "text-success" : "text-coral-red"}`}>
                {passed ? "Completed" : "Failed"}
            </span>
        </div>
    );
}

export default PassFailBadge;