import { useEffect, useState } from "react";
import assessmentAPI from "network/assessment";
import { AssessmentInfo, FreelancerProfile } from "types";
import Loader from "components/V2/Loader/Loader";
import { toast } from "react-toastify";
import OnlineInterview from "./OnlineInterview";
import Mcq from "./Mcq";
import CodingChallenge from "./CodingChallenge";

interface IAssessments {
    talent: FreelancerProfile
}

const Assessments = ({ talent }: IAssessments) => {

    const [assessment, setAssessment] = useState<AssessmentInfo>();

    const fetchData = async () => {
        try {
            const response = await assessmentAPI.getAssessmentInfo(talent.freelancer_id);
            if (!response?.data?.data) throw new Error(JSON.stringify(response));
            setAssessment(response.data.data)
        } catch (e) {
            console.error(e);
            toast.error("Error while fetching assessment data")
        }
    }
    useEffect(() => { if (talent) fetchData() }, [talent]);

    if (!assessment) return <Loader />

    return (
        <div className="flex flex-col gap-y-[100px] pl-[46px] w-[60%] py-[60px]">
            <OnlineInterview assessment={assessment} />
            <Mcq assessment={assessment} />
            <CodingChallenge assessment={assessment} />
        </div>
    );
}

export default Assessments;