import { ReactElement, memo } from 'react';
function IndustryList({ keyword }: { keyword?: string }): ReactElement {
  return (
    <div className='w-full h-fit border border-light-gray rounded-md overflow-hidden relative'>
      <div className='h-8 bg-[#F0F1F2] leading-8 text-[#8A8A8A] font-semibold text-base font-inter px-4'>
        Industries
      </div>
      <ul className='max-h-[500px] overflow-y-auto'>
        <span className='w-full absolute top-8 h-4 bg-gradient-to-b from-white to-transparent' />
        {[
          { id: '0x00', name: 'Web3' },
          { id: '0x01', name: 'Finance' },
          { id: '0x02', name: 'Saas' },
          { id: '0x03', name: 'Mobile' },
          { id: '0x04', name: 'Marketplace' },
        ]
          .filter((elem) =>
            !keyword
              ? true
              : elem.name.toLowerCase() === keyword?.toLowerCase() ||
                elem.name.toLowerCase().includes(keyword?.toLowerCase())
          )
          .map((role, index) => (
            <li
              key={role.id}
              className='h-8 font-inter font-semibold leading-8 px-4 border-b border-b-light-gray truncate last:border-none'
            >
              {index + 1}. {role.name}
            </li>
          ))}
        <span className='w-full absolute bottom-0 h-4 bg-gradient-to-t from-white to-transparent' />
      </ul>
    </div>
  );
}

export default memo(IndustryList);
