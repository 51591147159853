import { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import assigneeAPI from "network/assignee";
import profileAPI from "network/profile";
import ArrowButton from "components/ArrowButton";
import { FreelancerProfile, InternalRating } from "types";
import UserAvatarLogo from "../../components/UserAvatarLogo";
import { Assignee } from "types/Assignee";
import Loader from "components/V2/Loader/Loader";
import BadgeV2 from "components/V2/Badges/BadgeV2";
import { REGION_TYPE } from "types/Region";
import DropdownV2 from "components/V2/Dropdown/DropdownV2";
import { Order, Sort } from "types/Sort";
import { THsort } from "components/V2/THsort/THsort";
import { THdropdown } from "components/V2/THDropdown/THDropdown";
import { ITalentFilters } from "types/Filters/TalentFilters";

const ratingStyles: { [key in InternalRating]: string } = {
    "A": "!bg-[#DEF3DE] text-green",
    "B": "!bg-orange-hue text-orange",
    "C": "!bg-odf-light text-odf",
    "F": "!bg-coral-red-hue text-coral-red",
}
const ratingValues = [{ value: '-' }, { value: 'A' }, { value: 'B' }, { value: 'C' }, { value: 'F' }];

const regionStyles: { [key in REGION_TYPE]: string } = {
    "LATAM": "bg-odf-light text-odf",
    "APACIFIC": "bg-coral-red-hue text-coral-red",
    "EEUR": "bg-orange-hue text-orange",
    "CONUS": "bg-blue2-hue text-blue2",
}
const regionValues = ['-', ...Object.keys(regionStyles)];

interface ITalentTable {
    data?: FreelancerProfile[];
    refresh: (data: FreelancerProfile[]) => void;
    onSortChange: (sort?: Sort) => void;
    onFiltersChange: (filters: ITalentFilters) => void;
}

const TalentTable = ({ data, refresh, onSortChange, onFiltersChange }: ITalentTable) => {
    const [filters, setFilters] = useState<ITalentFilters>({});
    const [assignees, setAssignees] = useState<Assignee[]>();
    const [updating, setUpdating] = useState<{ [key: number]: boolean }>({});
    const [sort, setSort] = useState<Sort | undefined>();
    const _setSort = (field: string, order?: Order) => {
        let sort;
        if (!order) sort = undefined;
        else sort = [field, order];
        setSort(sort);
        onSortChange(sort)
    }
    // This helps with <th> filters
    const setFilter = (field, value) => {
        const _filters = { ...filters, [field]: value }
        if (value === undefined) delete _filters[field];
        setFilters(_filters);
        onFiltersChange(_filters);
    };
    const setThFilter = (field, value) => setFilter(field, value === '-' ? undefined : value);

    const assigneesValues = useMemo(
        () => assignees ? assignees.map(a => ({ value: a.User.first_name || "", id: a.id })) : []
        , [assignees]);

    const fetchAssignees = async () => {
        try {
            const response = await assigneeAPI.getAssigneeList();
            if (!response.success) throw new Error(response.message);
            setAssignees(response.all);
        } catch (e) {
            toast.error("Error while retrieving assignees");
            console.error(e);
        }
    }
    useEffect(() => { fetchAssignees() }, []);

    if (!assignees) return <Loader />

    const setRating = async (index: number, freelancer_id: number) => {
        const internalRating = (index ? ratingValues[index].value : null) as any;
        updating[freelancer_id] = true;
        setUpdating({ ...updating });
        try {
            const response = await profileAPI.updatePreference
                ({ freelancer_id, internal_rating: internalRating } as FreelancerProfile);
            if (!response.success) throw new Error(JSON.stringify(response));
            const profile = data?.find(t => t.freelancer_id === freelancer_id) as FreelancerProfile;
            profile.internal_rating = internalRating;
        } catch (e) {
            console.error(e);
            toast.error('Error while marking rating')
        }
        updating[freelancer_id] = false;
        setUpdating({ ...updating });

    }
    const setAssignee = async (index: number, freelancer_id: number) => {
        const assignee = (assigneesValues ? assigneesValues[index] : null) as any;
        updating[freelancer_id] = true;
        setUpdating({ ...updating });
        try {
            const response = await assigneeAPI.assignTalent([{ freelancer_id, assignee_id: assignee.id }]);
            if (!response.success) throw new Error(response.data);
            const profile = data?.find(t => t.freelancer_id === freelancer_id) as FreelancerProfile;
            if (profile?.FreelancerAssignee) profile.FreelancerAssignee.assignee_id = assignee.id;
            else profile.FreelancerAssignee = { freelancer_id, assignee_id: assignee.id }
            if (data) refresh([...data]);
        } catch (e) {
            toast.error("Error while assigning talent");
            console.error(e);
        }
        updating[freelancer_id] = false;
        setUpdating({ ...updating });
    }

    return (
        <div className="flex-1">
            {/* Table */}
            <table className="table-fixed break-words w-full  border-separate border-spacing-y-6 
            font-bold font-poppins text-[14px]">
                <thead >
                    <tr className="[&>th]:bg-white [&>th]:py-[20px] [&>th]:h-[72px]">
                        <THsort className="rounded-bl-lg rounded-tl-lg" field="User.first_name" text="Candidate" sort={sort} onClick={_setSort} />
                        <THdropdown data={ratingValues.map(({ value }) => ({ value, className: ratingStyles[value] }))}
                            className="!w-[100px]" text="A,B,C" onSelect={({ value }) => setThFilter("internal_rating", value)} />
                        <THdropdown data={regionValues.map((value) => ({ value, className: regionStyles[value] }))}
                            className="!w-[120px]" text="Region" onSelect={({ value }) => setThFilter("region", value)} />
                        <THsort field="city" text="Location" sort={sort} onClick={_setSort} />
                        <THsort field="User.email_id" text="Email" sort={sort} onClick={_setSort} />
                        <THsort className="!w-[100px]" field="years_of_experience" text="YOE" sort={sort} onClick={_setSort} />
                        <THsort className="!w-[100px]" field="hourly_rate" text="Rate" sort={sort} onClick={_setSort} />
                        <THsort field="Role.name" text="Stack" sort={sort} onClick={_setSort} />
                        <THdropdown data={[{ value: '-' }, ...assigneesValues.map(({ value, id }) => ({ value, id, className: "bg-odf-light text-odf" }))]}
                            text="Profile Manager" onSelect={({ id }) => id ? setThFilter("assignees", [id]) : setThFilter("assignees", undefined)} />
                        <th className="w-[80px] rounded-br-lg rounded-tr-lg"></th>
                    </tr>
                </thead>


                <tbody >
                    {(data || []).map(talent => (
                        <tr key={talent.freelancer_id} className="mt-[20px] [&>td]:bg-white [&>td]:py-[20px] [&>td]:h-[72px] [&>td]:border-none" >
                            <td className="rounded-bl-lg rounded-tl-lg pl-[20px]">
                                <div className="flex gap-[20px] items-center h-full ">
                                    <UserAvatarLogo className="w-[45px]" user={talent.User} />
                                    {`${talent.User.first_name} ${talent.User.last_name}`}
                                </div>
                            </td>
                            <td>
                                {updating[talent.freelancer_id] ?
                                    <Loader className="w-[30px] h-[30px] mx-auto" />
                                    :
                                    <DropdownV2
                                        buttonClass={"w-fit h-[37px] !px-[16px] !font-inter !text-[14px] !border-none text-blue2 " +
                                            (ratingStyles[talent.internal_rating as InternalRating] || " !bg-blue2-hue")}
                                        selectedIndex={ratingValues.findIndex(v => v.value === (talent.internal_rating || '-'))}
                                        arrowClassName="hidden"
                                        onChange={(index) => setRating(index, talent.freelancer_id)}
                                        data={ratingValues}
                                    />
                                }
                            </td>
                            <td >
                                <BadgeV2 color="none" addClass={"px-[12px] py-[8px] mx-auto " + regionStyles[talent?.region as REGION_TYPE]} >
                                    {talent?.region || "N/A"}
                                </BadgeV2>
                            </td>
                            <td>{talent.country || "N/A"}</td>
                            <td>{talent?.User.email_id}</td>
                            <td>{talent?.years_of_experience || "N/A"}</td>
                            <td>{`$${talent.hourly_rate || "?"}/hr`}</td>
                            <td>{talent?.Role?.name || "N/A"}</td>
                            <td>
                                {updating[talent.freelancer_id] ?
                                    <Loader className="w-[30px] h-[30px] mx-auto" />
                                    :
                                    <DropdownV2
                                        className="w-fit mx-auto"
                                        buttonClass="w-[100px] h-[37px] pr-[12px] !font-inter !text-[14px] !font-bold !border-none !bg-odf-light"
                                        selectedIndex={assigneesValues.findIndex(v => v.id === talent.FreelancerAssignee?.assignee_id)}
                                        onChange={(index) => setAssignee(index, talent.freelancer_id)}
                                        data={assigneesValues}
                                    />
                                }
                            </td>
                            <td className="rounded-br-lg rounded-tr-lg">
                                <a className="flex justify-center items-center" target="_blank"
                                    href={`/talent/details/${talent.User.email_id}`}>
                                    <ArrowButton onClick={() => null} direction="NEXT" />
                                </a>
                            </td>
                        </tr>))
                    }

                </tbody>
            </table>

            {/* Loader */}
            {data === undefined && <div className="w-full h-[100px]"><Loader /></div>}
        </div >
    );
}

export default TalentTable;