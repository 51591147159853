import { RootState } from 'app/store';
import { ReactElement, memo } from 'react';
import { useSelector } from 'react-redux';

interface PropsType {
  showRole?: boolean;
  keyword?: string;
}

function SkillList(props: PropsType): ReactElement {
  const skills = useSelector((root: RootState) => root.prototype.roles).map(
    (elem) =>
      elem.skills.map((skill) => {
        return { ...skill, role: elem.name };
      })
  );

  const flatten = skills
    .flat(2)
    .filter((elem) =>
      elem.name.toLowerCase().includes(props.keyword?.toLowerCase() || '')
    );

  return (
    <div className='w-full h-fit border border-light-gray rounded-md overflow-hidden relative'>
      <div className='h-8 bg-[#F0F1F2] leading-8 text-[#8A8A8A] font-semibold text-base font-inter px-4'>
        Skills
      </div>
      <ul className='w-full max-h-[500px] overflow-y-auto'>
        <span className='w-full absolute top-8 h-4 bg-gradient-to-b from-white to-transparent' />
        {flatten.map((skill, index) => (
          <li
            key={skill.id}
            className='h-8 font-inter font-semibold leading-8 px-4 border-b border-b-light-gray truncate last:border-none'
          >
            {index + 1}. {skill.name}
            <span className='text-light-gray'>
              {props.showRole ? ` - ${skill.role}` : ''}
            </span>
          </li>
        ))}
        <span className='w-full absolute bottom-0 h-4 bg-gradient-to-t from-white to-transparent' />
      </ul>
    </div>
  );
}

export default memo(SkillList);
