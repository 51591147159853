import {
  PolygonSdk,
  PolygonAmoySdk,
  PolygonAmoyDevSdk,
  getSdk as getPolygonSDK,
} from "@outdef/contracts-sdk/cjs/index";
import { ethers } from "ethers";
import { isDevelopment } from "helpers/env";

export type SDK = PolygonSdk & PolygonAmoySdk & PolygonAmoyDevSdk;

export const getSdk = async (provider: ethers.providers.Provider): Promise<SDK> => {
  const env = isDevelopment ? "development" : "production";
  const networkId = await provider.getNetwork();
  const networkName = networkId?.chainId === 137 ? "polygon" : "amoy";
  return getPolygonSDK(provider, env, networkName) as SDK;
};
