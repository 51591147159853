import { Routes, Route } from 'react-router-dom';

import pathUtils from 'utils/pathUtils';
import DetailedCompanyView from 'views/application/company/detail';
import CompanyOverview from 'views/application/company/overview';

const routes = [
  {
    path: pathUtils.COMPANY_OVERVIEW,
    element: <CompanyOverview />,
  },
  {
    path: pathUtils.COMPANY_DETAILS,
    element: <DetailedCompanyView />,
  },
];

const CompanyRoutes: React.FC = () => {
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
    </Routes>
  );
};

export default CompanyRoutes;
