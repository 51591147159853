import { useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/hooks/redux-hooks";

import Button from "components/Button/ButtonV2";
import { banFromCommnunity } from "redux/slices/banned_talent";
import { FreelancerProfile } from "types";

interface IBanButton {
    freelancer: FreelancerProfile,
    onBan?: Function,
    className?: string
}

const BanButton = ({ freelancer, onBan, className = "" }: IBanButton) => {
    const dispatch = useAppDispatch();
    const [banning, setBanning] = useState<boolean>(false);
    const banned_from_community = useAppSelector((root) => root.banned_talent.from_community);
    const isBanned = banned_from_community && banned_from_community[freelancer.freelancer_id];

    const handleBan = async () => {
        if (!window.confirm(`Are you sure you want to ${isBanned ? "unban" : "ban"} this user?`)) return;
        setBanning(true);
        try {
            const result: any = await dispatch(banFromCommnunity(freelancer));
            if (result.error) throw new Error(result.error);
            onBan?.();
        } catch (e) {
            toast.error("Error banning/unbanning user");
            console.error(e);
        }
        setBanning(false)
    }

    return (
        <Button onClick={handleBan}
            loading={!banned_from_community || banning}
            className={`${isBanned && "bg-blue2"} ` + className} >
            {isBanned ? "Unban" : "Ban"} user
        </Button>);
}

export default BanButton;