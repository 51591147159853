import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import assessmentAPI from "network/assessment";
import { FreelancerProfile } from "types";
import { interviewScheduled } from "utils/talent/utils";
import IconV2 from "components/V2/Icons/IconV2";
import BehavioralInterviewForm from "./BehavioralInterview";
import TechnicalInterview from "./TechincalInterview";
import PassFailButtons from "../../../../PassFailButtons";

interface IBehavioralInterview {
    talent: FreelancerProfile;
}

const BehavioralInterview = ({ talent }: IBehavioralInterview) => {

    const assessment = talent.User.AssessmentInfo;
    const [showTip, setShowTip] = useState<boolean>(false);

    if (!assessment) return null;

    return (
        <div className="flex">
            <div className="flex flex-col w-1/2 pl-[50px]">
                {
                    interviewScheduled(talent) &&
                    <>
                        <span className="font-poppins font-semibold text-[14px] mt-[40px]">Scheduled interview</span>
                    </>
                }


                {/* Forms */}
                <div className="flex flex-col mt-[106px] mb-[100px]">

                    {/* Header */}
                    <div className="relative font-poppins text-[20px] font-semibold border-b pb-1 border-inactive-gray flex flex-row items-end justify-between">

                        <div className="font-poppins text-[20px] font-semibold w-full pb-1 border-inactive-gray flex flex-row items-end justify-between">
                            In-person interview
                            <PassFailButtons status={assessment.interview_status} />
                        </div>

                        {/* Some tips for behavior interview */}
                        <div className="absolute bg-banner-orange w-[504px] right-[-550px] top-0 pl-[41px] pr-[23px] py-[27px] rounded-lg">
                            <div className="flex items-center justify-between">
                                <span className="font-poppins font-semibold text-[20px]">How to rate your behavioral interview</span>
                                <div onClick={() => setShowTip(!showTip)}>
                                    <IconV2 iconType={showTip ? "ARROW-UP" : "ARROW-DOWN"} iconClassName="hover:cursor-pointer" />
                                </div>
                            </div>
                            {showTip &&
                                <div className="font-inter text-[16px] font-normal mt-[10px]">
                                    <span >5 Credentials that make talent a 5 star rating:</span>
                                    <ul className="list-disc ml-[25px] mt-[28px]">
                                        <li>Does they have interpersonal skills?</li>
                                        <li>Are they team oriented?</li>
                                        <li>Are they adaptable?</li>
                                        <li>Do they have an openness to learn new things or challenges?</li>
                                        <li>Do they have the readiness to work in a remote setup?</li>
                                    </ul>
                                </div>
                            }
                        </div>
                    </div>


                    <BehavioralInterviewForm assessment={assessment} />
                    <TechnicalInterview assessment={assessment} />

                </div>
            </div>
            <div className="w-1/2"></div>
        </div>
    );
}

export default BehavioralInterview;