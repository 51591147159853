interface IMainTable {
  rows: JSX.Element[]
  heads: JSX.Element
}

const MainTable = ({ rows, heads }: IMainTable) => {
  return (
    <table className="table-fixed break-words w-full">
      <thead className="h-[50px] bg-[#D9D9D9] text-[14px] font-inter font-semibold sticky top-0">
        {heads}
      </thead>
      <tbody className="font-inter">{rows}</tbody>
    </table>
  )
}

export default MainTable
