import { API } from "aws-amplify";

import awsUtils from "utils/awsUtils";

async function getAssessmentInfo(user_id: number | string) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/assessment/info/get/?user_id=${user_id}`;
  const myInit = {
    headers: { ...authHeader },
    // body: JSON.stringify({ ...req }),
    response: true,
  };
  const result = await API.get(apiName, path, myInit);
  return result;
}


async function updateUserVetStatus({ vet_status }) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/user`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify({ vet_status }),
    response: true,
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

export default {
  updateUserVetStatus,
  getAssessmentInfo,
};
