import InputV2 from "components/V2/Input/InputV2";
import { useEffect, useState } from "react";

import type { ChangeEvent } from "react";

interface PropsType {
  placeholder?: string;
  icon?: React.ReactElement;
  onChange?: (value: string) => void;
}
export default function DebouncedInput({ onChange, ...props }: PropsType) {
  const [value, setValue] = useState<string>("");

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  useEffect(() => {
    const timerID = setTimeout(() => {
      onChange && onChange(value);
    }, 400);

    return () => {
      clearTimeout(timerID);
    };
  }, [value]);

  return (
    <InputV2
      placeholder={props?.placeholder}
      icon={props.icon}
      value={value}
      onChange={changeHandler}
    />
  );
}
