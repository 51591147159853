import { FreelancerAchievemntTypes } from "types/FreelancerAchievement";

const ENUM_TERM = ["Full time contract"];
const ENUM_ONBOARDING_STATUS = ["TODO", "STEP1", "STEP2", "STEP3", "STEP4", "STEP5", "COMPLETED"];
const ENUM_LEVEL_OF_EXPERIENCE = [
  "Entry-level",
  "Mid-level",
  "Senior-level",
  "Director-level",
];
const ENUM_YEARS_OF_EXPERIENCE = ["1-3", "3-5", "5-8", "8+"];
const ENUM_TIMEZONE = [
  "PST",
  "AST",
  "HST",
  "EST",
  "MST",
  "UTC-11",
  "CST",
  "AKST",
  "UTC+10",
];
const ENUM_LOCATION = ["REMOTE", "HYBRID", "ONSITE"];
const ENUM_NUMBER_OF_HIRES = ["1", "2", "3", "4", "5+"];
const ENUM_INTERVIEW_RATING = [0, 1, 2, 3, 4, 5];
const ENUM_ACTIVELY_HIRING = ["ACTIVE", "NOT ACTIVE"];
const ENUM_COMPANY_INDUSTRY_TYPES = [
  "Web3",
  "Finance",
  "Saas",
  "Mobile",
  "Marketplace",
  "Retail",
  "Health & Wellness",
  "Software",
  "Digital Infrastructure",
  "Entertainment",
  "Aviation",
  "Analytics",
];
const ENUM_COMPANY_STAGE = [
  "Pre-funded",
  "Early stage",
  "Late stage startup",
  "Public corporation",
  "Other",
];
const ENUM_COMPANY_NUMBER_OF_EMPLOYEES = [
  "1-10",
  "10-50",
  "51-200",
  "201-500",
  "501-1000",
  "1000+",
];

const ENUM_DEGREES = [
  "High School / GED",
  "Associate Degree",
  `Bachelor's Degree`,
  `Master's Degree`,
  "Doctoral Degree",
  "Bootcamp",
  "Other",
];

const ENUM_CLIENT_POSITION = [
  "ADMIN",
  "RECRUITER",
  "HIRING MANAGER",
  "BILLILNG",
];

const ENUM_PROFESSION = ["Engineering", "Product & Design", "Business "];
const ENUM_APPLICATION_STATUS = ["APPLIED", "REJECTED", "REVIEW", "INTERVIEW", "ARCHIVED"];
const ENUM_ASSESSMENT_STATUS = ["TODO", "SUBMITTED", "PASSED", "FAILED"];
const ENUM_IS_TRUSTED_TALENT = ["NOT_VERIFIED", "FAILED", "TRUSTED"];
const ENUM_ASSESSMENT_TYPE = ["HACKEREARTH", "RECORD"];

const MAP_ACHIEVEMENTS: { [key in FreelancerAchievemntTypes]: string } = {
  REFERRAL_FOR_SIGNUP: "REFERRAL_FOR_SIGNUP",
  HIVE_A_BOARD: "HIVE_A_BOARD",
  CONNECT_WALLET: "CONNECT_WALLET",

  // Level - 2
  HAVE_25_FOLLOWERS_AND_FOLLOWINGS: "HAVE_25_FOLLOWERS_AND_FOLLOWINGS",
  HAVE_5_REFERRALS_FOR_SIGNUP: "HAVE_5_REFERRALS_FOR_SIGNUP",
  HIVE_5_BOARDS: "HIVE_5_BOARDS",

  // Level - 3
  COMPLETE_PROFILE: "COMPLETE_PROFILE",
  HAVE_20_REFERRALS_FOR_SIGNUP: "HAVE_20_REFERRALS_FOR_SIGNUP",
  BECOME_TRUSTED_MEMBER: "BECOME_TRUSTED_MEMBER",

  // Other
  REWARD_WITHDRAW: "REWARD_WITHDRAW",
  BOOST_APPLICATION: "BOOST_APPLICATION",

  // Deprecated
  REFERRAL_FOR_TRUSTED_MEMBER: "REFERRAL_FOR_TRUSTED_MEMBER",

  // Leaderboard
  TOP_IN_LEADERBOARD: "TOP_IN_LEADERBOARD"
}

// TOKEN REWARD
const MAP_ACHIEVEMENTS_MESSAGE: { [key in FreelancerAchievemntTypes]: string } = {
  REFERRAL_FOR_SIGNUP: "Referred a friend",
  HIVE_A_BOARD: "Create a board",
  CONNECT_WALLET: "Wallet connected",

  // Level - 2
  HAVE_25_FOLLOWERS_AND_FOLLOWINGS: "Reached 25 Followers",
  HAVE_5_REFERRALS_FOR_SIGNUP: "Have made 5 referrals",
  HIVE_5_BOARDS: "Created 5 boards",

  // Level - 3
  COMPLETE_PROFILE: "Completed profile",
  HAVE_20_REFERRALS_FOR_SIGNUP: "Have made 20 referrals",
  BECOME_TRUSTED_MEMBER: "Became trusted",

  // Other
  REWARD_WITHDRAW: "Withdraw",
  BOOST_APPLICATION: "Used for boosting job application",

  // Deprecated
  REFERRAL_FOR_TRUSTED_MEMBER: "Referral became trusted",

  // Leaderboard
  TOP_IN_LEADERBOARD: "Top in leaderboard"
};

export const CURRENCY_SYMBOLS = {
  EUR: "€",
  USD: "$",
  GBP: "£",
  INR: "₹",
};

const ENUMS = {
  onboarding_status: ENUM_ONBOARDING_STATUS,
  term: ENUM_TERM,
  level: ENUM_LEVEL_OF_EXPERIENCE,
  yearsOfExperience: ENUM_YEARS_OF_EXPERIENCE,
  timezone: ENUM_TIMEZONE,
  location: ENUM_LOCATION,
  numberOfHires: ENUM_NUMBER_OF_HIRES,
  hiringStatus: ENUM_ACTIVELY_HIRING,
  industry: ENUM_COMPANY_INDUSTRY_TYPES,
  companyStage: ENUM_COMPANY_STAGE,
  companyNumberOfEmployees: ENUM_COMPANY_NUMBER_OF_EMPLOYEES,
  degree: ENUM_DEGREES,
  clientPositions: ENUM_CLIENT_POSITION,
  profession: ENUM_PROFESSION,
  interviewRating: ENUM_INTERVIEW_RATING,
  applicationStatus: ENUM_APPLICATION_STATUS,
  assessmentStatus: ENUM_ASSESSMENT_STATUS,
  trustedTalent: ENUM_IS_TRUSTED_TALENT,
  achievementsMessages: MAP_ACHIEVEMENTS_MESSAGE,
  achievements: MAP_ACHIEVEMENTS,
  hackerearth_assessment_type: ENUM_ASSESSMENT_TYPE
};

export default ENUMS;
