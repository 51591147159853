import { isString } from '@sentry/utils';

export function getQueryFromObject(obj) {
    const getValue = (value) => (isString(value) ? value : JSON.stringify(value))
    const queries = Object.entries(obj).map(([key, value]) => {
        const v = getValue(value)
        // Ignore undefineds
        if (v === undefined) return ""
        return `${key}=${v}`
    });
    return queries.join('&')
}