import DropdownV2 from "components/V2/Dropdown/DropdownV2";
import IconV2 from "components/V2/Icons/IconV2";
import { useState } from "react";
import { ITalentManagement } from "types/TalentManagement";
import { IJobManagement } from "types/JobManagement";

export type Category = "Talent" | "Jobs" | "Companies";
interface ITableProps {
  title: string;
  total: number;
  getName: (r) => string;
  rows: (ITalentManagement | IJobManagement)[];
  skip?: string[]; // List of column field name to skip
  category: Category;
}

interface IColumn {
  title: string,
  name: string
}

const base_talent_columns: IColumn[] = [
  { title: "Total talent", name: "total" },
  { title: "Trusted talent", name: "trusted" },
  { title: "US/CAN", name: "us_can" },
  { title: "LATAM", name: "latam" },
  { title: "APAC", name: "apac" },
  { title: "$15 - 50", name: "hourly_15_50" },
  { title: "$50 - 100", name: "hourly_50_100" },
  { title: "$100+", name: "hourly_100" },
  { title: "1-5 YOE", name: "yoe_2_5" },
  { title: "5+ YOE", name: "yoe_5" },
  { title: "40% Profile", name: "profile_40" },
]
const base_trusted_columns: IColumn[] = [
  base_talent_columns[1],
  ...base_talent_columns.slice(2, base_talent_columns.length).map(c => ({ ...c, name: "trusted_" + c.name }))
]

const base_job_columns: IColumn[] = [
  { title: "Total jobs", name: "total" },
  { title: "Entry Lv", name: "entry_level" },
  { title: "Mid Lv", name: "mid_level" },
  { title: "Senior Lv", name: "senior_level" },
  { title: "Director Lv", name: "director_level" },
]

const base_company_columns: IColumn[] = [
  { title: "Total companies", name: "total" },
  { title: "Total jobs", name: "total_jobs" },
  { title: "Entry Lv", name: "entry_level" },
  { title: "Mid Lv", name: "mid_level" },
  { title: "Senior Lv", name: "senior_level" },
  { title: "Director Lv", name: "director_level" },
  { title: "US/CAN", name: "us_can" },
  { title: "LATAM", name: "latam" },
  { title: "APAC", name: "apac" },
]


const SortButton = ({ active, onClick }) => {
  const [order, setOrder] = useState<boolean>(true);

  return (
    <div onClick={() => { onClick(order); setOrder(!order) }}
      className={`w-[20px] h-[20px] bg-inactive-gray text-white rounded-md absolute right-1 top-1
  cursor-pointer
  flex items-center justify-center
  ${active && order && "rotate-180"}
  ${active && "!bg-odf"} `}>
      <IconV2 iconClassName="stroke-[#D9D9D9]" iconType="ARROW-DOWN-2" />
    </div>)
}

const Table = ({ rows, title, getName, total, category, skip = [] }: ITableProps) => {

  const [sort, setSort] = useState<{ col: string, order: boolean }>();
  const [filter, setFilter] = useState<number>(0);

  const isTalent = category === "Talent";
  let base_columns: IColumn[];
  switch (category) {
    case "Talent":
      base_columns = base_talent_columns;
      break;
    case "Jobs":
      base_columns = base_job_columns;
      break;
    case "Companies":
      base_columns = base_company_columns;
      break;
    default:
      throw new Error("Invalid category")
  }
  let columns = [{ title, name: title.toLowerCase() }, ...(filter && isTalent ? base_trusted_columns : base_columns)];
  columns = columns.filter(c => !skip.includes(c.name.replace("trusted_", "")))

  // Sort if requested
  if (sort) rows.sort((r1, r2) => r1[sort.col] > r2[sort.col] ? -1 : 1);
  if (sort?.order) rows.reverse();

  return (
    <div className=" w-[1100px] lg:w-full">
      <div className="my-[11px] ml-[35px]">
        <span className="font-poppins text-[16px] font-bold">Total {title}s {total}</span>
        {/* Filters */}
        {category === "Talent" &&
          <div className="flex gap-[20px] my-[30px]">
            <span className="font-inter font-bold text-[14px]">Filter by:</span>
            <DropdownV2 className="w-fit" buttonClass="w-[120px] h-[21px] pr-[0px] !font-inter !font-bold !text-[14px] !text-inactive-gray border-none "
              selectedIndex={filter}
              onChange={setFilter}
              data={[{ value: "None" }, { value: "Trusted" }]}
            />
          </div>
        }
      </div>
      <table className="table-fixed break-words w-full border-collapse">
        <thead className="h-[50px] bg-[#D9D9D9] text-[14px] font-inter font-semibold ">
          <tr>
            {columns.map(c =>
              <th className="border-dark-gray border-[1px] p-[10px_20px_20px_10px] relative" key={c.name}>
                {c.title}
                <SortButton active={c.name === sort?.col} onClick={(order) => setSort({ col: c.name, order })} />
              </th>
            )
            }
          </tr>
        </thead>
        <tbody className="font-inter text-[14px] font-semibold">
          {rows.map(r =>
            <tr key={getName(r)} className="[&>td]:border-[1px]  [&>td]:border-dark-gray ">
              {columns.map(c => <td key={c.name}>{r[c.name]}</td>)}
            </tr>
          )}
        </tbody >
      </table >
    </div>
  );
}

export default Table;