import { Routes, Route } from 'react-router-dom';

import pathUtils from 'utils/pathUtils';
import Dashboard from 'views/application/dashboard';

const routes = [
    {
        path: pathUtils.DASHBOARD,
        element: <Dashboard />,
    },
];

const DashboardRoutes: React.FC = () => {
    return (
        <Routes>
            {routes.map((route, index) => (
                <Route key={index} {...route} />
            ))}
        </Routes>
    );
};

export default DashboardRoutes;
