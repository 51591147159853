import { IIdentificationType, IReputationPersonhood } from "types/Reputation"
import { IPageProps } from "."
import Anchor from "../Form/Anchor"
import Input from "../Form/Input"
import Label from "../Form/Label"

interface IPage extends IPageProps {
  repu: IReputationPersonhood
}

const PagePersonhood = ({ repu }: IPage) => {
  const { type, willo_answer_url_1, willo_answer_url_2, willo_answer_url_3, willo_url, legacy_url } = repu;
  return <div className="flex flex-col gap-5">
    <div>
      <Label>Personal Info</Label>
      <p className="mt-[10px]">Enter the personal information required for proof of identification.</p>
    </div>

    {type === "LEGACY" && <Anchor label="Legacy url" href={legacy_url} />}

    {type === "WILLO" &&
      <>
        <Anchor label="Overview" href={willo_url || ""} />
        <div className="flex gap-5 flex-wrap">
          <video key={"willo_answer_url_1"} className="w-[200px] h-[140px]" controls >
            <source src={willo_answer_url_1} type="video/mp4" />
          </video>
          <video key={"willo_answer_url_2"} className="w-[200px] h-[140px]" controls >
            <source src={willo_answer_url_2} type="video/mp4" />
          </video>
          <video key={"willo_answer_url_3"} className="w-[200px] h-[140px]" controls >
            <source src={willo_answer_url_3} type="video/mp4" />
          </video>
        </div>
      </>
    }

  </div>
}


const PersonhoodPages = [
  { Comp: PagePersonhood, title: "Willo" },
]

export default PersonhoodPages;