import { useEffect, useState } from "react";

import adminAPI from "network/admin";
import DropdownV2 from "components/V2/Dropdown/DropdownV2";
import IconV2 from "components/V2/Icons/IconV2";
import { toast } from "react-toastify";
import Loader from "components/V2/Loader/Loader";
import DashboardCharts from "./components/DashboardCharts";
import Leaderboard from "./components/Leaderboard";
import { DashboardStats, DashboardTime } from "./types";
import HeaderCard from "./components/HeaderCard";
import useContractsData from "hooks/useContractsData";

const DateData: { value: string, date: DashboardTime }[] = [
  { value: "Week to date", date: "Week" },
  { value: "Month to date", date: "Month" },
  { value: "Year to date", date: "Year" },
  { value: "Years to date", date: "Years" },
  { value: "3 months to date", date: "3 Months" },
  { value: "6 months to date", date: "6 Months" },
  { value: "12 months to date", date: "12 Months" },

];

const Dashboard = () => {
  const { contractsData } = useContractsData();
  const [stats, setStats] = useState<DashboardStats>();
  const [selectedDate, setSelectedDate] = useState<number>(0);
  const fetchData = async () => {
    try {
      const response = await adminAPI.getDashboardStats();
      if (!response.data.success) throw new Error(JSON.stringify(response));
      setStats(response.data.stats);
    } catch (e) {
      toast.error("Error while fething data");
      console.error(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  if (!stats) return <Loader />;

  const selectedTime = DateData[selectedDate].date as DashboardTime;
  console.log(selectedTime);

  const talentCount = stats.talent.count;

  return (
    <div className="relative w-full max-md:px-2 md:pl-[37px] md:pr-[50px] h-screen overflow-x-hidden md:overflow-auto">
      {/* Background picture */}
      <div className="absolute  w-full h-[194px] bg-odf top-0 left-0 " />

      {/* Header */}
      <div className="relative flex max-md:items-center max-md:flex-col gap-y-2 justify-between pt-[40px] pb-[44px]">
        <span className="font-poppins font-bold text-white text-[24px]">Dashboard</span>
        <DropdownV2
          className="w-[196px] h-[40px]"
          buttonClass="h-[40px]"
          icon={<IconV2 iconType="CALENDAR" />}
          data={DateData}
          selectedIndex={selectedDate}
          onChange={(i) => setSelectedDate(i)}
        />
      </div>

      {/* Header cards */}
      <div
        className="relative grid grid-cols-1 lg:grid-cols-5 gap-[30px] mb-[24px]
      [&>div]:col-span-full [&>div]:flex [&>div]:max-md:flex-col [&>div]:gap-[30px]"
      >
        <div>
          <HeaderCard
            title="Onboarded Talent"
            iconType="USER-ORANGE2"
            iconWrapperClassName="bg-orange-hue"
            stats={stats.talent}
            time={selectedTime}
          />
          <HeaderCard
            title="Linkedin External Users"
            iconType="LINKEDIN"
            iconWrapperClassName="bg-blue2-hue"
            stats={stats.externalLinkedinUsers}
            time={selectedTime}
          />
          <HeaderCard
            title="Talent completed 70% profile"
            iconType="USER"
            iconWrapperClassName="bg-blue2-hue"
            stats={stats.completedProfiles}
            time={selectedTime}
            total={talentCount}
          />
          <HeaderCard
            title="Total new referrals"
            iconType="RED-EMAIL"
            iconWrapperClassName="bg-coral-red-hue"
            stats={stats.referrals}
            time={selectedTime}
            total={talentCount}
          />
          <HeaderCard
            title="ABC Talent"
            iconType="USER"
            iconWrapperClassName="bg-blue2-hue"
            stats={stats.internalRating}
            time={selectedTime}
            total={talentCount}
          />
        </div>

        <div>
          <HeaderCard
            title="Signed up companies"
            iconType="COMPANY"
            iconWrapperClassName="bg-odf-light"
            stats={stats.company}
            time={selectedTime}
          />
          <HeaderCard
            title="Total Jobs"
            iconType="BRIEFCASE-RED"
            iconWrapperClassName="bg-coral-red-hue"
            stats={stats.open_role}
            time={selectedTime}
          />
          <HeaderCard
            title="External Jobs"
            iconType="BRIEFCASE-RED"
            iconWrapperClassName="bg-coral-red-hue"
            stats={stats.externalJobs}
            time={selectedTime}
          />
          <HeaderCard
            title="Companies with jobs"
            iconType="BRIEFCASE-RED"
            iconWrapperClassName="bg-odf-light"
            stats={stats.companiesWithJobs}
            time={selectedTime}
            total={stats.company.count}
          />
          <HeaderCard
            title="Premium companies"
            iconType="BRIEFCASE-RED"
            iconWrapperClassName="bg-odf-light"
            stats={stats.payingCompanies}
            time={selectedTime}
            total={stats.company.count}
          />
        </div>

        <div>
          <HeaderCard
            title="Talent Applied to Jobs"
            iconType="ORANGE-DOCUMENT"
            iconWrapperClassName="bg-orange-hue"
            stats={stats.appliedJobTalents}
            time={selectedTime}
            total={talentCount}
          />
          <HeaderCard
            title="Total Applications to Jobs"
            iconType="BLUE-DOCUMENT-FAVORITE"
            iconWrapperClassName="bg-blue2-hue"
            stats={stats.jobApplications}
            time={selectedTime}
          />
          <HeaderCard
            title="Average Applicants Per Job"
            iconType="BLUE-DOCUMENT-FAVORITE"
            iconWrapperClassName="bg-blue2-hue"
            stats={{ count: Math.round(stats.jobApplications.avgAppliedTalentsPerJob) }}
            time={selectedTime}
          />
          <HeaderCard
            title="Jobs with Applicants"
            iconType="BLUE-DOCUMENT-FAVORITE"
            iconWrapperClassName="bg-blue2-hue"
            stats={stats.appliedJobs}
            time={selectedTime}
            total={stats.open_role.count}
          />
          <HeaderCard
            title="Jobs with Talent Invited"
            iconType="BLUE-DOCUMENT-FAVORITE"
            iconWrapperClassName="bg-blue2-hue"
            stats={stats.jobsWithInvite}
            time={selectedTime}
            total={stats.open_role.count}
          />
        </div>

        <div>
          <HeaderCard
            title="Any assessment completed"
            iconType="MSA"
            iconWrapperClassName="bg-odf-light"
            stats={stats.assessmentsStarted}
            time={selectedTime}
            total={talentCount}
          />
          <HeaderCard
            title="Introduction completed"
            iconType="MSA"
            iconWrapperClassName="bg-odf-light"
            stats={stats.assessmentsIntroduction}
            time={selectedTime}
            total={talentCount}
          />
          <HeaderCard
            title="Interview completed"
            iconType="MSA"
            iconWrapperClassName="bg-odf-light"
            stats={stats.assessmentsInterview}
            time={selectedTime}
            total={talentCount}
          />
          <HeaderCard
            title="MCQ completed"
            iconType="MSA"
            iconWrapperClassName="bg-odf-light"
            stats={stats.assessmentsMcq}
            time={selectedTime}
            total={talentCount}
          />
          <HeaderCard
            title="Coding completed"
            iconType="MSA"
            iconWrapperClassName="bg-odf-light"
            stats={stats.assessmentsCoding}
            time={selectedTime}
            total={talentCount}
          />
        </div>

        <div>
          <HeaderCard
            title="Total tokens rewarded"
            iconType="OUTDEF-LOGO"
            iconWrapperClassName="bg-odf-light"
            stats={stats.tokens}
            time={selectedTime}
          />
          <HeaderCard
            title="Total tokens claimed"
            iconType="OUTDEF-LOGO-BLUE2"
            iconWrapperClassName="bg-odf-light"
            stats={stats.claimedTokens}
            time={selectedTime}
            total={stats.tokens.count}
          />
          {contractsData && <>
            <HeaderCard
              title="Rewards Contract Withdrawals"
              iconType="OUTDEF-LOGO-BLUE2"
              iconWrapperClassName="bg-odf-light"
              stats={contractsData.totalWithdrawals}
              time={selectedTime}
            />
            <HeaderCard
              title="Minting Contract"
              iconType="OUTDEF-LOGO-BLUE2"
              iconWrapperClassName="bg-odf-light"
              stats={contractsData.totalSupply}
              time={selectedTime}
              total={contractsData?.maxSupply.count}
            />
            <HeaderCard
              title="Rewards Contract Balance"
              iconType="OUTDEF-LOGO-BLUE2"
              iconWrapperClassName="bg-odf-light"
              stats={contractsData.rewardsLeft}
              time={selectedTime}
            />
          </>}
        </div>
        <div>
          <HeaderCard
            title="Total boards created"
            iconType="ORANGE-DOCUMENT"
            iconWrapperClassName="bg-orange-hue"
            stats={stats.questions}
            time={selectedTime}
          />
          <HeaderCard
            title="Non Outdefine Boards"
            iconType="BLUE-DOCUMENT-FAVORITE"
            iconWrapperClassName="bg-blue2-hue"
            stats={stats.nonOutdefineQuestions}
            time={selectedTime}
            total={stats.questions.count}
          />
          <HeaderCard
            title="Boards with Engagement "
            iconType="BLUE-DOCUMENT-FAVORITE"
            iconWrapperClassName="bg-blue2-hue"
            stats={stats.questionsWithEngage}
            time={selectedTime}
            total={stats.questions.count}
          />
          <HeaderCard
            title="Boards with images"
            iconType="BLUE-DOCUMENT-FAVORITE"
            iconWrapperClassName="bg-blue2-hue"
            stats={stats.questionsWithImages}
            time={selectedTime}
            total={stats.questions.count}
          />
          <HeaderCard
            title="Slept Talent"
            iconType="USER"
            iconWrapperClassName="bg-blue2-hue"
            stats={stats.sleptTalent}
            time={selectedTime}
          />
        </div>
      </div>

      <div className="flex max-md:flex-col w-full gap-x-[20px] pb-[31px] gap-y-10">
        {/* Charts section */}
        <div className="flex flex-col  md:w-[50%] gap-[16px]">
          <DashboardCharts
            data={[
              {
                name: "Total",
                stats: stats.talent,
                color: "#FF8134",
              },
              {
                name: "Slept",
                stats: stats.sleptTalent,
                color: "#B22222",
              },
              {
                name: "40% profile",
                stats: stats.completed40Profiles,
                color: "#7FC8C8",
              },
              {
                name: "70% profile",
                stats: stats.completedProfiles,
                color: "#FFC8C8",
              },
              {
                name: "I. rate",
                stats: stats.internalRating,
                color: "#FFC8C8",
              },
            ]}
            time={selectedTime}
            title="Talents"
          />

          <DashboardCharts
            data={[
              {
                name: "Total",
                stats: stats.company,
                color: "#FF8134",
              },
              {
                name: "Premium",
                stats: stats.payingCompanies,
                color: "#FFC8C8",
              },
              {
                name: "With Jobs",
                stats: stats.companiesWithJobs,
                color: "#8FC8C8",
              },
            ]}
            time={selectedTime}
            title="Company"
          />

          <DashboardCharts
            data={[
              {
                name: "Total",
                stats: stats.open_role,
                color: "#FF8134",
              },
              {
                name: "With Applicants",
                stats: stats.appliedJobs,
                color: "#9F5FFF",
              },
              {
                name: "With Invite",
                stats: stats.jobsWithInvite,
                color: "#B22222",
              },
              {
                name: "External",
                stats: stats.externalJobs,
                color: "#8FC8C8",
              },
            ]}
            time={selectedTime}
            title="Jobs"
          />

          <DashboardCharts
            data={[
              {
                name: "Total",
                stats: stats.questions,
                color: "#FF8134",
              },
              {
                name: "Non-Outdefine",
                stats: stats.nonOutdefineQuestions,
                color: "#9F5FFF",
              },
              {
                name: "With images",
                stats: stats.questionsWithImages,
                color: "#B22222",
              },
              {
                name: "Engage",
                stats: stats.questionsWithEngage,
                color: "#7FC8C8",
              },
            ]}
            time={selectedTime}
            title="Community boards"
          />

          <DashboardCharts
            data={[
              {
                name: "Total Rewarded",
                stats: stats.tokens,
                color: "#FF8134",
              },
              {
                name: "Total Claimed",
                stats: stats.claimedTokens,
                color: "#5F5FFF",
              },
            ]}
            time={selectedTime}
            title="Token rewards"
          />

          <DashboardCharts
            data={[
              {
                name: "Total referrals signed up",
                stats: stats.referrals,
                color: "#FF8134",
              },
            ]}
            time={selectedTime}
            title="Referrals"
          />

          <DashboardCharts
            data={[
              {
                name: "One assessment",
                stats: stats.assessmentsStarted,
                color: "#5F5FFF",
              },
              {
                name: "Introduction",
                stats: stats.assessmentsIntroduction,
                color: "#800080",
              },
              {
                name: "Interview",
                stats: stats.assessmentsInterview,
                color: "#B22222",
              },
              {
                name: "MCQ",
                stats: stats.assessmentsMcq,
                color: "#7FC8C8",
              },
              {
                name: "Coding",
                stats: stats.assessmentsCoding,
                color: "#FFC8C8",
              },
            ]}
            time={selectedTime}
            title="Completed assessments"
          />
        </div>

        {/* Leaderboard */}
        <div className="md:w-[50%]">
          <Leaderboard stats={stats} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
