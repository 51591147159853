import { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { isEmpty } from "@aws-amplify/core";
import { toast } from "react-toastify";

import { averageRating } from "utils/talent/utils";
import profileAPI from "network/profile";
import onboardingAPI from "network/onboarding";
import ModalV2 from "components/Modal/ModalV2";
import { FormControl } from "components/V2/Form/FormControl";
import IconV2 from "components/V2/Icons/IconV2";
import { FreelancerProfile } from "types";
import Button from "components/Button/ButtonV2";
import { FormSkillInputV2 } from "components/V2/SkillInput/SkillInput";
import { useAppSelector } from "redux/hooks/redux-hooks";
import { FormMultiSelectDropdownV2 } from "components/V2/MultiSelectDropdown/MultiSelectDropdownV2";
import ENUMS from "constants/enums";
import Label from "components/V2/Form/Label";
import { FormCountryInputV2 } from "components/V2/LocationAutocomplete/CountryInputV2";
import { FormCityInputV2 } from "components/V2/LocationAutocomplete/CityInputV2";
import { FormDropdownV2 } from "components/V2/Dropdown/FormDropdownV2";
import InputV2 from "components/V2/Input/InputV2";
import { FormUploadResume } from "./UploadResume/FormUploadResume";
import { deleteFalsyFromObject } from "utils/utils";

interface IEditTalentModal {
    isOpen: boolean,
    onClose: () => void,
    onSuccess: () => void,
    talent: FreelancerProfile,
}

function getDefaultData(talent: FreelancerProfile) {
    return {
        primarySkills: talent.skills.filter((s) => s.freelancer_skill.is_primary).map((s) => s.id),
        secondarySkills: talent.skills.filter((s) => !s.freelancer_skill.is_primary).map((s) => s.id),
        locations: talent.roles_open_to ? JSON.parse(talent.roles_open_to) : [],
        terms: talent.terms_open_to ? JSON.parse(talent.terms_open_to) : [],
        country: talent.country,
        city: talent.city,
        experienceYears: experienceYearsData.find(e => e.value === talent.years_of_experience)?.id,
        experienceLevel: experienceLevelData.find(e => e.value === talent.level_of_experience)?.id,
        hourlyRate: talent.hourly_rate || 0,
    }
}

const locationsData = ENUMS.location.map((value, index) => ({ value, index }));
const termsData = ENUMS.term.map((value, index) => ({ value, index }));
const experienceYearsData = ENUMS.yearsOfExperience.map((value, id) => ({ value, id }));
const experienceLevelData = ENUMS.level.map((value, id) => ({ value, id }));

const EditTalentModal = ({ isOpen, talent, onClose, onSuccess, }: IEditTalentModal) => {

    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const prototype = useAppSelector((root) => root.prototype);
    const {
        handleSubmit,
        control,
        formState: { errors },
        reset
    } = useForm({
        defaultValues: getDefaultData(talent),
    });

    // Reset the form data when the modal is closed or talent changes
    useEffect(() => {
        if (!isOpen && talent) reset(getDefaultData(talent));
    }, [isOpen, talent])

    // Calculate the available skills based on the selected ones
    const defaultSkills = prototype.skills;
    const selectedPrimarySkills = useWatch({ control, name: "primarySkills" });
    const selectedSecondarySkills = useWatch({ control, name: "secondarySkills" });
    const selectableSkills = defaultSkills
        .filter((skill) => !selectedSecondarySkills.find((s) => s === skill.id))
        .filter((skill) => !selectedPrimarySkills.find((s) => s === skill.id));

    // Handle update
    const onSubmit = async (data) => {
        console.log("form data:", data);

        const { primarySkills: primary_skills, secondarySkills: secondary_skills, resume,
            locations: roles_open_to, terms: terms_open_to, country, city, hourlyRate: hourly_rate,
            experienceYears: years_of_experience, experienceLevel: level_of_experience, } = data;
        const freelancer_id = talent.freelancer_id;

        setIsSubmitting(true);
        try {
            let response;

            // Update the freelancer_profile data
            const profile: any = {
                freelancer_id,
                country,
                city,
                hourly_rate,
                years_of_experience: experienceYearsData[years_of_experience]?.value,
                level_of_experience: experienceLevelData[level_of_experience]?.value,
                roles_open_to: JSON.stringify(roles_open_to),
                terms_open_to: JSON.stringify(terms_open_to),
            }
            deleteFalsyFromObject(profile);
            response = await profileAPI.updatePreference(profile);
            if (!response.success) throw new Error("Error while updating freelancer_profile model: ", response.message);

            // Update the freelancer_skills model
            const p_skills = primary_skills.map(skillId => (
                {
                    skillId,
                    is_primary: true
                }
            ));
            const s_skills = secondary_skills.map(skillId => (
                {
                    skillId,
                    is_primary: false
                }
            ));
            response = await profileAPI.updateProfileSkills(freelancer_id, [...p_skills, ...s_skills]);
            if (!response.success) throw new Error("Error while updating freelancer_skills model: ", response.message);

            // Update resume if there is is one 
            if (resume) {
                response = await onboardingAPI.uploadResume(resume, talent.freelancer_id);
                if (!response.success) throw Error("Error on resume uploading: " + response.message);
            }

            await onSuccess();
            toast.success("User data was updated!");
            onClose();
        } catch (e) {
            console.error(e);
            toast.error("Error while updating talent data");
        }

        setIsSubmitting(false);
    };

    return (
        <ModalV2 isOpen={isOpen}>
            <form onSubmit={handleSubmit((onSubmit))} className="relative w-[717px] pt-[67px] pb-[32px] rounded-md px-[57px] mx-auto mt-[131px] bg-white">
                <div onClick={onClose} className="absolute right-[20px] top-[20px] hover:cursor-pointer ">
                    <IconV2 iconType="CLOSE" />
                </div>

                <div className="flex flex-col">
                    <span className="font-poppins font-semibold text-[20px] mx-auto">Edit talent info</span>
                    <span className="font-poppins text-[14px] mt-[12px] mx-auto" >Information changed here will effect the talent’s Outdefine profile</span>
                </div>

                <div>
                    <div className="flex w-full gap-x-[33px] mt-[69px]">
                        <FormControl className="w-full">
                            <FormSkillInputV2
                                name="primarySkills"
                                control={control}
                                skillData={[...selectableSkills]}
                                labelText="Primary skills (multi-select up to 5)"
                                isPrimary
                            />
                        </FormControl>
                        <FormControl className="w-full">
                            <FormSkillInputV2
                                name="secondarySkills"
                                control={control}
                                skillData={[...selectableSkills]}
                                labelText="Secondary skills (multi-select up to 5)"
                                isPrimary={false}
                            />
                        </FormControl>
                    </div>

                    <div className="flex w-full gap-x-[33px] mt-[29px]">

                        <FormControl className="w-full">
                            <Label>Location open to (multi-select)</Label>
                            <FormMultiSelectDropdownV2
                                name="locations"
                                icon={<IconV2 iconType="LOCATION" />}
                                control={control}
                                withBadge
                                buttonClassName="w-full h-12 font-inter text-xs"
                                buttonText="What locations are you open to?"
                                listData={locationsData}
                            />
                        </FormControl>

                        <FormControl className="w-full">
                            <Label>Term open to (multi-select)</Label>
                            <FormMultiSelectDropdownV2
                                name="terms"
                                icon={<IconV2 iconType="CLOCK" />}
                                control={control}
                                withBadge
                                buttonClassName="w-full h-12 font-inter text-xs"
                                buttonText="What terms are you open to?"
                                listData={termsData}
                            />
                        </FormControl>
                    </div>

                    <div className="flex w-full gap-x-[33px] mt-[29px]">
                        <FormControl>
                            <Label>Country</Label>
                            <FormCountryInputV2
                                name="country"
                                control={control}
                                placeholder="Type a country"
                                className="text-xs font-inter"
                                withBadge
                            />
                        </FormControl>
                        <FormControl>
                            <Label className="mt-10 md:mt-0" htmlFor="city">
                                City
                            </Label>
                            <FormCityInputV2
                                name="city"
                                control={control}
                                placeholder="Type a city"
                                inputClass="text-xs font-inter"
                                withBadge
                            />
                        </FormControl>
                    </div>

                    <div className="flex w-full gap-x-[33px] mt-[29px]">

                        <FormControl className="w-full">
                            <Label>Years of experience</Label>
                            <FormDropdownV2
                                name='experienceYears'
                                control={control}
                                icon={<IconV2 iconType="EDUCATION" />}
                                data={experienceYearsData}
                                placeholder='Select experience years'
                                withBadge
                                normalCase
                            />
                        </FormControl>

                        <FormControl className="w-full">
                            <Label >Hourly rate</Label>
                            <Controller
                                name="hourlyRate"
                                control={control}
                                rules={{ validate: (rate) => (!rate || (rate > 0 && rate <= 1000)) }}
                                render={({ field: { value, onChange } }) => (
                                    <div className="relative">
                                        <InputV2
                                            icon={<IconV2 iconType="DOLLAR-BLUE-CIRCLE" iconClassName="w-5 h-5" />}
                                            type="number"
                                            onChange={onChange}
                                            value={value}
                                            autoComplete="off"
                                            placeholder="Add your hourly rate"
                                        />
                                        <span className="absolute right-4 bottom-[12px] font-normal text-sm">/hr</span>
                                    </div>
                                )}
                            />
                            {errors?.hourlyRate && (
                                <span className="font-inter text-xs text-red">
                                    Hourly rate is invalid
                                </span>
                            )}
                        </FormControl>
                    </div>

                    <div className="flex w-full gap-x-[33px] mt-[44px]">
                        <FormControl className="w-full">
                            <Label>Experience level</Label>
                            <FormDropdownV2
                                name='experienceLevel'
                                control={control}
                                icon={<IconV2 iconType="CLOCK" />}
                                data={experienceLevelData}
                                placeholder='Select experience level'
                                withBadge
                                normalCase
                            />
                        </FormControl>

                        <FormControl className="w-full">
                            <Label>Resume</Label>
                            <FormUploadResume
                                id="resume"
                                accept=".pdf, .doc, .docx"
                                name='resume'
                                control={control}
                                buttonText="Replace resume"
                            />
                        </FormControl>
                    </div>

                    <div className="flex w-full gap-x-[33px] mt-[44px]">
                        <FormControl>
                            <Label>Overall interview rating</Label>
                            <InputV2
                                disabled
                                icon={<IconV2 iconType="RATINGSTAR" />}
                                name='rating'
                                className='w-full h-12 text-[14px] font-inter font-semibold '
                                placeholder='First name'
                                value={`${averageRating(talent.User.AssessmentInfo?.rating).toFixed(1)} star avg`}
                            />
                        </FormControl>
                    </div>
                </div>


                <div className="w-full flex justify-center mt-[46px]">
                    <Button type="submit" loading={isSubmitting} disabled={!isEmpty(errors)}>
                        Save changes
                    </Button>
                </div>

            </form>

        </ModalV2>

    );
}

export default EditTalentModal;