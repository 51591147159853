const MENU_TALENT = "Talent";
const MENU_COMPANIES = "Companies";
const MENU_DASHBOARD = "Dashboard";
const MENU_TABLE_MANAGEMENT = "Database";
const MENU_TALENT_MANAGEMENT = "TalentManagement";
const MENU_BOARD_MANAGEMENT = "BoardManagement";
const MENU_INVOICES = "Invoices";
const MENU_LINKEDIN_USERS = "LinkedinUsers";
const MENU_REPUTATION = "Reputation";

const mainlinks = [
  {
    link: "/",
    text: MENU_DASHBOARD,
  },
  {
    link: "/talent/overview",
    text: MENU_TALENT,
  },
  {
    link: "/linkedin_users/overview",
    text: MENU_LINKEDIN_USERS,
  },
  {
    link: "/company/overview",
    text: MENU_COMPANIES,
  },
  {
    link: "/database",
    text: MENU_TABLE_MANAGEMENT,
  },
  {
    link: "/management",
    text: MENU_TALENT_MANAGEMENT,
  },
  {
    link: "/board",
    text: MENU_BOARD_MANAGEMENT,
  },
  {
    link: "/invoice/overview",
    text: MENU_INVOICES,
  },
  {
    link: "/reputation",
    text: MENU_REPUTATION
  }
];

export const menu_data = {
  admin: {
    mainlinks: mainlinks,
  },
};
