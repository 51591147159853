import DropdownV2 from "components/V2/Dropdown/DropdownV2";
import IconV2 from "components/V2/Icons/IconV2";
import { useState } from "react";
import { Company } from "types/Company";
import { JobPosting } from "types/JobPosting";
import JobPost from "./JobPost";

interface IJobsPosts {
    company: Company;
}

const JobPosts = ({ company }: IJobsPosts) => {

    const [open, setOpen] = useState<number | undefined>();
    const jobs = company.JobsPosting || [];
    const jobsData = jobs.map(job => ({ value: job.job_title, job }))

    return (
        <div>
            <DropdownV2
                className="w-[940px] h-[48px]"
                buttonClass="h-[48px] !rounded-none"
                icon={<IconV2 iconType="COMPANY" />}
                data={jobsData}
                placeholder="Select a job"
                selectedIndex={open}
                onChange={(i) => setOpen(i)}
            />

            {
                open !== undefined && <JobPost company={company} job={jobsData[open].job} />
            }

        </div>
    );
}

export default JobPosts;