import { API } from "aws-amplify";

import talentApi from "network/profile";
import { FreelancerProfile } from "types";
import awsUtils from "utils/awsUtils";

type Permissions = 'cannot_create_question' | 'cannot_reply_question' |  'cannot_reply_answer'
type IPermissions = { [key in Permissions]?: boolean } ;

interface IOptions {
    hide_questions?: boolean,
}

async function ban(freelancer_id: number, permissions: IPermissions = {}, options: IOptions = {}) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/freelancer/denied_permissions/ban`;
  const myInit = {
    headers: { ...authHeader },
    response: true,
    body: JSON.stringify({
        freelancer_id,
        permissions, 
        options,
    })
  };
  const result = await API.put(apiName, path, myInit);
  return result;
}

/**
 * This api will stop the giving user from creating questions and replies on community and will hide all 
 * the given user questions
 * @param freelancer_id 
 * @returns 
 */
async function communityBan(freelancer_id: number, unban = false) {
  const isBan = !unban;
  const result = await ban(
    freelancer_id,
    {   cannot_create_question: isBan,
        cannot_reply_answer: isBan,
        cannot_reply_question: isBan
    },
    isBan ? {hide_questions: true} : {}
    );
  return result;
}

async function getTalentWithDeniedPermissions(permissions: [Permissions]): Promise<FreelancerProfile[] | undefined>{
    const result = await talentApi.getTalentPaginated({
    filters: {
        denied_permissions: permissions
    }});
    if(!result.success) console.error(result);
    return result.talents;
}

async function getAllCommnutyBannedTalent() {
    return await getTalentWithDeniedPermissions(["cannot_create_question"]);
}

export default {
    communityBan,
    getAllCommnutyBannedTalent
}