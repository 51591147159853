import ArrowButton from "components/ArrowButton";
import IconV2 from "components/V2/Icons/IconV2";
import { useNavigate } from "react-router-dom";
import MedalRank1 from "assets/dashboard/medal-rank1.png";
import MedalRank2 from "assets/dashboard/medal-rank2.png";
import MedalRank3 from "assets/dashboard/medal-rank3.png";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";
import { DashboardStats } from "../types";

function getTalentMedal(rank: number): JSX.Element {
    switch (rank) {
        case 1:
            return <img className="w-[50px]" src={MedalRank1} />
        case 2:
            return <img className="w-[50px]" src={MedalRank2} />
        case 3:
            return <img className="w-[50px]" src={MedalRank3} />
        default:
            return <span className="font-poppins font-semibold text-[16px] ml-[10px]">{rank}.</span>
    }
}

interface ILeaderboard {
    stats: DashboardStats;
}

const Leaderboard = ({ stats }: ILeaderboard) => {

    const navigate = useNavigate();
    const goToDetailedtTalent = async (email) => navigate(`/talent/details/${email}`);

    return (
        <div className="h-full w-full px-[20px] pb-[28px] pt-[20px] bg-white rounded">
            <span className="font-poppins font-semibold text-[20px]">
                Leader board
            </span>

            <div className="h-full overflow-y-auto">
                <table className=" w-full mt-[23px]">
                    <thead className="font-inter font-semibold text-[12px] text-inactive-gray ">
                        <tr className="[&>th]:bg-[#F0F1F2] [&>th]:text-start">
                            <th className="rounded-l-md pl-[6px]">Place</th>
                            <th className="pl-[20px]">User</th>
                            <th className="w-[100px]"><div className="w-full text-center">Onboarded</div></th>
                            <th className="rounded-r-md w-[150px] pl-[50px]">Total earnings </th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* This row simulates a margin */}

                        <tr className="h-[38px]"></tr>
                        {
                            stats.ranking.map(u => (
                                <tr key={u.freelancer_id}>
                                    <td>
                                        <div className="flex justify-start">
                                            {getTalentMedal(u.ranking)}
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex gap-x-[6px]">
                                            <UserAvatarLogo user={u.FreelancerProfile.User} />
                                            <div className="flex flex-col justify-start gap-y-[1px] text-start">
                                                <span className="font-poppins font-semibold text-[16px] w-fit">
                                                    {`${u.FreelancerProfile.User.first_name} ${u.FreelancerProfile.User.last_name}`}
                                                </span>
                                                <span className="font-inter font-semibold text-[14px] text-dark-gray w-fit">
                                                    {u.FreelancerProfile.Role?.name || 'N/A'}
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="flex gap-x-[10px] justify-center">
                                            <IconV2 iconType="USER" iconClassName="w-[20px]" />
                                            <span className="font-inter font-semibold text-[16px]">
                                                {u.onboarded_referrals}
                                            </span>
                                        </div>
                                    </td>
                                    <td className="pl-[50px]">
                                        <div className="flex justify-between items-center">
                                            <div className="flex gap-x-[15px] items-center">
                                                <IconV2 iconType="DOLLAR" iconClassName="w-[16px]" />
                                                <span className="font-inter font-semibold text-[16px] text-blue2" >
                                                    {u.rewards}
                                                </span>
                                            </div>
                                            <ArrowButton
                                                direction="NEXT"
                                                className="w-[12px] h-[12px]"
                                                onClick={() => goToDetailedtTalent(u.FreelancerProfile.User.email_id)}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            )
                            )
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Leaderboard;