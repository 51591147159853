import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { isEmpty } from "@aws-amplify/core";
import { toast } from "react-toastify";

import onboardingAPI from "network/onboarding";
import profileAPI from "network/profile";
import ModalV2 from "components/Modal/ModalV2";
import utils, { deleteFalsyFromObject } from "utils/utils";
import { FormDialCodeInput } from "components/V2/DialCodeInput/FormDialCodeInput";
import { FormDropdownV2 } from "components/V2/Dropdown/FormDropdownV2";
import { FormControl } from "components/V2/Form/FormControl";
import Label from "components/V2/Form/Label";
import IconV2 from "components/V2/Icons/IconV2";
import { FormInputV2 } from "components/V2/Input/FormInputV2";
import { FormPhoneInput } from "components/V2/PhoneInput/FormPhoneInput";
import usePrototypeUtils from "hooks/usePrototypeUtils";
import { FreelancerProfile } from "types";
import { dialCodes } from "components/V2/DialCodeInput/DialCodeInput";
import Button from "components/Button/ButtonV2";

interface IEditTalentContactModal {
    isOpen: boolean,
    onClose: () => void,
    onSuccess: () => void,
    talent: FreelancerProfile,
}

const EditTalentContactModal = ({ isOpen, talent, onClose, onSuccess, }: IEditTalentContactModal) => {

    const { allRoles } = usePrototypeUtils();
    function getDefaultData(talent: FreelancerProfile) {
        return {
            firstName: talent.User.first_name,
            lastName: talent.User.last_name,
            role: allRoles.length ? allRoles.findIndex(r => r.id === talent.Role?.role_id) : undefined,
            phoneNumber: talent.User.phone_number || undefined,
            dialCode: dialCodes.find(code => code.code === talent.User.dial_code),
            linkedin: talent.FreelancerProfileSocialLink?.linkedin_link || "",
            github: talent.FreelancerProfileSocialLink?.github_link || "",
            website: talent.FreelancerProfileSocialLink?.website_link || "",
        }
    }
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
    const {
        setValue,
        handleSubmit,
        control,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: getDefaultData(talent),
    });

    // Set default role once we have fetched all the roles
    useEffect(() => {
        const index = allRoles.findIndex(r => r.id === talent.Role?.role_id);
        setValue("role", index);
    }, [allRoles]);

    // Reset the form data when the modal is closed
    useEffect(() => {
        if (!isOpen && talent) reset(getDefaultData(talent));
    }, [isOpen, talent])

    const onSubmit = async (data) => {
        setIsSubmitting(true);

        const { firstName: first_name, lastName: last_name, role, phoneNumber: phone_number,
            dialCode: dial_code, linkedin: linkedin_link, github: github_link, website: website_link } = data;

        const { freelancer_id } = talent;
        const { user_id } = talent.User;

        try {
            let response;

            // Update the user model data
            const update_user = {
                first_name,
                last_name,
                dial_code: dial_code?.code,
                phone_number,
                user_id,
            };
            deleteFalsyFromObject(update_user);
            response = await onboardingAPI.updateUser(update_user);
            if (!response.success) throw new Error("Error: error updating user model: " + response.message);

            // Update freelancer_profile model data
            const profile: any = { freelancer_id, role: allRoles[role]?.id }
            deleteFalsyFromObject(profile);
            response = await profileAPI.updatePreference(profile);
            if (!response.success) throw new Error("Error: error updating freelancer_profile model: " + response.message);

            // Update socials model data
            const socials = {
                freelancer_id,
                linkedin_link,
                github_link,
                website_link
            };
            deleteFalsyFromObject(socials);
            response = await profileAPI.updateProfileSocials(socials);
            if (!response.success) throw new Error("Error: error updating social model: " + response.message);

            await onSuccess();
            toast.success("User data was updated!");
            onClose();
        } catch (e) {
            console.error(e);
            toast.error("Error while updating talent data");
        }

        setIsSubmitting(false);
    };

    return (
        <ModalV2 isOpen={isOpen}>
            <form onSubmit={handleSubmit((onSubmit))} className="relative w-[717px] pt-[67px] pb-[32px] rounded-md px-[57px] mx-auto mt-[131px] bg-white">
                <div onClick={onClose} className="absolute right-[20px] top-[20px] hover:cursor-pointer ">
                    <IconV2 iconType="CLOSE" />
                </div>

                <div className="flex flex-col">
                    <span className="font-poppins font-semibold text-[20px] mx-auto">Edit talent contact info</span>
                    <span className="font-poppins text-[14px] mt-[12px] mx-auto" >Information changed here will effect the talent’s Outdefine profile</span>
                </div>

                <div>
                    <div className="flex w-full gap-x-[32px] mt-[34px]">
                        <FormControl>
                            <Label>Talent Name</Label>
                            <FormInputV2
                                name='firstName'
                                control={control}
                                className='w-full h-12 text-xs font-inter'
                                placeholder='First name'
                            />
                        </FormControl>

                        <FormControl>
                            <Label>&nbsp;</Label>
                            <FormInputV2
                                name='lastName'
                                control={control}
                                className='w-full h-12 text-xs font-inter'
                                placeholder='Last name'
                            />
                        </FormControl>
                    </div>
                    <FormControl className="mt-[21px] !w-full">
                        <Label>Talent Role</Label>
                        <FormDropdownV2
                            name='role'
                            className=""
                            control={control}
                            icon={<IconV2 iconType="SEARCH" iconClassName="w-[40px] h-[40px] pr-[20px]" />}
                            data={allRoles}
                            placeholder='Select a role'
                            withBadge
                            normalCase
                            badgeClass="text-xs font-inter px-3 py-2 w-fit mt-2 bg-coral-red-hue text-coral-red"
                        />
                    </FormControl>

                    <FormControl className="mt-[21px] !w-full" >
                        <Label>Email</Label>
                        <FormInputV2
                            name='email'
                            disabled
                            icon={<IconV2 iconType="EMAIL" />}
                            control={control}
                            className='w-full h-12 text-xs font-inter'
                            placeholder={talent.User.email_id}
                        />
                    </FormControl>

                    <div className='flex w-full gap-x-[32px] mt-[21px]'>
                        <FormControl>
                            <Label>Dial code</Label>
                            <FormDialCodeInput
                                name='dialCode'
                                control={control}
                                placeholder='US +1'
                            />
                        </FormControl>
                        <FormControl>
                            <Label>Phone number</Label>
                            <FormPhoneInput
                                name='phoneNumber'
                                control={control}
                                rules={{
                                    validate: (phone) => {
                                        return !phone || utils.clientPhoneRegex.test(phone);
                                    },
                                }}
                                className='w-full h-12 text-xs font-inter'
                            />
                            {errors?.phoneNumber && (
                                <span className='font-inter text-xs text-red'>
                                    Phone number format is invalid
                                </span>
                            )}
                        </FormControl>
                    </div>

                    <div className='flex w-full gap-x-[32px] mt-[21px]'>
                        <div className="w-full">
                            <Label className="mb-[14px]">Socials</Label>
                            <FormInputV2
                                name="linkedin"
                                icon={<IconV2 iconType="LINKEDIN" />}
                                control={control}

                                placeholder="Linkedin url"
                                rules={{ validate: (url) => !url || utils.linkedinRegex.test(url) }}
                            />
                            {errors?.linkedin?.type === "validate" && (
                                <p className="font-inter text-xs text-red mt-1">
                                    {"Linkedin profile is invalid (e.g. https://linkedin.com/in/john-doe)"}
                                </p>
                            )}

                            <Label className="mt-[14px]">{""}</Label>
                            <FormInputV2
                                name="github"
                                icon={<IconV2 iconType="GITHUB" />}
                                control={control}
                                placeholder="Github url"
                                rules={{
                                    validate: (url) => utils.githubRegex.test(url) || !url,
                                }}
                            />
                            {errors?.github && (
                                <p className="font-inter text-xs text-red mt-1">
                                    {"Github profile is invalid (e.g. https://github.com/johndoe)"}
                                </p>
                            )}

                            <Label className="mt-[14px]">{""}</Label>
                            <FormInputV2
                                name="website"
                                icon={<IconV2 iconType="PORTFOLIO" />}
                                control={control}
                                placeholder="Portafolio url"
                                rules={{
                                    validate: (url) => utils.urlRegex.test(url) || !url,
                                }}
                            />
                            {errors?.website && (
                                <p className="font-inter text-xs text-red mt-1">
                                    {"Website url is invalid (e.g. https://example.com)"}
                                </p>
                            )}
                        </div>
                        <div className="w-full" />

                    </div>
                </div>

                <div className="w-full flex justify-center mt-[46px]">
                    <Button type="submit" loading={isSubmitting} disabled={!isEmpty(errors)}>
                        Save changes
                    </Button>
                </div>

            </form>

        </ModalV2>

    );
}

export default EditTalentContactModal;