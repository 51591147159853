import { Link } from "react-router-dom";
import { ColorRing } from "react-loader-spinner";

import useBoardDetail from "hooks/useBoardDetail";
import BoardCard from "./components/BoardCard";
import { BackIcon } from "./components/icons";

export default function BoardDetailPage() {
  const { board, isLoading } = useBoardDetail();

  return (
    <main className="w-full pt-[90px]">
      <div className="max-w-[800px] 2xl:max-w-[1200px] mx-auto">
        <Link
          to="../"
          className="flex items-center gap-x-5 font-inter text-[#8A8A8A] font-semibold"
        >
          <BackIcon />
          Back
        </Link>
        {board && (
          <p className="my-10 font-poppins text-base font-semibold first-letter:uppercase">
            {board?.curated ? "Curated" : board?.hidden ? "Hidden" : "All"} boards
          </p>
        )}

        {board && <BoardCard board={board} />}

        {isLoading && (
          <div className="w-full justify-center flex h-[400px] items-center">
            <ColorRing
              visible={true}
              height="120"
              width="120"
              ariaLabel="blocks-loading"
              wrapperStyle={{}}
              wrapperClass=""
              colors={["#FF8134", "#FF5757", "#5F5FFF", "#2F3454", "transparent"]}
            />
          </div>
        )}
      </div>
    </main>
  );
}
