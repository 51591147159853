import { Order, Sort } from "types/Sort";
import IconV2 from "../Icons/IconV2";

interface ITH {
    onClick: (field: string, order?: Order, getFiled?: Function) => void;
    field: string,
    sort?: Sort,
    getField?: Function;// This functions gives the given field in case is not simple to get
    className?: string,
    text: string,
}

export const THsort = ({ onClick, text, className = "", sort, field, getField }: ITH) => {
    const selected = sort && sort[0] === field && sort[1];
    return (
        <th className={className}>
            <div className={"flex gap-[16px] w-fit mx-auto "}>
                {text}
                <div className="flex flex-col items-center gap-[10px] justify-center  ">
                    <div onClick={() => onClick(field, selected === "ASC" ? undefined : "ASC", getField)}>
                        <IconV2 iconType="ARROW-UP" iconClassName={`cursor-pointer w-[12px] fill-inactive-gray ${selected === "ASC" && "fill-odf"} `} />
                    </div>
                    <div onClick={() => onClick(field, selected === "DESC" ? undefined : "DESC", getField)}>
                        <IconV2 iconType="ARROW-DOWN" iconClassName={`cursor-pointer w-[12px] fill-inactive-gray ${selected === "DESC" && "fill-odf"} `} />
                    </div>
                </div>
            </div>
        </th>
    )
}