import { JobApplicantFilters } from './../../types/Filters/JobApplicationFilters';
import { IJobOfferPaginatedFilters } from './../../types/Filters/OfferFilters';
import { IJobContractPaginatedFilters } from './../../types/Filters/ContractFilters';
import { API } from 'aws-amplify';
import awsUtils from 'utils/awsUtils';

// import types
import utils from 'utils/utils';
import { JobPosting } from 'types/JobPosting';

function getQueryParamnsPath(filters: object) {
  // Add filters params
  let path = "";
  Object.keys(filters).forEach((key) => {
    let value = filters[key];
    value = utils.isString(value) ? value : JSON.stringify(value);
    path += `${key}=${value}&`;
  });
  return path;
}

const getJobApplications = async (filters: JobApplicantFilters = {}) => {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/job/applied?${getQueryParamnsPath(filters)}`;
  const myInit = { headers: { ...authHeader, }, };

  const result = await API.get(apiName, path, myInit);
  return result;
}

const updateJobPostingByID = async (data: JobPosting) => {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/job`;

  const myInit = {
    headers: {
      ...authHeader,
    },
    body: JSON.stringify(data),
  };
  const result = await API.put(apiName, path, myInit);
  return result;
};

const removeJobPostingByID = async (id: number, company_id: number) => {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/job/remove/${company_id}/${id}`;

  const myInit = {
    headers: {
      ...authHeader,
    },
  };
  const result = await API.del(apiName, path, myInit);
  return result;
};

const recommendTalentToJob = async (freelancer_id: number, job_id: number) => {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();

  const path = '/job/talent_recommendations';

  const myInit = {
    headers: {
      ...authHeader,
    },
    body: JSON.stringify({ freelancer_id, job_id }),
  };
  const result = await API.post(apiName, path, myInit);
  return result;

}

const recommendTalentsToJob = async (data: { freelancer_id: number, job_id: number }[]) => {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();

  const path = '/job/talent_recommendations';

  const myInit = {
    headers: {
      ...authHeader,
    },
    body: JSON.stringify({ rows: data }),
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

const getRecommendedTalentsForJob = async (job_id: number) => {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/job/talent_recommendation?job_id=${job_id}`;

  const myInit = {
    headers: {
      ...authHeader,
    },
    body: {},
  };
  const result = await API.get(apiName, path, myInit);
  return result;
}


const getOffers = async (filters: IJobOfferPaginatedFilters) => {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/job/offer/all/?` + getQueryParamnsPath(filters);

  const myInit = { headers: { ...authHeader } };
  const result = await API.get(apiName, path, myInit);
  return result;
};

const getJobOffersByJobId = async (job_id: number) => {
  return await getOffers({ job_id });
};


const getContracts = async (filters: IJobContractPaginatedFilters) => {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = "/job/contract/?" + getQueryParamnsPath(filters);

  const myInit = {
    headers: {
      ...authHeader,
    },
  };
  const result = await API.get(apiName, path, myInit);
  return result;
};

const getJobContractsByJobId = async (job_id: number) => {
  return await getContracts({ job_id });
};


export default {
  getJobApplications,
  getJobContractsByJobId,
  updateJobPostingByID,
  removeJobPostingByID,
  recommendTalentToJob,
  recommendTalentsToJob,
  getRecommendedTalentsForJob,
  getJobOffersByJobId
};
