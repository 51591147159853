import IconV2 from "components/V2/Icons/IconV2";
import ENUMS from "constants/enums";
import moment from "moment";
import { FreelancerProfileWithRewards } from "types/FreelancerProfileWithRewards";

interface ITokenEarnings {
  talent: FreelancerProfileWithRewards;
}

const TokenEarnings = ({ talent }: ITokenEarnings) => {
  const totalEarnings = talent.Rewards.reduce((acc, r) => acc + r.amount, 0);

  return (
    <div className="py-[150px] pl-[160px] w-[805px]">
      {/* Header */}
      <div className="flex justify-between w-full mb-[30px]">
        <span className="font-poppins font-semibold text-[20px]">Total token earnings</span>
        <div className="flex gap-[12px]">
          <IconV2 iconType="TOKEN" iconClassName="w-[27px]" />
          <span className="font-poppins font-bold text-[32px]">{totalEarnings}</span>
        </div>
      </div>

      {/* Rewards */}
      <div className="h-[600px] overflow-y-auto">
        {talent.Rewards.map((r) => (
          <div className="flex px-[20px] py-[13px] border-[1px] border-dark-gray w-full items-center">
            <IconV2 iconType="OUTDEF-LOGO" iconClassName="w-[30px] h-[30px]" />

            <div className="ml-[7px]">
              <span className="font-poppins font-semibold text-[14px]">
                {ENUMS.achievementsMessages[r.received_from] || "Unknown"}
              </span>
              <div className="flex gap-[8px] items-center">
                <IconV2
                  iconType="RECEIVE-SQUARE"
                  iconClassName="w-[13px] h-[13px]"
                  fill="#8A8A8A"
                />
                <span className="font-inter font-semibold text-[12px] text-inactive-gray">
                  Received
                </span>
                <span className="font-inter font-semibold text-[12px] text-inactive-gray">
                  {moment(r.createdAt).format("D/MM/YY")}
                </span>
              </div>
            </div>

            <div className="flex gap-[10px] ml-auto">
              <IconV2 iconType="OUTDEF-LOGO" iconClassName="w-[20px] mt-[3px] h-[20px]" />
              <div className="flex flex-col font-inter font-semibold">
                <span className="text-[16px]">{r.amount}</span>
                <span className="text-[12px] text-inactive-gray">DEFN</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TokenEarnings;
