import { useForm } from "react-hook-form";

import Button from "components/Button/ButtonV2";
import FormStarRating from "components/V2/StarRating/FormStarRating";
import { FormTextareaV2 } from "components/V2/Textarea";
import { AssessmentInfo } from "types";
import { FormSkillInputV2 } from "components/V2/SkillInput/SkillInput";
import IconV2 from "components/V2/Icons/IconV2";
import { useAppSelector } from "redux/hooks/redux-hooks";

interface ITechnicalInterview {
    assessment: AssessmentInfo,
}


function getDefaultValues(assessment: AssessmentInfo) {
    return {
        rating: (JSON.parse(assessment.rating || '{}')).interviewTechnicalSkillsRating,
        notes: (JSON.parse(assessment.notes_taken || '{}')).interviewTechnicalSkillsNotes,
        skills: JSON.parse(assessment.interview_technical_skills || '[]')
    }
}

const TechnicalInterview = ({ assessment }: ITechnicalInterview) => {

    const skills = useAppSelector((root) => root.prototype.skills);
    const { control } = useForm({
        defaultValues: getDefaultValues(assessment)
    });

    return (
        <div className="flex flex-col" >

            {/* Skills Technical */}
            <div className="w-[300px] mt-[32px]">
                <span className="font-poppins font-semibold text-[16px]">Skills interview</span>
                <FormSkillInputV2
                    icon={<IconV2 iconType="CODE" iconClassName="w-[20px]" />}
                    name="skills"
                    control={control}
                    skillData={skills}
                    isPrimary={false}
                />
            </div>

            <span className="font-poppins text-[16px] font-semibold mt-[66px]">
                Rating
            </span>
            <span className="font-inter text-[12px] mt-[10px]">
                Rate your interview using our 1-5 star system, 1 being strong NO hire
                and 5 being a strong hire.
            </span>
            <div className="mt-[10px]">
                <FormStarRating control={control} isReadOnly name="rating" />
            </div>

            <span className="font-poppins text-[12px] font-semibold mt-[13px]">Add notes</span>
            <FormTextareaV2
                control={control}
                disabled
                name={"notes"}
                className="mt-[18px] text-[12px] h-[108px]"
                limitText="200 words max"
                placeholder="Write down some notes"
            />

            <div className="w-full flex justify-end">
                <Button
                    className="px-4"
                    type="submit"
                    variant="secondary"
                    disabled
                >
                    Save changes
                </Button>
            </div>
        </div>
    );
}

export default TechnicalInterview;