import { getJobPrimarySkills } from 'utils/jobUtils';
import { useMemo } from 'react';
import { FreelancerProfile } from './../types/FreelancerProfile';
import { JobPosting } from './../types/JobPosting';
import { SkillFullType } from './../redux/slices/prototype';
import { useAppSelector } from './../redux/hooks/redux-hooks';
import { averageRating } from 'utils/talent/utils';
import ENUMS from 'constants/enums';

function usePrototypeUtils() {
    const prototype = useAppSelector((root) => root.prototype);

    const allRoles =  useMemo(
      () => prototype.roles.map( ({name, id}) => ({value: name, id})), 
      [prototype.roles]);

    const getSkillsFromJSON = (skills: string) : SkillFullType[] => {
      
      const ids = JSON.parse(skills);

      const fullSkills : SkillFullType[] = [];

      ids.forEach( (id) => {
        const found  = prototype.skills.find(skill => skill.id === id) ;
        if(found) fullSkills.push(found);
      })

      return fullSkills
    }

    const getJobMatchLevel = (talent: FreelancerProfile, job: JobPosting) => {
      let match = 0;
      let total = 0;
      const jobSkills = getJobPrimarySkills(job);

      // Check the compensation
      const { hourly_rate } = talent;
      if (hourly_rate && hourly_rate < job.hourly_max_rate && hourly_rate > job.hourly_min_rate) match++;
      total++;

      // Check the skills
      const skills = talent.skills?.filter(s => s.freelancer_skill.is_primary) || [];
      jobSkills.forEach(s => {
          if (skills.some(js => js.id === s.id)) match++;
          total++;
      })


      // Check the assertment rating
      const talentRating = averageRating(talent?.User?.AssessmentInfo?.rating);
      if (talentRating >= 3) match++;
      if (talentRating >= 4) match++;
      if (talentRating >= 4.9) match++;
      total += 3;


      // Check the experience
      const talentLevel = ENUMS.level.findIndex(level => level === talent.level_of_experience);
      const jobLevel = ENUMS.level.findIndex(level => level === job.experience_level);
      if (jobLevel <= talentLevel) match++;
      total++;

      // Check the role
      const talentRole = talent.Role?.name;
      if (talentRole === job.job_title) match += 2;
      total += 2;

      return {match, total, string: `${match}/${total}`}
    }

    return {
      getSkillsFromJSON,
      getJobMatchLevel,
      allRoles,
    };
}

  
export default usePrototypeUtils;