import StarRating from "react-svg-star-rating";
import { useAppSelector } from "redux/hooks/redux-hooks";

import Button from "components/Button/ButtonV2";
import { AssessmentStatus, FreelancerProfile } from "types";
import BadgeV2 from "components/V2/Badges/BadgeV2";
import IconV2 from "components/V2/Icons/IconV2";

interface IVetDecline {
    talent: FreelancerProfile,
}

const notCompleted = (status?: AssessmentStatus) => status !== "PASSED" && status !== "FAILED";

const VetDecline = ({ talent }: IVetDecline) => {
    const skills = useAppSelector((root) => root.prototype.skills);
    const skillsObj = {};
    skills.forEach(s => skillsObj[s.id] = s);
    const assessment = talent.User.AssessmentInfo;
    const isTrustedOrDeclined = talent.is_trusted_talent === "TRUSTED" || talent.is_trusted_talent === "FAILED";

    // Buttons to decline or vet
    const buttons = (
        <div className="w-full flex justify-between">
            <Button
                variant="secondary"
                className="px-[10px]"
                disabled
            >
                Decline talent
            </Button>
            <Button
                className="px-4"
                disabled
            >
                Vet talent
            </Button>
        </div>
    );

    const ratings = JSON.parse(talent.User.AssessmentInfo?.rating || '{}');
    const notes = JSON.parse(talent.User.AssessmentInfo?.notes_taken || '{}');

    // Introduction data
    const introductionRating = ratings.englishProficiencyRating;
    const introductionNotes = notes.englishProficiencyNotes;
    const introductionLink = assessment?.introduction_url;
    const introductionMissing = notCompleted(assessment?.introduction_status);

    // MCQ data
    const mcq_skills = JSON.parse(assessment?.technical_skills || '[]').map(id => skillsObj[id]);
    const mcq_rating = ratings.technicalSkillsRating;
    const mcq_notes = notes.technicalSkillsNotes;
    const mcqMissing = notCompleted(assessment?.mcq_status);

    // Coding test data
    const coding_skills = JSON.parse(assessment?.coding_skills || '[]').map(id => skillsObj[id]);
    const coding_rating = ratings.codingSkillsRating;
    const coding_notes = notes.codingSkillsNotes;
    const codingMissing = notCompleted(assessment?.coding_status);

    // Interview data
    const interview_rating1 = ratings.behavioralInterviewRating;
    const interview_notes1 = notes.behavioralInterviewNotes;
    const interview_rating2 = ratings.interviewTechnicalSkillsRating;
    const interview_notes2 = notes.interviewTechnicalSkillsNotes;
    const interviewMissing = notCompleted(assessment?.interview_status);

    return (
        <div className="p-[50px] w-1/2">

            {/* If vetted */}
            {talent.is_trusted_talent === "TRUSTED" &&
                <div className="flex justify-left items-center mt-8 ml-2">
                    <span className="font-poppins font-semibold text-[20px]">
                        Talent has been vetted
                    </span>
                    <img src={"/common/spaceboy/gamer1.png"} width={186} height={170} />
                </div>
            }

            {/* If declined */}
            {talent.is_trusted_talent === "FAILED" &&
                <div className="flex mt-8 ml-2">
                    <div className="flex flex-col justify-between h-[120px] mt-[40px]">
                        <span className="font-poppins font-semibold text-[20px]">Talent has been declined</span>
                        <Button
                            className="w-fit px-[16px] py-[12px]"
                            disabled
                        >
                            Invite to try again
                        </Button>
                    </div>
                    <img
                        src={"/common/spaceboy/astro-angle.png"}
                        alt="astro is angling"
                        width={146}
                        height={146}
                    />
                </div>
            }

            {!isTrustedOrDeclined && buttons}

            {/* Introuction */}
            <div className="flex flex-col mt-[58px]">
                <div className="flex justify-between border-solid border-inactive-gray border-b-[2px]">
                    <span className="font-poppins font-semibold text-[20px]">Online interview</span>
                    {introductionMissing && <IconV2 iconType="MISSING-ASSESSMENT" />}
                </div>

                <span className="font-poppins font-semibold text-[16px] mt-[12px] mb-[10px]">Introduce yourself </span>
                {introductionLink ?
                    <a href={introductionLink} className="font-inter font-semibold text-[14px] underline">{introductionLink}</a>
                    :
                    <span className="font-inter text-[14px]">N/A</span>
                }

                <span className="font-poppins font-semibold text-[16px] mt-[26px] mb-[13px]">English Proficiency</span>

                <StarRating
                    isReadOnly
                    innerRadius={30}
                    initialRating={parseInt(introductionRating ?? 0)}
                    containerClassName="flex"
                />

                <span className="font-poppins font-semibold text-[12px] mt-[20px]">Notes</span>
                <span className="font-inter text-[12px] mt-[10px]">{introductionNotes}</span>
            </div>

            {/* MCQ */}
            <div className="flex flex-col mt-[58px]">
                <div className="flex justify-between border-solid border-inactive-gray border-b-[2px]">
                    <span className="font-poppins font-semibold text-[20px]">MCQ Coding Assessment (Engineers only)</span>
                    {mcqMissing && <IconV2 iconType="MISSING-ASSESSMENT" />}
                </div>

                <span className="font-poppins font-semibold text-[16px] mt-[20px]">Technical skills assessed</span>
                <div className="flex gap-x-[8px] mt-[12px] mb-[20px]">
                    {
                        mcq_skills.map((skill, i) => <BadgeV2 key={i}>{skill ? skill.name : '?'}</BadgeV2>)
                    }
                </div>

                <StarRating
                    isReadOnly
                    innerRadius={30}
                    initialRating={parseInt(mcq_rating ?? 0)}
                    containerClassName="flex"
                />

                <span className="font-poppins font-semibold text-[12px] mt-[20px]">Notes</span>
                <span className="font-inter text-[12px] mt-[10px]">{mcq_notes}</span>
            </div>

            {/* Coding */}
            <div className="flex flex-col mt-[58px]">
                <div className="flex justify-between border-solid border-inactive-gray border-b-[2px]">
                    <span className="font-poppins font-semibold text-[20px]">Coding Challenge Assessment (Engineers only)</span>
                    {codingMissing && <IconV2 iconType="MISSING-ASSESSMENT" />}
                </div>

                <span className="font-poppins font-semibold text-[16px] mt-[20px]">Coding skills assessed</span>
                <div className="flex gap-x-[8px] mt-[12px] mb-[20px]">
                    {
                        coding_skills.map((skill, i) => <BadgeV2 key={i}>{skill ? skill.name : '?'}</BadgeV2>)
                    }
                </div>

                <StarRating
                    isReadOnly
                    innerRadius={30}
                    initialRating={parseInt(coding_rating ?? 0)}
                    containerClassName="flex"
                />

                <span className="font-poppins font-semibold text-[12px] mt-[20px]">Notes</span>
                <span className="font-inter text-[12px] mt-[10px]">{coding_notes}</span>
            </div>

            {/* Interview */}
            <div className="flex flex-col mt-[58px] mb-[54px]">
                <div className="border-solid border-inactive-gray border-b-[2px]">
                    <span className="font-poppins font-semibold text-[20px]">In-person interview</span>
                </div>

                {/* Behavior */}
                <div className="flex justify-between mt-[13px] mb-[20px]">
                    <span className="font-poppins font-semibold text-[16px]">Behavioral interview</span>
                    {interviewMissing && <IconV2 iconType="MISSING-ASSESSMENT" />}
                </div>
                <StarRating
                    isReadOnly
                    innerRadius={30}
                    initialRating={parseInt(interview_rating1 ?? 0)}
                    containerClassName="flex"
                />
                <span className="font-poppins font-semibold text-[12px] mt-[17px]">Notes</span>
                <span className="font-inter text-[12px] mt-[10px]">{interview_notes1}</span>

                {/* Tech Skills */}
                <div className="flex justify-between mt-[25px] mb-[20px]">
                    <span className="font-poppins font-semibold text-[16px]">Skills interview</span>
                </div>
                <StarRating
                    isReadOnly
                    innerRadius={30}
                    initialRating={parseInt(interview_rating2 ?? 0)}
                    containerClassName="flex"
                />
                <span className="font-poppins font-semibold text-[12px] mt-[17px]">Notes</span>
                <span className="font-inter text-[12px] mt-[10px]">{interview_notes2}</span>

            </div>

            {!isTrustedOrDeclined && buttons}

        </div>
    );
}

export default VetDecline;