import { FreelancerSkill } from "types";
import BadgeV2 from "../Badges/BadgeV2";


interface ISkillCell {
    skills?: FreelancerSkill[]
}

const SkillsCell = ({ skills = [] }: ISkillCell) => {
    return (
        <div className="flex justify-center flex-wrap gap-[8px]">
            {skills?.filter(s => s.freelancer_skill.is_primary).map(s =>
                <BadgeV2 addClass="h-fit" key={s.name} color="pink" starInBadge>{s.name}</BadgeV2>
            )}
        </div>
    );
}

export default SkillsCell;