import { API } from 'aws-amplify';
import awsUtils from 'utils/awsUtils';

async function getAssigneeList() {
    const authHeader = await awsUtils.getAuthHeader();
    const apiName = awsUtils.getAPIName();
  
    const path = `/assignee`;
  
    const myInit = {
      headers: { ...authHeader },
    };
    const result: any = await API.get(apiName, path, myInit);
    return result;
}

const assignTalent = async (data :{freelancer_id: number, assignee_id: number}[]) => {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();

  const path = '/freelancer/assignee';

  const myInit = {
    headers: {
      ...authHeader,
    },
    body: JSON.stringify({rows: data}),
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}


export default {
  getAssigneeList,
  assignTalent
}