import { useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import companyAPI from "network/company";
import applicationAPI from "network/application";
import { Company } from "types/Company";
import { toast } from "react-toastify";
import Loader from "components/V2/Loader/Loader";
import IconV2 from "components/V2/Icons/IconV2";
import CompanyLogo from "../components/CompanyLogo";
import JobPosts from "./components/JobPosts";
import EditCompanyModal from "./components/EditCompanyModal";

const DetailedCompanyView = () => {
  const navigate = useNavigate();
  const [editing, setEditing] = useState<boolean>(false);
  const { id } = useParams();
  const [next, setNext] = useState<number | undefined>();
  const [company, setCompany] = useState<Company | undefined>();

  // Look for the next to this company in the list
  const fetchNext = async () => {
    const data: Company[] = await applicationAPI.getCompanyList();
    const index = data.findIndex((d) => d.company_id === Number(id));
    setNext(data[index + 1]?.company_id);
  };
  useEffect(() => {
    if (id) fetchNext();
  }, [id]);

  // Fetch company data
  const fetchCompany = async () => {
    const c = await companyAPI.getCompanyById(id || "");
    if (!c.success) {
      toast.error("Error while retrieving company");
      return;
    }
    setCompany(c.data);
  };
  useEffect(() => {
    if (id) fetchCompany();
  }, [id]);

  if (!company) return <Loader />;

  const onCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success("Copied!");
  };

  const onCompanyUpdateSucess = () => {
    setCompany(undefined);
    fetchCompany();
  };

  return (
    <div className="w-full h-full overflow-y-auto">
      {/* Arrows */}
      <div className="flex pl-[37px] pr-[25px] h-[53px] w-full border-b-solid border-b-dark-gray border-b-[1px] justify-between items-center">
        <div
          className="flex items-center hover:cursor-pointer"
          onClick={() => navigate(`/company/overview`)}
        >
          <IconV2 iconType="BACK-ARROW" iconClassName="w-[25px] h-[25px]" />
          <span className="text-[14px] font-inter font-semibold ml-[15px]">Back</span>
        </div>
        {!!next && (
          <div
            className="flex items-center hover:cursor-pointer"
            onClick={() => navigate(`/company/details/${next}`)}
          >
            <span className="text-[14px] font-inter font-semibold mr-[15px]">Next</span>
            <IconV2 iconType="NEXT-ARROW" iconClassName="w-[25px] h-[25px]" />
          </div>
        )}
      </div>

      {/* Company Info */}
      <div className="h-[91px] pl-[37px] pr-[27px] py-[10px] border-b-solid border-b-dark-gray border-b-[1px] flex justify-between items-center">
        <div className="flex items-center">
          <CompanyLogo company={company} className="w-[60px] h-[60px]" />
          <span className="text-[16px] ml-[25px] font-poppins font-semibold">{company.name}</span>
          <div className="flex gap-[47px] ml-[110px]">
            <div className="flex gap-[9px]">
              <IconV2 iconType="COMPANY" />
              <span className="font-inter font-semibold text-[14px] text-dark-gray ">
                {company.industry}
              </span>
            </div>
            <div className="flex gap-[9px]">
              <IconV2 iconType="USER" />
              <span className="font-inter font-semibold text-[14px] text-dark-gray ">
                {company.number_of_employees}
              </span>
            </div>
            {!!company.city && (
              <div className="flex gap-[9px]">
                <IconV2 iconType="LOCATION" />
                <span className="font-inter font-semibold text-[14px] text-dark-gray ">
                  {company.city}
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="flex gap-[10px]">
          <div
            onClick={onCopy}
            className="w-[32px] h-[32px] bg-[#F4F4F4] flex items-center rounded-lg hover:cursor-pointer"
          >
            <IconV2 iconType="COPY" iconClassName="mx-auto" />
          </div>
          <div
            onClick={() => setEditing(true)}
            className="w-[32px] h-[32px] bg-[#F4F4F4] flex items-center rounded-lg hover:cursor-pointer"
          >
            <IconV2 iconType="EDIT" iconClassName="mx-auto w-[20px] h-[20px]" />
          </div>
        </div>
      </div>

      {/* Contact and Social */}
      <div className="h-[50px] pl-[47px] pr-[30px] py-[15px] flex justify-between border-b-solid border-b-dark-gray border-b-[2px]">
        <div className="flex gap-[20px] items-center">
          <span className="text-[14px] font-inter font-semibold">Contact</span>
          <div className="flex gap-[28px]">
            <div className="flex gap-[10px]">
              <IconV2 iconType="WORLD" iconClassName="w-[24px] h-[24px]" />
              <span className="font-inter text-[14px]">{company.website}</span>
            </div>
          </div>
          <div className="flex gap-[28px]">
            <div className="flex gap-[10px]">
              <IconV2 iconType="PHONE" iconClassName="w-[24px] h-[24px]" />
              <span className="font-inter text-[14px]">{company.full_phone}</span>
            </div>
          </div>
        </div>

        <div className="flex gap-[22px]">
          <span className="text-[14px] font-semibold font-inter">Company socials</span>
          <div className="flex gap-[15px]">
            {company?.CompanySocialLink?.linkedin_link && (
              <a href={company.CompanySocialLink.linkedin_link}>
                <IconV2 iconType="LINKEDIN" />
              </a>
            )}
            {company?.CompanySocialLink?.twitter_link && (
              <a href={company.CompanySocialLink.twitter_link}>
                <IconV2 iconType="TWITTER" />
              </a>
            )}
            {company?.CompanySocialLink?.instagram_link && (
              <a href={company.CompanySocialLink.instagram_link}>
                <IconV2 iconType="INSTAGRAM" />
              </a>
            )}
          </div>
        </div>
      </div>

      {/* Jobs posted */}
      <div>
        <div className="pt-[27px] pl-[20px] flex flex-col mb-[13px]">
          <span className="font-inter font-semibold text-[16px]">Filter by active jobs </span>
          <span className="font-inter text-[12px] mt-[10px]">
            Choose an existing job from our list to see the applicants or recommend talent
          </span>
        </div>

        <JobPosts company={company} />
      </div>

      {/* Edit Company modal */}
      <EditCompanyModal
        company={company}
        isOpen={editing}
        onSuccess={onCompanyUpdateSucess}
        onClose={() => setEditing(false)}
      />
    </div>
  );
};

export default DetailedCompanyView;
