
import moment from "moment";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import tokenAPI from "network/token";
import { FreelancerProfile } from "types";
import Loader from "components/V2/Loader/Loader";
import IconV2 from "components/V2/Icons/IconV2";
import { TalentReferralHistory } from "types/TalentReferralHistory";
import ArrowButton from "components/ArrowButton";
import InputV2 from "components/V2/Input/InputV2";
import DropdownV2 from "components/V2/Dropdown/DropdownV2";
import { ITalentReferralHistoryFilters } from "network/token/types";
import useDebounce from "hooks/useDebounce";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";
import PaginatedDiv from "components/V2/Pagination/PaginatedDiv";

interface IReferralHistory {
  talent: FreelancerProfile;
}

const page_size = 10;
const today = (new Date()).toISOString().split('T')[0];
const dropdown_filters = [
  "All referrals",
  "Invited referral",
  "Signed up referral",
  "Vetted referrals",
];

const ReferralHistory = ({ talent }: IReferralHistory) => {
  const [referrals, setReferrals] = useState<TalentReferralHistory[]>();
  const [count, setCount] = useState<number>();
  const [loading, setLoading] = useState<boolean>(false);

  const [keyword, setKeyword] = useState<string>("");
  const [invitedDates, setInvitedDates] = useState<{ from?: string, to?: string }>({ to: today })
  const [signedUpDates, setSignedUpDates] = useState<{ from?: string, to?: string }>({ to: today })
  const [dFilter, setDFilter] = useState<number>(0);

  const onSearch = async (total = false) => {
    setLoading(true);

    // Calculate the filters neeeded for endpoint
    const filters: ITalentReferralHistoryFilters = {}
    const { to: to1, from: from1 } = invitedDates;
    const { to: to2, from: from2 } = signedUpDates;

    if (keyword) filters.keyword = keyword.toLowerCase();
    if (to1 && from1) filters.invited_date = [from1, to1];
    if (to2 && from2) filters.sign_up_date = [from2, to2];
    filters.referrer_id = talent.freelancer_id;

    // Dropdown filters
    switch (dFilter) {
      case 1:
        filters.signed_up = false;
        break;
      case 2:
        filters.signed_up = true;
        break;
      case 3:
        filters.vetted = true;
        break;
      default:
        break;
    }

    try {
      const offset = total ? undefined : referrals?.length;
      const { histories, count } = (await tokenAPI.getReferralsHistory({ ...filters, offset, page_size, count: total }))?.data;
      setReferrals((prev) => [...(total ? [] : (prev || [])), ...histories]);
      if (total) setCount(count);
    } catch (e) {
      toast.error("Error while fetching histories")
    }

    setLoading(false);
  };

  // Debounced search
  const debounceSearch = useDebounce(onSearch, 2000);
  useEffect(() => { debounceSearch(true); }, [keyword]);
  useEffect(() => { onSearch(true); }, [invitedDates, signedUpDates, dFilter]);

  return (
    <div className="py-[84px] pl-[68px] pr-[100px]">
      {/* FILTERS */}
      <div className="flex flex-col gap-10">
        {/* Search bar */}
        <InputV2
          className="w-[520px]"
          value={keyword} onChange={(e) => setKeyword(e.target.value)}
          icon={<IconV2 iconType="SEARCH" iconClassName="w-6 h-6 translate-y-[1px]" />}
          placeholder="Search for talent by email or name "
        />

        {/* Dates */}
        <div className="flex gap-10 w-full">
          <div className="w-fit flex gap-[10px] items-center">
            <span className="w-fit font-poppins text-[16px] font-bold">Filter by invited:</span>
            <InputV2
              type="date"
              wrapClassName="w-[190px]"
              name="from"
              value={invitedDates.from}
              onChange={(e) => setInvitedDates({ ...invitedDates, from: e.target.value })}
            />

            <InputV2
              type="date"
              wrapClassName="w-[190px]"
              name="to"
              defaultValue={invitedDates.to}
              onChange={(e) => setInvitedDates({ ...invitedDates, to: e.target.value })}

            />
          </div>
          <div className="w-fit flex gap-[10px] items-center">
            <span className="w-fit font-poppins text-[16px] font-bold">Filter by signed up:</span>
            <InputV2
              type="date"
              wrapClassName="w-[190px]"
              name="from"
              onChange={(e) => setSignedUpDates({ ...signedUpDates, from: e.target.value })}
            />

            <InputV2
              type="date"
              wrapClassName="w-[190px]"
              name="to"
              defaultValue={signedUpDates.to}
              onChange={(e) => setSignedUpDates({ ...signedUpDates, to: e.target.value })}

            />
          </div>
        </div>

        {/* Dropdown filters */}
        <div className="flex gap-[20px]">
          <span className="font-inter font-bold text-[14px]">Filter by:</span>
          <DropdownV2
            className="w-[250px]"
            buttonClass="w-fit h-[21px] pr-[50px] !font-inter  !text-[14px] border-none "
            selectedIndex={dFilter}
            onChange={setDFilter}
            data={dropdown_filters.map(f => ({ value: f }))}
          />
        </div>

      </div>

      {/* Referrals */}
      <div className="font-poppins text-[20px] font-bold flex mt-10">
        <p>Referral history {count === undefined ? "" : count}</p>
        {loading && <Loader className="w-[30px] h-[30px]" />}
      </div>
      <PaginatedDiv currentCount={referrals?.length} count={count} onLimit={onSearch} loading={loading} className="h-[400px]">
        <table className="w-full mt-[20px] border-separate border-spacing-y-[12px] bg-[#F0F1F2] p-[8px_20px_8px_20px]">
          <thead>
            <tr className="bg-white font-poppins text-[14px] font-bold [&>*]:py-[25px] ">
              <th className="rounded-tl-lg rounded-bl-lg py-[6px]">Email</th>
              <th>Role</th>
              <th>Location</th>
              <th>Invited</th>
              <th>Signed Up</th>
              <th>Referrer</th>
              <th className="rounded-br-lg rounded-tr-lg">View profile</th>
            </tr>
          </thead>
          <tbody className="font-inter text-[14px] font-semibold">
            {
              referrals?.map(r => (
                <tr key={r.id} className="[&>*]:py-[22px] bg-white">
                  <td className="rounded-tl-lg rounded-bl-lg ">
                    {r.email}
                  </td>
                  <td>{r?.ReferredUser?.FreelancerProfile?.Role?.name || '--'} </td>
                  <td>{r.ReferredUser?.FreelancerProfile?.city || '--'}</td>
                  <td>{moment(r.createdAt).format("MM/DD/YYYY")}</td>
                  <td>
                    {r.ReferredUser ? moment(r.ReferredUser.createdAt).format("MM/DD/YYYY") : "--"}
                  </td>
                  <td>
                    {talent.User.first_name + " " + talent.User.last_name}
                  </td>
                  <td className="!w-[250px] rounded-tr-lg rounded-br-lg" >
                    {
                      !!r.ReferredUser ?
                        <a target="_blank" className="w-full flex items-center" href={`/talent/details/${r.email}`}>
                          <UserAvatarLogo user={r.ReferredUser} className="!w-[45px] !h-[45px]" />
                          <p className="ml-[12px] max-w-[150px]">{r.ReferredUser.first_name + " " + r.ReferredUser.last_name}</p>
                          <ArrowButton className="w-[15x] h-[15px] ml-auto mr-[30px]" onClick={() => null} direction="NEXT" />
                        </a>
                        :

                        "--"
                    }
                  </td>
                </tr>
              ))
            }

          </tbody>
        </table>
      </PaginatedDiv>
    </div>
  );
}

export default ReferralHistory;