import BadgeV2 from "components/V2/Badges/BadgeV2"
import IconV2 from "components/V2/Icons/IconV2"
import TabV2 from "components/V2/Tab/TabV2"
import moment from "moment"
import { FreelancerProfile, FreelancerExperience, FreelancerEducation } from "types"

interface IExpEducationDetail {
    talent: FreelancerProfile
}

const ExpCard = ({ exp }: { exp: FreelancerExperience }) => (
    <div className="w-[358px] h-[348px] rounded-md border-solid border-[1px] border-dark-gray
    pl-[18px] pr-[37px] pt-[27px] pb-[35px] overflow-hidden overflow-y-auto">
        <div className="flex">
            <div className="flex w-[52px] h-[52px] items-center justify-center bg-odf rounded-lg">
                <IconV2 iconType="BRIEFCASEWHITE" iconClassName="w-[32px]" />
            </div>
            <div className="flex flex-col ml-[16px] justify-center">
                <span className="font-poppins font-semibold text-[16px]">{exp.position}</span>
                <span className="font-inter font-semibold text-[14px] mt-[2px]">{exp.company_name}</span>

            </div>
        </div>

        <div className="flex gap-[16px] mt-[17px]">
            <BadgeV2>{exp.term || 'N/A'}</BadgeV2>
            <BadgeV2>{`${moment(exp.start_date).format("MM/YYYY")} - ${moment(exp.end_date).format("MM/YYYY")}`}</BadgeV2>
        </div>

        <ul className="list-disc ml-[10px] mt-[44px]">
            {
                JSON.parse(exp.summary).map(
                    (s, i) =>
                        <li className="text-[12px] font-nunito" key={i}>
                            {s}
                        </li>
                )
            }
        </ul>
    </div>
)

const EducationCard = ({ education }: { education: FreelancerEducation }) => (
    <div className="w-[358px] h-[348px] rounded-md border-solid border-[1px] border-dark-gray
    pl-[18px] pr-[34px] pt-[27px] pb-[35px]  overflow-hidden overflow-y-auto">
        <div className="flex">
            <div className="flex w-[52px] h-[52px] items-center justify-center bg-odf rounded-lg">
                <IconV2 iconType="BANK" iconClassName="w-[32px]" />
            </div>
            <div className="flex flex-col ml-[16px] justify-center">
                <span className="font-poppins font-semibold text-[16px]">{education.name}</span>
                <span className="font-inter font-semibold text-[14px] mt-[2px]">{education.major}</span>
            </div>
        </div>

        <div className="flex gap-[16px] mt-[17px]">
            <BadgeV2>{education.degree}</BadgeV2>
            <BadgeV2>{`${moment(education.start_date).format("MM/YYYY")} - ${moment(education.end_date).format("MM/YYYY")}`}</BadgeV2>
        </div>
    </div>
)

const ExpEducationDetail = ({ talent }: IExpEducationDetail) => {

    const tabs: string[] = [];
    const contents: JSX.Element[] = [];

    if (talent.FreelancerProfileExperiences.length) {
        tabs.push("Experience");
        const content = <div className="flex w-fit gap-[16px] pb-[20px]">
            {talent.FreelancerProfileExperiences.map(exp => <ExpCard key={exp.id} exp={exp} />)}
        </div>
        contents.push(content);
    }

    if (talent.FreelancerProfileEducations.length) {
        tabs.push("Education");
        const content = <div className="flex w-fit gap-[16px] pb-[20px]">
            {talent.FreelancerProfileEducations.map(edu => <EducationCard key={edu.id} education={edu} />)}
        </div>
        contents.push(content);
    }

    if (!tabs.length) return null;

    return (
        <TabV2
            addClass="mt-[40px] mb-[23px]"
            tabClass="py-[13px] pl-[45px] pr-[50px]"
            contentWrapperClass="w-full overflow-x-auto"
            tabs={tabs}
            contents={contents}
        />
    );
}

export default ExpEducationDetail;