import { useNavigate, useSearchParams } from "react-router-dom";
import { useMemo, useState } from "react";
import { toast } from 'react-toastify'
import IconV2 from "components/V2/Icons/IconV2";
import { ReputationTag } from "network/reputation/types";
import { reputationTitles } from "../constants";
import InputV2 from "components/V2/Input/InputV2";
import useDebounce from "hooks/useDebounce";
import Tabs, { tab } from "./components/Tabs";
import Table from "./components/Table";
import PagesBar from "components/V2/Pagination/PagesBar";
import { IReputation } from "types/Reputation";
import ReputationApi from "network/reputation/reputation";
import { IReputationFilters } from "types/Filters/IReputationFilters";
import Loader from "components/V2/Loader/Loader";
import { useAppSelector } from "redux/hooks/redux-hooks";

export const PAGE_SIZE = 10

const ReputationOverview = () => {
  const navigate = useNavigate();
  const { userId } = useAppSelector((root) => root.authentication);
  const [data, setData] = useState<IReputation[]>();
  const [count, setCount] = useState<number>();
  const [keyword, setKeyword] = useState<string>("");
  const [tab, setTab] = useState<tab>("all");
  const [params] = useSearchParams();
  const type: ReputationTag = params.get("type") as ReputationTag || "identity_proof";
  const title = reputationTitles[type];

  const fetchPage = useMemo(
    () =>
      async (offset: number): Promise<any[] | undefined> => {
        const filters: IReputationFilters = {}
        if (keyword) filters.keyword = keyword.toLowerCase()
        switch (tab) {
          case "assigned":
            filters.assignee_id = userId as any;
            break;
          case "denied":
            filters.status = "DENIED"
            break;
          case "pending":
            filters.status = "SUBMITTED"
            break;
          case "verified":
            filters.status = "VERIFIED"
            break;
          default:
            break;
        }

        try {
          const response = await ReputationApi.getAll(type, {
            page_size: PAGE_SIZE,
            offset,
            count: !offset,
            ...filters
          })
          if (!response.success) throw new Error(JSON.stringify(response))
          if (response.count !== undefined) setCount(response.count)
          return response.data
        } catch (e) {
          toast.error('Error while fetching page')
          console.error(e)
        }
      },
    [keyword, tab]
  )

  // Debounced search
  const debounceSearch = useDebounce((k) => setKeyword(k), 2000);

  return (<div className="w-full h-full overflow-y-scroll py-[50px] px-[70px]">

    <div
      className="flex items-center hover:cursor-pointer w-fit"
      onClick={() => navigate(`/reputation`)}
    >
      <IconV2 iconType="TRIANGLE-ARROW-SOLID" iconClassName="-rotate-90 fill-blue2 h-3 w-3" />
      <span className="font-inter ml-3">Back</span>
    </div>

    <div className="flex font-poppins text-xl font-semibold gap-[10px] my-5 ">
      <p>Verifications</p>
      <p>{">"}</p>
      <p>{title}</p>
    </div>

    {/* Search bar */}
    <InputV2
      className="w-[477px] rounded-3xl !bg-[#434343] font-poppins text-[14px] font-semibold  text-white  placeholder:text-[#ABA9A9]"
      onChange={(e) => debounceSearch(e.target.value)}
      icon={<IconV2 iconType="SEARCH2" iconClassName="w-5 h-5 translate-y-[1px] fill-white" />}
      placeholder="Search"
    />

    <div className="flex font-poppins text-xl font-semibold gap-[10px] my-5">
      <p>{title}</p>
      <p>{">"}</p>
      <p>All</p>
      {data && count !== undefined ? <p className="ml-10">{count}</p> : <Loader className="w-5 h-5 ml-10" />}
    </div>

    <Tabs selected={tab} onTabChange={setTab} />

    <Table type={type} data={data} refresh={() => setData(data && [...data])} />

    {/* Loader */}
    {!data && <div className="w-full h-[100px]"><Loader /></div>}

    <PagesBar
      page_size={PAGE_SIZE}
      className="mt-5 mx-auto w-fit"
      count={count}
      fetchPage={fetchPage}
      onLoading={() => setData(undefined)}
      onPageChange={(data) => setData(data)}
    />

  </div>);
}



export default ReputationOverview;