import utils from "utils/utils";
import dateUtils from "utils/dateUtils";
import ModalV2 from "components/Modal/ModalV2";
import IconV2 from "components/V2/Icons/IconV2";
import { FreelancerProfile } from "types";
import { JobOffer } from "types/JobOffer";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";
import usePrototypeUtils from "hooks/usePrototypeUtils";
import BadgeV2 from "components/V2/Badges/BadgeV2";
import { JobContract } from "types/JobContract";

const { getLocalDateFromUTCDate } = dateUtils;

interface IJobOfferModal {
    offer: JobOffer | JobContract,
    freelancer: FreelancerProfile,
    isOpen: boolean,
    onClose: () => void,
}

const JobOfferModal = ({ offer, isOpen, onClose, freelancer, }: IJobOfferModal) => {

    const { User } = offer;
    const { getSkillsFromJSON } = usePrototypeUtils();
    const offerPSkills = getSkillsFromJSON(offer.primary_skills);
    const offerSSkills = getSkillsFromJSON(offer.secondary_skills);

    return (
        <ModalV2 isOpen={isOpen}>
            <div className="w-[717px] mx-auto rounded-md pl-[55px] mb-[240px] mt-[235px] pr-[83px] pb-[71px] bg-white">

                <div className="w-full pt-[27px]">
                    <div onClick={onClose} className="ml-auto mr-[-40px] w-fit hover:cursor-pointer ">
                        <IconV2 iconType="CLOSE" />
                    </div>
                </div>

                <div className="mx-auto w-fit mt-[20px]">
                    <span className="font-poppins font-semibold text-[20px] w-fit">Offer</span>
                </div>

                <div className="flex gap-x-[27px]">
                    <UserAvatarLogo user={User} className='w-[65px] h-[65px]' />
                    <div className="flex flex-col">
                        <span className="font-bold font-poppins text-[14px]">{`${User.first_name} ${User.last_name}`}</span>
                        <span className="mt-[8px] font-inter text-[14px]">{freelancer.Role?.name || 'N/A'}</span>
                    </div>
                </div>

                <div className="mt-[41px] flex gap-x-[193px]">
                    <div>
                        <div className="flex flex-col gap-y-[14px]">
                            <span className="font-poppins text-[12px]">Company name</span>
                            <span className="font-poppins font-semibold text-[14px]">{offer.Company.name}</span>
                        </div>
                        <div className="flex flex-col gap-y-[14px] mt-[22px]">
                            <span className="font-poppins text-[12px]">Term</span>
                            <div className="flex gap-x-[17px]">
                                <IconV2 iconType="CLOCK" iconClassName="w-[17px]" />
                                <div className="flex flex-col">
                                    <span className="font-poppins font-semibold text-[12px]">{offer.term}</span>
                                    <span className="font-poppins font-semibold text-[12px]">{offer.timezone}</span>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-[14px] mt-[30px]">
                            <span className="font-poppins text-[12px]">Hours</span>
                            <div className="flex gap-x-[17px]">
                                <IconV2 iconType="CLOCK" iconClassName="w-[17px]" />
                                <span className="font-poppins font-semibold text-[12px]">
                                    {`${offer.term_of_hours} hours ${offer.term_of_hours_duration.toLowerCase()}`}
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-[14px] mt-[34px]">
                            <span className="font-poppins text-[12px]">Location</span>
                            <div className="flex gap-x-[17px]">
                                <IconV2 iconType="LOCATION" iconClassName="w-[17px]" />
                                <span className="font-poppins font-semibold text-[12px]">
                                    {utils.capitalizeFirstLetter(offer.location)}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div>
                        <div className="flex flex-col gap-y-[14px]">
                            <span className="font-poppins text-[12px]">Position</span>
                            <span className="font-poppins font-semibold text-[14px]">{offer.position}</span>
                        </div>
                        <div className="flex flex-col gap-y-[14px] mt-[22px]">
                            <span className="font-poppins text-[12px]">Experience level</span>
                            <div className="flex gap-x-[17px]">
                                <IconV2 iconType="EDUCATION" iconClassName="w-[17px]" />
                                <span className="font-poppins font-semibold text-[12px]">{offer.experience_level}</span>
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-[14px] mt-[30px]">
                            <span className="font-poppins text-[12px]">Hourly rate</span>
                            <div className="flex gap-x-[17px]">
                                <IconV2 iconType="DOLLAR-BLUE-CIRCLE" iconClassName="w-[17px]" />
                                <span className="font-poppins font-semibold text-[12px]">
                                    {`${offer.hourly_rate} /hr ${offer.pay_frequency.toLowerCase()}`}
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col gap-y-[14px] mt-[34px]">
                            <span className="font-poppins text-[12px]">Contract length</span>
                            <div className="flex gap-x-[17px]">
                                <IconV2 iconType="CALENDAR" iconClassName="w-[17px]" />
                                <span className="font-poppins font-semibold text-[12px]">
                                    {`${getLocalDateFromUTCDate(offer.contract_start).date}-${getLocalDateFromUTCDate(offer.contract_end).date}`}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex flex-col mt-[40px]">
                    <span className="font-poppins text-[12px]">Duties</span>
                    <span className="text-[12px] font-nunito ml-[20px]">&#x2022;{" " + offer.duties}</span>
                </div>

                <div className="flex flex-col mt-[25px]">
                    <span className="font-poppins text-[12px]">Skills</span>
                    <div className="mt-[14px] flex gap-x-[11px] flex-wrap">
                        {offerPSkills.map(({ name }) => <BadgeV2 key={name} color="pink" starInBadge >{name}</BadgeV2>)}
                        {offerSSkills.map(({ name }) => <BadgeV2 key={name}>{name}</BadgeV2>)}
                    </div>
                </div>

                {offer.response_due &&
                    <div className="flex flex-col mt-[22px]">
                        <span className="font-poppins text-[12px]">Response due</span>
                        <div className="flex gap-x-[10px]">
                            <IconV2 iconType="CALENDAR" iconClassName="w-[17px]" />
                            <span className="font-poppins font-semibold text-[12px]">{getLocalDateFromUTCDate(offer.response_due).date}</span>
                        </div>
                    </div>
                }

            </div>
        </ModalV2>
    );
}

export default JobOfferModal;