import { useEffect, useMemo, useState } from "react";

import { FreelancerProfile } from "types";
import Loader from "components/V2/Loader/Loader";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";
import StarRating from "react-svg-star-rating";
import ArrowButton from "components/ArrowButton";
import { useNavigate } from "react-router-dom";
import TalentFilter, { getTalentAvailability } from "views/application/talent/components/TalentFilter";
import { JobPosting } from "types/JobPosting";
import usePrototypeUtils from "hooks/usePrototypeUtils";
import CheckBoxV2 from "components/V2/Buttons/CheckBoxV2";
import { averageRating } from "utils/talent/utils";
import Button from "components/Button/ButtonV2";
import SubmitRecommendationModal from "../SubmitRecommendationModal";
import { Company } from "types/Company";
import { toast } from "react-toastify";
import MainTable from "components/V2/MainTable/MainTable";
import SkillsCell from "components/V2/MainTable/SkillsCell";
import TableWrapper from "./TableWrapper";
import profileAPI from "network/profile";

interface IFindTalent {
    job: JobPosting // Job we are matching the talents to
    company: Company // Company owner of the job
}

const FindTalent = ({ job, company }: IFindTalent) => {

    const navigate = useNavigate();
    const { getJobMatchLevel } = usePrototypeUtils();
    const [submitModalOpen, setSubmitModalOpen] = useState<boolean>(false);
    const [talents, setTalents] = useState<FreelancerProfile[] | undefined>();
    const [checkedTalents, setCheckedTalents] = useState<{ [key: number]: FreelancerProfile }>({});
    const [filtered, setFiltered] = useState<FreelancerProfile[]>([]);
    // Memorize the interview ratings of each talent
    const interviewRatings = useMemo(
        () => {
            const ratings = {};
            if (!talents) return ratings;
            talents.forEach(t => ratings[t.freelancer_id] = averageRating(t.User?.AssessmentInfo?.rating))
            return ratings
        }
        , [talents]
    )
    // Memorize the job level match of each talent
    const matchLevels = useMemo(
        () => {
            const levels = {};
            if (!talents || !getJobMatchLevel) return levels;
            talents.forEach(t => levels[t.freelancer_id] = getJobMatchLevel(t, job));
            return levels;
        }
        , [talents])
    // Sort the final result
    const sorted = useMemo(
        () => filtered.sort((t1, t2) => matchLevels[t2.freelancer_id].match - matchLevels[t1.freelancer_id].match)
        , [filtered, matchLevels]);


    const fetchTalents = async () => {
        try {
            setFiltered([]);
            setTalents(undefined);
            const data = await profileAPI.getTalentPaginated({ page_size: 500 });
            console.log(data);
            if (!data?.talents) throw new Error("Error while fetching talent data");
            setTalents(data.talents);
        } catch (e) {
            toast.error("Error while fetching talent data");
            console.error(e);
        }
    }
    useEffect(() => { fetchTalents() }, [job])

    const onCheck = (talent: FreelancerProfile) => {
        const newSet = { ...checkedTalents };
        const checked = !!checkedTalents[talent.freelancer_id];
        if (!checked) newSet[talent.freelancer_id] = talent;
        if (checked) delete newSet[talent.freelancer_id];
        setCheckedTalents(newSet);
    }

    const goToDetailedtTalent = async (email) => {
        navigate(`/talent/details/${email}`);
    };

    if (!talents) return <Loader />

    // Only need to display 50 at most
    const data = sorted.slice(0, 50)

    return (
        <div>
            {/* Filters */}
            <TalentFilter className="pl-[16px] py-[48px] w-[80%]" data={talents} onFilter={setFiltered} />

            {/* Talents */}
            <TableWrapper>
                <MainTable
                    heads={
                        <tr>
                            <th></th>
                            <th>Photo</th>
                            <th>Name</th>
                            <th>Role</th>
                            <th>Experience</th>
                            <th>Compensation</th>
                            <th>Skills</th>
                            <th>Application status</th>
                            <th>Interview rating</th>
                            <th>Job match %</th>
                            <th></th>
                        </tr>
                    }
                    rows={data.map(t =>
                        <tr key={t.freelancer_id}>
                            <td className="w-[60px]">
                                <div className="flex justify-center mx-auto">
                                    <CheckBoxV2
                                        onClick={() => onCheck(t)}
                                        value=""
                                        selected={!!checkedTalents[t.freelancer_id]} />
                                </div>
                            </td>
                            <td><UserAvatarLogo user={t.User} /></td>
                            <td>{t.User?.first_name}</td>
                            <td className="w-[200px]">{t.Role?.name}</td>
                            <td>{t.level_of_experience}</td>
                            <td>{`$${t.hourly_rate}/hour`}</td>
                            <td className="w-[210px]">
                                <SkillsCell skills={t.skills} />
                            </td>
                            <td>{getTalentAvailability(t)}</td>
                            <td>
                                <StarRating
                                    isReadOnly
                                    unit="float"
                                    innerRadius={30}
                                    initialRating={interviewRatings[t.freelancer_id]}
                                    containerClassName="flex justify-center mt-[18px]"
                                />
                            </td>
                            <td>{matchLevels[t.freelancer_id].string}</td>
                            <td>
                                <div className="flex w-[80px] justify-center items-center">
                                    <ArrowButton
                                        direction="NEXT"
                                        onClick={() => goToDetailedtTalent(t.User.email_id)}
                                    />
                                </div>
                            </td>
                        </tr>
                    )}
                />
            </TableWrapper>

            {/* Submit Modal */}
            <SubmitRecommendationModal
                company={company}
                recommendatedTalent={Object.values(checkedTalents)}
                job={job}
                onClose={() => setSubmitModalOpen(false)}
                isOpen={submitModalOpen}
            />

            <div className="w-full flex justify-center">
                <Button onClick={() => setSubmitModalOpen(true)}
                    disabled={!Object.keys(checkedTalents).length}
                    className="font-poppings font-semibold text-[14px] mx-auto my-[24px]">
                    Recommend talent
                </Button>
            </div>


        </div>
    );
}

export default FindTalent;