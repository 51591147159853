import { useForm } from "react-hook-form";

import Button from "components/Button/ButtonV2";
import { useAppSelector } from "redux/hooks/redux-hooks";
import { AssessmentInfo, FreelancerProfile } from "types";
import IconV2 from "components/V2/Icons/IconV2";
import { FormSkillInputV2 } from "components/V2/SkillInput/SkillInput";
import FormStarRating from "components/V2/StarRating/FormStarRating";
import { FormTextareaV2 } from "components/V2/Textarea";
import Timeline from "components/V2/Timeline/Timeline";
import PassFailButtons from "../../../../PassFailButtons";
import ENUMS from "constants/enums";


interface ICodingChallenge {
    assessment: AssessmentInfo,
}

function getDefaultValues(assessment: AssessmentInfo) {
    return {
        rating: (JSON.parse(assessment.rating || "{}")).codingSkillsRating,
        notes: (JSON.parse(assessment.notes_taken || "{}")).codingSkillsNotes,
        skills: JSON.parse(assessment.coding_skills || '[]')
    }
}

const CodingChallenge = ({ assessment }: ICodingChallenge) => {
    const skills = useAppSelector((root) => root.prototype.skills);
    const { control } = useForm({
        defaultValues: getDefaultValues(assessment)
    });
    const atLeastOne = (arr) => !!arr.length;
    const { coding_status } = assessment;

    let timeline;
    switch (coding_status) {
        case "TODO":
            timeline = 0;
            break;
        case "SUBMITTED":
            timeline = 1;
            break;
        case "FAILED":
            timeline = 2;
            break;
        case "PASSED":
            timeline = 2;
            break;
    }

    return (
        <div className="flex flex-col" >

            {/* Header */}
            <div className="font-poppins text-[20px] font-semibold border-b pb-1 border-inactive-gray flex flex-row items-end justify-between">
                Coding Challenge Assessment (Engineers only)
                {ENUMS.hackerearth_assessment_type[0] === assessment.confirmed_type &&
                    <PassFailButtons status={coding_status} />
                }

            </div>

            {/* Introduction section */}
            <span className="font-inter text-[12px] mt-[12px]">
                For engineering talent you will be able to see their Coding challenge score by using the pdf below.
            </span>
            <Timeline
                current={timeline}
                className="mt-[20px]"
                steps={["Not started coding challenge", "In-progress", "Finished/Dropped off"]}
            />

            {/* Form inputs */}
            <div className="w-[300px] mt-[30px]">
                <span className="font-poppins font-semibold text-[16px]">Coding skills assessed</span>
                <FormSkillInputV2
                    icon={<IconV2 iconType="CODE" iconClassName="w-[20px]" />}
                    name="skills"
                    control={control}
                    rules={{ validate: atLeastOne }}
                    skillData={skills}
                    isPrimary={false}
                />
            </div>

            <span className="font-poppins text-[16px] font-semibold mt-[26px]">
                Rating
            </span>
            <span className="font-inter text-[12px] mt-[10px]">
                Rate your coding challenge using our 1-5 star system, 1 being strong NO hire
                and 5 being a strong hire.
            </span>
            <div className="mt-[10px]">
                <FormStarRating control={control} isReadOnly name="rating" />
            </div>

            <span className="font-poppins text-[12px] font-semibold mt-[13px]">Add notes</span>
            <FormTextareaV2
                control={control}
                name={"notes"}
                disabled
                className="mt-[18px] text-[12px] h-[108px]"
                limitText="200 words max"
                placeholder="Write down some notes"
            />

            <div className="w-full flex justify-end">
                <Button
                    className="px-4"
                    type="submit"
                    variant="secondary"
                    disabled
                >
                    Save changes
                </Button>
            </div>

        </div>
    );
}

export default CodingChallenge;