import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import Loader from "components/V2/Loader/Loader";
import BadgeV2 from "components/V2/Badges/BadgeV2";
import IconV2 from "components/V2/Icons/IconV2";
import TabV2 from "components/V2/Tab/TabV2";
import Button from "components/Button/ButtonV2";
import InputV2 from "components/V2/Input/InputV2";
import UserAvatarLogo from "views/application/talent/components/UserAvatarLogo";
import utils from "utils/utils";
import CompanyLogo from "views/application/company/components/CompanyLogo";
import { useAppSelector } from "redux/hooks/redux-hooks";
import ConfirmButton from "../components/ConfirmButton";
import PagesBar from "components/V2/Pagination/PagesBar";
import { Invoice } from "types";

export const PAGE_SIZE = 2;

const InvoiceOverview = () => {
  const [tab, setTab] = useState<number>(0);
  const [query, setQuery] = useState<string>("");
  const [items, setItems] = useState<Invoice[]>([]);
  const { isLoading, invoices } = useAppSelector((state) => state.invoice);
  const data = useMemo(() => {
    if (utils.isDataEmpty(invoices)) return [];
    let arr: any = [...invoices];
    let key = tab === 0 ? "received" : tab === 1 ? "paid" : "overdue";

    if (query) {
      const search = query.toLowerCase();
      arr = invoices.filter((invoice) => {
        const user = invoice?.Contract.FreelancerProfile.User;
        const companyName = invoice?.Contract.Offer.JobPosting.Company.name || "";
        const job_title = invoice?.Contract?.position || "";
        const username = user.first_name + " " + user.last_name;
        const role = invoice?.Contract.FreelancerProfile?.Role?.name || "";
        return (
          companyName.toLowerCase().includes(search) ||
          job_title.toLowerCase().includes(search) ||
          username.toLowerCase().includes(search) ||
          role.toLowerCase().includes(search)
        );
      });
    }
    arr = arr.filter((invoice) => {
      const diffDays = invoice?.due_days!;
      let payment_status = "paid";
      if (invoice?.date_processed) {
        payment_status = "paid";
      } else if (diffDays < 0) {
        payment_status = "overdue";
      } else {
        payment_status = "received";
      }
      return payment_status === key;
    });
    return arr;
  }, [invoices, query, tab]);
  const fetchPage = useCallback(
    async (offset: number) => {
      return data.slice(offset, offset + PAGE_SIZE);
    },
    [data],
  );
  const isEmpty = utils.isDataEmpty(data);

  if (isLoading) return <Loader />;
  return (
    <main
      data-cy="invoice"
      className="w-full min-h-screen max-h-screen overflow-x-hidden overflow-y-scroll pt-[50px] px-[50px] pb-8"
    >
      <div className="flex flex-col w-full mx-auto max-w-[1226px]">
        <h3 className="font-poppins font-bold text-2xl mb-5">
          {isEmpty ? `No invoice` : `${data.length} Invoices total`}
        </h3>
        <TabV2
          addClass="!bg-transparent !p-[1px] mb-5"
          tabClass="px-4 py-2 font-poppins font-semibold text-[16px] rounded-none mr-[20px] bg-[#D9D9FF] !rounded-3xl !text-white "
          activeTabClass="!bg-[#5F5FFF]"
          inactiveTabClass="hover:text-white hover:bg-[#D9D9FF]"
          tabs={["Received invoices", "Paid invoices", "Overdue invoices"]}
          contents={[]}
          onTabChange={setTab}
          initIndex={tab}
        />

        <section className="p-[10px]">
          <div className="mb-5">
            <InputV2
              className="max-w-[540px]"
              placeholder="Search"
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              icon={<IconV2 iconType="SEARCH" iconClassName="w-6 h-6 translate-y-[1px]" />}
            />
          </div>
          <table className="w-full">
            <thead className="border-b border-b-[#A9ACB1]">
              <tr>
                {["Company", "Talent", "Invoices", "Job type", "Amount", "Actions"].map((item) => (
                  <th
                    key={item}
                    className="p-5 text-[#8A8A8A] font-poppins text-sm font-semibold text-start"
                  >
                    {item}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {isEmpty ? (
                <tr className="p-5">
                  <td className="py-2" colSpan={5}>
                    No data...
                  </td>
                </tr>
              ) : (
                items.map((invoice, idx) => {
                  const user = invoice?.Contract.FreelancerProfile.User;
                  const profile = invoice?.Contract.FreelancerProfile;
                  const job_title = invoice?.Contract?.position!;
                  const offer = invoice?.Contract?.Offer;
                  const company = invoice?.Contract.Offer.JobPosting.Company;
                  const date_start = new Date(offer?.contract_start).toLocaleDateString();
                  const date_end = new Date(offer?.contract_end).toLocaleDateString();
                  const contract_period = `${date_start} - ${date_end}`;
                  const date_issued = moment(invoice?.date_issued).utc().format("MM/DD/YYYY");
                  const date_processed = moment(invoice?.date_processed).utc().format("MM/DD/YYYY");
                  const createdAt = moment(invoice?.createdAt).utc().format("MM/DD/YYYY");
                  const diffDays = invoice?.due_days!;
                  const payment_status = invoice?.date_processed
                    ? "paid"
                    : diffDays < 0
                    ? "overdue"
                    : "received";
                  const date_overDue = moment().add(diffDays, "days").utc().format("MM/DD/YYYY");
                  return (
                    <tr key={idx} className="align-top border-b border-b-[#A9ACB1]">
                      <td className="text-start p-5">
                        <div className="flex gap-x-3 items-center">
                          <CompanyLogo company={company} className="mx-0" />
                          <div className="flex flex-col gap-y-3">
                            <span className="font-poppins font-semibold text-sm text-black">
                              {company?.name}
                            </span>
                            <span className="font-inter font-semibold text-sm text-[#8A8A8A]">
                              {company?.industry}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-start p-5">
                        <div className="flex gap-x-3 items-center">
                          <UserAvatarLogo user={user} className="mx-0" />
                          <div className="flex flex-col gap-y-3">
                            <span className="font-poppins font-semibold text-sm text-black">
                              {user?.first_name} {user?.last_name}
                            </span>
                            <span className="font-inter font-semibold text-sm text-black">
                              {profile?.Role?.name}
                            </span>
                          </div>
                        </div>
                      </td>
                      <td className="text-start p-5">
                        <div className="flex flex-col gap-y-3">
                          <p className="font-inter font-semibold text-sm text-[#5F5FFF]">
                            {contract_period}
                          </p>
                          <p className="font-inter font-semibold text-sm">{invoice?.slug}</p>
                          <p className="font-inter font-semibold text-sm">
                            Date issued {date_issued}
                          </p>
                          <p className="font-inter font-semibold text-sm">
                            {diffDays < 0 && "Over due"}
                            {diffDays === 0 && "Today"}
                            {diffDays > 0 && `Due in ${diffDays} days`}
                          </p>
                        </div>
                      </td>
                      <td className="text-start p-5">
                        <span className="font-inter font-semibold text-sm text-black">
                          {job_title}
                        </span>
                      </td>
                      <td className="text-start p-5">
                        <p className="font-inter font-semibold text-sm mb-3">
                          $
                          {invoice.total_amount.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </p>
                        <BadgeV2
                          addClass={`px-3 py-2 font-inter font-semibold !text-sm ${
                            payment_status === "received" && "!bg-[#D9D9FF] !text-[#5F5FFF]"
                          }
                            ${payment_status === "overdue" && "!bg-[#FFD1D1] !text-[#D32F2F]"}
                            ${payment_status === "paid" && "!bg-[#26993f26] !text-[#26993F]"}
                          }`}
                        >
                          {payment_status === "received" && `Received ${createdAt}`}
                          {payment_status === "paid" && `Paid ${date_processed}`}
                          {payment_status === "overdue" && `Overdue ${date_overDue}`}
                        </BadgeV2>
                      </td>
                      <td className="text-start">
                        <div className="flex flex-col gap-y-3">
                          <div className="flex gap-x-3 items-center cursor-pointer">
                            <IconV2
                              iconType="RECEIVE-SQUARE"
                              iconClassName="w-[24px] h-[24px]"
                              fill={"#5F5FFF"}
                            />
                            <span className="font-inter font-semibold text-sm">Download</span>
                          </div>
                          {payment_status === "paid" && (
                            <span className="font-inter font-semibold text-sm text-[#26993F]">
                              Status: Paid
                            </span>
                          )}
                          <Link
                            to={`/invoice/details/${invoice.id}`}
                            className="flex gap-x-3 items-center cursor-pointer"
                          >
                            <span className="font-inter font-semibold text-sm text-white p-3 bg-[#2F3454] rounded-xl whitespace-normal">
                              View Invoice
                            </span>
                          </Link>
                          {payment_status === "overdue" && (
                            <Button className="font-inter font-semibold text-sm text-white !py-3 !px-5 bg-[#FF5757] rounded-xl w-fit">
                              Send email
                            </Button>
                          )}
                          {(payment_status === "received" || payment_status === "overdue") && (
                            <ConfirmButton id={invoice.id.toString()} />
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </table>
          <div className="flex flex-col items-center w-full">
            <PagesBar
              page_size={PAGE_SIZE}
              className="mt-[8px]"
              count={data.length}
              fetchPage={fetchPage}
              onLoading={() => {}}
              onPageChange={(data) => setItems(data)}
            />
          </div>
        </section>
      </div>
    </main>
  );
};

export default InvoiceOverview;
