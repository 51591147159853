import ReactDOM from 'react-dom';

const ModalV2 = (props: ModalType) => {
  if (!props.isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className='fixed top-0 left-[83px] 2xl:left-[133px] w-[calc(100%-83px)] 2xl:w-[calc(100%-133px)] h-screen bg-[rgba(0,0,0,0.4)] backdrop-blur-[2px] z-10 overflow-y-auto'
      onClick={() => props.onClose && props.onClose()}
    >
      {props.children}
    </div>,
    document.body
  );
};

interface ModalType {
  isOpen: boolean;
  onClose?: Function;
  children?: any;
}

export default ModalV2;
