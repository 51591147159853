import MainTabs from "components/V2/MainTabs/MainTabs";
import { FreelancerProfile } from "types";
import AssessmentsTab from "./Tabs/Assessments/index.tab";
import BehavioralInterview from "./Tabs/BehavioralInterview/index.tab";
import VetDecline from "./Tabs/VetDecline/index.tab";

interface IAssesments {
    talent: FreelancerProfile;
}

const Assesments = ({ talent }: IAssesments) => {
    if (!talent.User.AssessmentInfo) return <p className="p-10 text-center w-full font-poppins text-3xl">Not available</p>
    return (
        <MainTabs
            tabNavigations={["assessments", "behavioral interview", "vet/Decline"]}
            tabs={["Assessments", "Behavioral interview", "Vet/Decline"]}
            contents={[
                <AssessmentsTab talent={talent} />,
                <BehavioralInterview talent={talent} />,
                <VetDecline talent={talent} />]}
        />
    );
}

export default Assesments;