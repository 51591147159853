import { IReputation } from 'types/Reputation';
import { ReputationTag } from './types';
import { IReputationFilters } from './../../types/Filters/IReputationFilters';
import { getQueryFromObject } from 'network/utils';
import { API } from 'aws-amplify';
import awsUtils from 'utils/awsUtils';

const apiName = awsUtils.getAPIName();

const getAll = async (tag: ReputationTag, filters: IReputationFilters = {}) => {
    const authHeader = await awsUtils.getAuthHeader();
    const query = getQueryFromObject(filters)
    const path = `/reputation_${tag}/paginated?${query}`;
    const myInit = { headers: { ...authHeader, }, };

    const result = await API.get(apiName, path, myInit);
    return result;
};

const getOne = async (id: number | string, tag: ReputationTag,): Promise<IReputation> => {
    const resp = await getAll(tag, { id });
    if (!resp.data[0]) throw new Error("Reputation not found by id");
    return resp.data[0]
}

const approve = async (id: number | string, tag: ReputationTag,): Promise<IReputation> => {
    const authHeader = await awsUtils.getAuthHeader();
    const path = `/reputation_${tag}/approve`;
    const myInit = {
        headers: { ...authHeader, },
        body: JSON.stringify({ id }),
    };
    const result = await API.put(apiName, path, myInit);
    return result;
}

const reject = async (id: number | string, tag: ReputationTag, notes: string): Promise<IReputation> => {
    const authHeader = await awsUtils.getAuthHeader();
    const path = `/reputation_${tag}/reject`;
    const myInit = {
        headers: { ...authHeader, },
        body: JSON.stringify({ id, notes }),
    };
    const result = await API.put(apiName, path, myInit);
    return result;
}


/**
 * 
 * @param tag 
 * @param id reputation id
 * @param assignee_id 
 * @returns 
 */
const assign = async (tag: ReputationTag, id: number, assignee_id: number) => {
    const authHeader = await awsUtils.getAuthHeader();
    const path = `/reputation_${tag}/assign`;
    const myInit = {
        headers: { ...authHeader, },
        body: JSON.stringify({ id, assignee_id }),
    };

    const result = await API.put(apiName, path, myInit);
    return result;
};


const metrics = async () => {
    const authHeader = await awsUtils.getAuthHeader();
    const path = `/reputation/metrics`;
    const myInit = { headers: { ...authHeader, }, };

    const result = await API.get(apiName, path, myInit);
    return result;
}

const ReputationApi = { getAll, assign, getOne, approve, reject, metrics };

export default ReputationApi;