import InputV2 from 'components/V2/Input/InputV2';
import { ChangeEvent, ReactElement, useEffect, useState } from 'react';

interface PropsType {
  onChange: (val: string) => void;
  value: string;
  placeholder?: string;
}

export default function DebouncedInput(props: PropsType): ReactElement {
  const [key, setKey] = useState<string>(props.value);

  const keyChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setKey(e.target.value);
  };

  useEffect(() => {
    const timerID = setTimeout(() => {
      props.onChange(key);
    }, 1500);

    return () => {
      clearTimeout(timerID);
    };
  }, [key]);

  return (
    <InputV2
      placeholder={props.placeholder}
      value={key}
      onChange={keyChangeHandler}
    />
  );
}
