import * as React from "react";
import { ColorRing } from "react-loader-spinner";

interface ILoader {
    className?: string;
}

const Loader = ({ className = "" }: ILoader) => {
    return (
        <div className={`h-full w-full flex flex-row justify-center items-center ${className}`}>
            <ColorRing
                visible={true}
                ariaLabel='blocks-loading'
                colors={["#FF8134", "#FF5757", "#5F5FFF", "#2F3454", "transparent"]}
            />
        </div>);
};

export default Loader;
