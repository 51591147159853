import { BannedTalentState } from './../types/banned_talent';
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import permissionsFreelancerAPI from "network/permissions/freelancer";
import { FreelancerProfile } from 'types';
import { RootState } from 'app/store';

const initialState: BannedTalentState = {
    from_community: undefined
};

export const getTalentBannedFromCommunity = createAsyncThunk(
  "getTalentBannedFromCommunity",
  async () => {
    const talents = await permissionsFreelancerAPI.getAllCommnutyBannedTalent();
    if(!talents) throw new Error("Error while fetching banned from community talent")
    return talents;
  }
);

export const banFromCommnunity = createAsyncThunk(
    "banFromCommnunity",
    async (profile: FreelancerProfile, api) => {
        const {freelancer_id} = profile;
        const { from_community } = (await api.getState() as RootState).banned_talent;
        const unban = from_community && !!from_community[freelancer_id];
        const response = await permissionsFreelancerAPI.communityBan(freelancer_id, unban);
        if(!response.data.success){
            console.error(response);
            throw new Error("Error while handling community banning of: " + freelancer_id);
        }
        return {profile, unban};
    }
);

export const BannedTalentSlice = createSlice({
  name: "banned_talent",
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder.addCase(getTalentBannedFromCommunity.pending, (state, action) => {
        return state;
    });
    builder.addCase(getTalentBannedFromCommunity.fulfilled, (state, action) => {
        const talents = action.payload;
        const from_community = {};
        talents.forEach(t => from_community[t.freelancer_id] = t);
        state = {...state, from_community};
        return state;
    });
    builder.addCase(getTalentBannedFromCommunity.rejected, (state, action) => {
        console.error("Error while fetching talent banned from community")
        return state;
    });

    builder.addCase(banFromCommnunity.pending, (state, action) => {
        return state;
    });
    builder.addCase(banFromCommnunity.fulfilled, (state, action) => {
        const {unban, profile} = action.payload;
        let from_community = {...state.from_community};
        if(unban) delete from_community[profile.freelancer_id]
        else from_community[profile.freelancer_id] = {...profile}
        return {...state, from_community};
    });
    builder.addCase(banFromCommnunity.rejected, (state, action) => {
        console.error("Error while handling community ban of talent")
        return state;
    });
  },
});

export default BannedTalentSlice.reducer;
