import { ReactElement, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { validateInitIndex } from './validation';

const defaultActiveClass = "bg-white text-[#000000] "
const defaultInactiveClass = "hover:bg-white text-[#8A8A8A] hover:text-[#000000] "

const TabV2: React.FC<ITabV2> = ({
  addClass = "",
  tabs,
  tabClass = "",
  activeTabClass = "",
  inactiveTabClass = "",
  contents,
  contentWrapperClass,
  wrapperAsFragment,
  initIndex,
  tabNavigations,
  onTabChange
}): ReactElement => {
  const [_, setSearchParam] = useSearchParams();

  const [index, setIndex] = useState<number>(
    validateInitIndex(initIndex, tabs, contents)
  );

  const tabClickHandler = (_index: number) => {
    if (tabNavigations !== undefined && tabNavigations[_index]) {
      setSearchParam(`tab=${tabNavigations[_index]}`);
    }
    setIndex(_index);
    onTabChange && onTabChange(_index);
  };

  return (
    <>
      <div className={`flex gap-1 w-fit p-1 bg-[#D9D9D9] rounded-lg ${addClass}`}>
        {tabs.map((elem, i) => (
          <span
            onClick={() => tabClickHandler(i)}
            className={`flex items-center justify-center rounded-lg cursor-pointer transition-all duration-150 ${tabClass}
             ${index === i ? defaultActiveClass + activeTabClass : defaultInactiveClass + inactiveTabClass} `}
            key={elem + i}
          >
            {elem}
          </span>
        ))}
      </div>
      {wrapperAsFragment ? (
        contents[index]
      ) : (
        <div className={contentWrapperClass ?? ''}>{contents[index]}</div>
      )}
    </>
  );
};

export default TabV2;
