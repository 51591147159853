import { useState } from "react";
import { useForm } from "react-hook-form";

import Button from "components/Button/ButtonV2";
import { AssessmentInfo, FreelancerProfile } from "types";
import IconV2 from "components/V2/Icons/IconV2";
import FormStarRating from "components/V2/StarRating/FormStarRating";
import { FormTextareaV2 } from "components/V2/Textarea";
import { FormInputV2 } from "components/V2/Input/FormInputV2";
import utils from "utils/utils";
import PassFailButtons from "../../../../PassFailButtons";

const questions = [
    "Please tell us about yourself.",
    "Please share what skills or roles interest you.",
    "Please share your biggest professional achievement to date.",
];
interface IOnlineInterview {
    assessment: AssessmentInfo,
}

function getDefaultValues(assessment: AssessmentInfo) {
    return {
        rating: (JSON.parse(assessment.rating || '{}')).englishProficiencyRating,
        notes: (JSON.parse(assessment.notes_taken || '{}')).englishProficiencyNotes,
        introductionUrl: assessment.introduction_url || "",
    }
}

const OnlineInterview = ({ assessment }: IOnlineInterview) => {

    const [video, setVideo] = useState<number>(1);
    const { willo_introduction_answer_url_1, introduction_status } = assessment;

    const { control } = useForm({
        defaultValues: getDefaultValues(assessment)
    });

    return (
        <div className="flex flex-col" >
            <Button
                className="w-fit mb-[65px]"
                variant="secondary"
            >
                Send email remider
            </Button>

            {/* Header */}
            <div className="font-poppins text-[20px] font-semibold border-b pb-1 border-inactive-gray flex flex-row items-end justify-between">
                Online interview
                <PassFailButtons status={introduction_status} />
            </div>

            {/* Introudction section */}
            <span className="font-poppins text-[16px] font-semibold mt-[11px]">
                Introduce yourself
            </span>
            <span className="font-inter text-[12px] mt-[2px]">
                Use the link below to review this talent’s “Introduce yourself”
                submission
            </span>

            {willo_introduction_answer_url_1 &&
                <div className="mt-[40px] rounded-lg p-[20px] w-[670px] h-[635px] bg-placeholder">
                    <span className="font-inter font-semibold text-[20px]">{video}. {questions[video - 1]}</span>
                    <video key={video} className="w-full h-[370px]" controls >
                        <source src={assessment["willo_introduction_answer_url_" + video]} type="video/mp4" />
                    </video>

                    <div className="w-full flex gap-[20px] mt-[20px]">
                        {
                            [1, 2, 3].map(i =>
                                <div key={i} onClick={() => setVideo(i)} className="w-1/3  whitespace-nowrap text-ellipsis overflow-hidden cursor-pointer ">
                                    <span className="font-inter font-semibold text-[16px]  ">
                                        {i}. {questions[i - 1]}
                                    </span>
                                    <video className="w-full h-[120px]" >
                                        <source src={assessment["willo_introduction_answer_url_" + video]} type="video/mp4" />
                                    </video>
                                </div>)
                        }
                    </div>
                </div>

            }

            {/* Video section */}
            <span className="font-poppins font-semibold text-[14px] mt-[22px] mb-[16px]">
                Manually add Willo video link if video is not shown above
            </span>
            <FormInputV2
                name="introductionUrl"
                className="w-[290px]"
                control={control}
                rules={{ validate: (url) => !url || utils.urlRegex.test(url) }}
                placeholder="Add myinterview video link"
                icon={<IconV2 iconType="COPY" />}
            />

            {/* English form section */}
            <span className="font-poppins text-[16px] font-semibold mt-[26px]">
                English and Presentation
            </span>
            <span className="font-inter text-[12px] mt-[10px]">
                Rate your interview using our 1-5 star system, 1 being strong NO hire
                and 5 being a strong hire.
            </span>
            <div className="mt-[10px]">
                <FormStarRating control={control} isReadOnly name="rating" />
            </div>
            <span className="font-poppins text-[12px] font-semibold mt-[13px]">Add notes</span>
            <FormTextareaV2
                control={control}
                name={"notes"}
                disabled
                className="mt-[18px] text-[12px] h-[108px]"
                limitText="200 words max"
                placeholder="Write down some notes"
            />

            <div className="w-full flex justify-end">
                <Button
                    className="px-4"
                    type="submit"
                    variant="secondary"
                    disabled
                >
                    Save changes
                </Button>
            </div>

        </div>
    );
}

export default OnlineInterview;