import IconV2 from "components/V2/Icons/IconV2";
import { useMemo, useState } from "react";

export interface IUploadResume {
    buttonText?: string;
    accept?: string; // valid extensions
    placeholder?: string;
    onChange?: (file: File) => void;
    id: string;
};

const UploadResume = ({ buttonText = "", accept, onChange = () => null, id, placeholder = "Select a file" }: IUploadResume) => {

    const [file, setFile] = useState<File>();

    const handleChange = (e) => {
        if (!e?.target?.files?.length) return;
        const file = e.target.files[0];
        setFile(file);
        onChange(file);
    }

    return (
        <div className="w-full">
            <div className="flex gap-x-[21px] px-[18px] py-[15px] h-[48px] bg-lighter-gray rounded-lg border-solid border-odf border-[1px]" >
                <IconV2 iconType="PORTFOLIO" />
                <span className="font-inter font-semibold text-[12px]">{file ? file.name : placeholder}</span>
            </div>

            <label htmlFor={id}>
                <div className="flex gap-x-[8px] px-[13px] py-[10px] mt-[21px] w-[173px] h-[40px] bg-white rounded-lg  border-solid border-odf border-[1px] hover:cursor-pointer">
                    <IconV2 iconType="UPLOAD" />
                    <span className="font-poppins font-semibold text-[14px]">{buttonText}</span>
                </div>
            </label>

            <input
                type="file"
                onChange={handleChange}
                accept={accept}
                hidden
                id={id}
            />
        </div>
    );
}

export default UploadResume;