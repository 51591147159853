import { Routes, Route } from 'react-router-dom'
import Management from 'views/application/management'

const routes = [
    {
        path: "/",
        element: <Management />,
    }
]

const ManagementRoutes: React.FC = () => {
    return (
        <Routes>
            {routes.map((route, index) => (
                <Route key={index} {...route} />
            ))}
        </Routes>
    )
}

export default ManagementRoutes
