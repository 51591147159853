import {
  ReactElement,
  useEffect,
  useState,
} from "react";
import InputV2 from "../Input/InputV2";
import utils from "utils/utils";

const PhoneInput = ({
  prefix = "",
  ...props
}: IPhoneInput): ReactElement => {
  const [savedPrefix, setSavedPrefix] = useState<string>(prefix);
  const value = props?.value?.toString() || "";

  const handleChange = (phone) => {
    const isNumeric = utils.numericRegex.test(phone);
    if (!phone || isNumeric) props?.onChange?.(phone);
  };

  // Handle prefix Changes
  useEffect(() => {
    if (!prefix) return;
    const oldPrefix = savedPrefix;
    const newPhone = value.replace(oldPrefix, prefix);
    handleChange(newPhone);
    setSavedPrefix(prefix);
  }, [prefix]);

  // Check if someone tries to delete the prefix
  useEffect(() => {
    if (!prefix) return;
    const deletedPrefix = !value.startsWith(prefix);
    if (deletedPrefix) handleChange(prefix + value.slice(prefix.length - 1));
  }, [value]);

  return (
    <InputV2
      placeholder="Ex.4567891"
      {...props} value={value}
      onChange={(e) => handleChange(e.target.value)} />
  );
};

export default PhoneInput;
