import BadgeV2 from "components/V2/Badges/BadgeV2";
import Loader from "components/V2/Loader/Loader";
import moment from "moment";
import jobAPI from "network/job";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FreelancerProfileWithRewards } from "types/FreelancerProfileWithRewards";
import { JobPosting } from "types/JobPosting";
import utils from "utils/utils";

interface IAppliedJobs {
    talent: FreelancerProfileWithRewards;
}

const AppliedJobs = ({ talent }: IAppliedJobs) => {
    const { freelancer_id } = talent;
    const [data, setData] = useState<JobPosting[]>();

    // Fetch all the applications to this job
    const fetchAppliedJobs = async () => {
        try {
            const response = await jobAPI.getJobApplications({ freelancer_id });
            const { applications } = response;
            if (!applications) throw new Error(JSON.stringify(response));
            setData(applications.map(app => app.PostedJobs));
        } catch (e) {
            toast.error("Error while retrieving applications data");
            console.error(e);
        }
    };

    useEffect(() => { fetchAppliedJobs() }, []);

    if (!data) return <Loader />

    return (
        <div className="py-[84px] pl-[68px] pr-[100px]">

            <table className="w-full mt-[20px] border-separate border-spacing-y-[12px] bg-[#F0F1F2] p-[8px_20px_8px_20px]">
                <thead>
                    <tr className="bg-white font-poppins text-[14px] font-bold [&>*]:py-[25px] ">
                        <th className="rounded-tl-lg rounded-bl-lg py-[6px]">Role</th>
                        <th>Company</th>
                        <th>Date</th>
                        <th>Headquarters</th>
                        <th>Seniority level</th>
                        <th>Work location</th>
                        <th className="rounded-br-lg rounded-tr-lg">Skills needed</th>
                    </tr>
                </thead>
                <tbody className="font-inter text-[14px] font-semibold">
                    {
                        data?.map(j => (
                            <tr key={j.id} className="[&>*]:py-[22px] bg-white">
                                <td className="rounded-tl-lg rounded-bl-lg ">{j.job_title}</td>
                                <td> {j.Company.name} </td>
                                <td>{moment(j.createdAt).format("MM/DD/YYYY")}</td>
                                <td>{j.Company.city}</td>
                                <td>{j.experience_level}</td>
                                <td>{utils.capitalizeFirstLetter(j.location || "")}</td>
                                <td className="!w-[250px] rounded-tr-lg rounded-br-lg" >
                                    <div className="flex justify-center flex-wrap gap-[8px]">
                                        {j.Skills?.map(s =>
                                            <BadgeV2 addClass="h-fit" key={s.name} color="pink" starInBadge>{s.name}</BadgeV2>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))
                    }

                </tbody>
            </table>
        </div>
    );
};

export default AppliedJobs;
