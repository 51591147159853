import { debounce } from "lodash";
import { useMemo } from "react";

function useDebounce(f: Function, time = 1000) {
  const global: any = useMemo(() => ({}), []);
  global.f = f;

  const debouncedF = useMemo(() => debounce((...args) => global.f(...args), time), []);

  return debouncedF;
}

export default useDebounce;