import { AppDispatch, RootState } from 'app/store';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import prototypeAPI from 'network/prototype';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { getProtoTypes } from 'redux/slices/prototype';

type TValid = 'VALID' | 'WARNING' | 'INVALID';

interface IValidation {
  valid: TValid;
  message: string;
  reference: string;
}

const stringSimilarity = require('string-similarity');

export default function useAddSkillModal(setOpen: (vis: boolean) => void) {
  const dispatch = useDispatch<AppDispatch>();

  const data = useSelector((root: RootState) => root.prototype.roles);

  const [type, setType] = useState<any>();
  const [skillName, setSkillName] = useState<string>('');
  const [validation, setValidation] = useState<IValidation>({
    message: '',
    valid: 'INVALID',
    reference: '',
  });
  const [pending, setPending] = useState<boolean>(false);

  const validateskillName = useCallback((): IValidation => {
    if (!skillName || !type)
      return { valid: 'INVALID', message: 'NON INPUT', reference: '' };

    for (let i = 0; i < data.length; i++) {
      for (let j = 0; j < data[i].skills.length; j++) {
        const dbSkill = data[i].skills[j].name.toLowerCase();
        const skillNameInput = skillName.toLowerCase();
        // Exactly the same
        if (dbSkill === skillNameInput) {
          return {
            valid: 'INVALID',
            message: 'Skill already exists, please add a new skill : ',
            reference: dbSkill,
          };
        }

        // The same without whitespace
        else if (
          dbSkill.replaceAll(' ', '') === skillNameInput.replaceAll(' ', '')
        ) {
          return {
            valid: 'INVALID',
            message:
              'Seems like a skill already exists, please check spaces in the skills : ',
            reference: data[i].skills[j].name,
          };
        } else if (
          stringSimilarity.compareTwoStrings(dbSkill, skillNameInput) > 0.7
        ) {
          return {
            valid: 'WARNING',
            message: 'Similar one exists : ',
            reference: `"${data[i].skills[j].name}" in ${data[i].name}`,
          };
        }
      }
    }

    return { valid: 'VALID', message: '', reference: '' };
  }, [skillName, type]);

  useEffect(() => {
    setValidation({
      message: '',
      valid: 'INVALID',
      reference: '',
    });

    const timerID = setTimeout(() => {
      const result = validateskillName();
      setValidation(result);
    }, 1500);

    return () => {
      clearTimeout(timerID);
    };
  }, [skillName, type]);

  const addSkillHandler = async () => {
    const role_id = data.find((elem) => elem.name === type)?.id;
    if (!role_id || validation.valid === 'INVALID') {
      console.log('no role id');
      console.log(type);
      return;
    }

    setPending(true);
    if (
      window.confirm(
        validation.valid === 'VALID'
          ? 'Please check if spelling is correct or we have duplicated one in our database. EX: React.js vs ReactJS'
          : 'Seems like we already have similar one in our database, do you still want to create?'
      )
    ) {
      try {
        const response = await prototypeAPI.createSkill({
          role_id,
          name: skillName,
        });
        if (response.success === true) {
          toast.success('Success: A new skill added.');
        } else {
          throw new Error('Error from backend');
        }
      } catch (e) {
        toast.error('There was an error while adding a new skill.');
      }
    }

    await dispatch(getProtoTypes());
    setPending(false);

    closeHandler();
  };

  const closeHandler = () => {
    setType('');
    setValidation({
      message: '',
      valid: 'INVALID',
      reference: '',
    });
    setSkillName('');
    setOpen(false);
  };

  return {
    pending,
    data,
    type,
    skillName,
    validation,
    setType,
    setSkillName,
    addSkillHandler,
    closeHandler,
  };
}
