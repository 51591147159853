import { useEffect, useState, useMemo } from "react";
import { ethers, BigNumber } from "ethers";
import { toast } from "react-toastify";
import { SDK, getSdk } from "utils/sdkUtils";
import { Stats } from "views/application/dashboard/types";
import { SAFE_ADDRESS, rpcNodeUrl } from "helpers/env";

const getProvider = () => {
  const provider = new ethers.providers.JsonRpcProvider(rpcNodeUrl);
  return provider;
};

interface ContractsData {
  rewardsLeft: Stats;
  totalSupply: Stats;
  maxSupply: Stats;
  safeBalance: Stats;
  totalWithdrawals: Stats;
}

const contractsDataKeys = [
  "rewardsLeft",
  "totalSupply",
  "maxSupply",
  "safeBalance",
  "totalWithdrawals",
];

const emptyStat = (count: number): Stats => ({
  axis: {
    Week: { x_axis: [], y_axis: [] },
    Month: { x_axis: [], y_axis: [] },
    Year: { x_axis: [], y_axis: [] },
    Years: { x_axis: [], y_axis: [] },
    "3 Months": { x_axis: [], y_axis: [] },
    "6 Months": { x_axis: [], y_axis: [] },
    "12 Months": { x_axis: [], y_axis: [] },
  },
  count,
  variation: { Week: "", Month: "", Year: "", Years: "", "3 Months": "", "6 Months": "", "12 Months": "" },
});

const parseWei = (value: BigNumber) => {
  const etherValue = ethers.utils.formatUnits(value);
  return parseFloat(etherValue);
};

/**
 * Fetches and sums up the transferred tokens from the specified address.
 *
 * @param {SDK} sdk - The SDK instance to interact with the blockchain.
 * @param {string | null} from - The sender's address, or null for any address.
 * @param {string} to - The receiver's address.
 * @returns {Promise<BigNumber>} - The total amount of transferred tokens.
 */
const getTransferredTokens = async (
  sdk: SDK,
  from: string | null,
  to: string,
): Promise<BigNumber> => {
  const filter = sdk.odfToken.filters.Transfer(from, to);
  const events = await sdk.odfToken.queryFilter(filter);

  return events.reduce((total, event) => total.add(event.args.value), BigNumber.from(0));
};

const useContractsData = () => {
  const [contractsData, setContractsData] = useState<ContractsData | null>(null);
  const [loading, setLoading] = useState(true);

  const provider = useMemo(() => getProvider(), []);

  const loadData = async () => {
    const sdk = await getSdk(provider);
    const results = await Promise.allSettled([
      sdk.odfToken.balanceOf(sdk.odfRewards.address),
      sdk.odfToken.totalSupply(),
      sdk.odfToken.maxSupply(),
      SAFE_ADDRESS ? sdk.odfToken.balanceOf(SAFE_ADDRESS) : ethers.constants.Zero,
      getTransferredTokens(sdk, null, sdk.odfRewards.address),
    ]);

    const [rewards, totalSupply, maxSupply, safe, transferred] = results.map((result, index) => {
      if (result.status === "fulfilled") return result.value;
      toast.error(`Error loading ${contractsDataKeys[index]} data`);
      return ethers.constants.Zero;
    });

    const totalWithdrawals = transferred.isZero() ? transferred : transferred.sub(rewards);

    setContractsData({
      rewardsLeft: emptyStat(parseWei(rewards)),
      totalSupply: emptyStat(parseWei(totalSupply)),
      maxSupply: emptyStat(parseWei(maxSupply)),
      safeBalance: emptyStat(parseWei(safe)),
      totalWithdrawals: emptyStat(parseWei(totalWithdrawals)),
    });
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, [provider]);

  return { contractsData, loading };
};

export default useContractsData;
