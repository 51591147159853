type Item = {
  src: string; // image
  title: string;
  value: string; // key
}
export interface IFormSelect {
  value?: string;
  disabled?: boolean;
  onChange?: (item: string) => void,
  items: Item[]
}

const ButtonSelect = ({ value, items }: IFormSelect) => {
  return (
    <div className={`flex flex-col gap-5`}>
      {items.map(i => <Option item={i} value={value} key={i.value} />)}
    </div>
  );
};

const Option = ({ item, value, }: { item: Item, value?: string }) => {
  const selected = item.value === value;
  return <button disabled={true}
    className={`w-[331px] px-5 py-3 rounded-[20px flex items-center gap-3 border-[1px] border-[#3B3B3B] ${selected ? "bg-[#3B3B3B]" : "bg-[#F6F5F8]"} `}>
    <div className="rounded-full bg-[#E6E6E6] w-10 h-10 p-1 ">
      <img className="w-full h-full " src={item.src} />
    </div>
    <p className={`font-poppins font-semibold ${selected && "text-white"}`}>{item.title}</p>
  </button>;
};

export default ButtonSelect;