import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import pathUtils from "utils/pathUtils";

import { Login } from "views/authentication";
import ComponentRoutes from "./Route.Components";

const routes = [
  {
    path: pathUtils.SIGN_IN,
    element: <Login />,
  },
  {
    path: "/components/*",
    element: <ComponentRoutes />,
  },
  {
    path: "*",
    element: <Navigate to="/login" replace />,
  },
];

const Authentication: React.FC = () => {
  const location = useLocation();

  useEffect(() => {
    sessionStorage.setItem(
      "_redirectpath",
      location.pathname.slice(-1) === "/"
        ? location.pathname.slice(0, -1)
        : location.pathname
    );
  }, []);

  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} {...route} />
      ))}
    </Routes>
  );
};

export default Authentication;
