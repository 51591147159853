const tabs = ["all", "pending", "assigned", "verified", "denied"] as const
export type tab = typeof tabs[number];

interface ITabs {
  onTabChange: (t: tab) => void,
  selected: tab,
}

const Tabs = ({ onTabChange, selected }: ITabs) => {
  return <div className="flex gap-[18px] pb-5 border-b-[1px] border-dark-gray">
    <Tab tab="all" selected={selected} text="All submissions" onClick={onTabChange} />
    <Tab tab="pending" selected={selected} text="Pending" onClick={onTabChange} />
    <Tab tab="assigned" selected={selected} text="Assigned to you" onClick={onTabChange} />
    <Tab tab="verified" selected={selected} text="All Verified" onClick={onTabChange} />
    <Tab tab="denied" selected={selected} text="All Denied" onClick={onTabChange} />
  </div>
}

const Tab = ({ tab, text, selected, onClick }: { tab: tab, text: string, selected: tab, onClick: (tab) => void }) => {
  const active = selected === tab;
  return (
    <button onClick={() => onClick(tab)}
      className={"rounded-3xl px-4 py-2 font-poppins text-base hover:bg-odf hover:text-white " + (active ? "bg-blue2 text-white font-bold" : "")}>
      {text}
    </button>
  );
};

export default Tabs;