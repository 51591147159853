import { Auth } from 'aws-amplify';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import OnboardingAPI from 'network/onboarding';
import { setItem } from 'utils/storageUtils';

const getInitialState = (): AuthState => ({
  firstName: '',
  lastName: '',
  isFetching: false,
  isAuthenticated: false,
  userId: '',
  isAssignee: false,
});

export const loadAuth = createAsyncThunk('auth/login', async () => {
  try {
    const user = await Auth.currentAuthenticatedUser({ bypassCache: false });
    const email = user?.signInUserSession?.idToken?.payload?.email ?? '';
    const userData = await OnboardingAPI.getUserDetail(email).then(
      (response) => response?.data ?? null
    );
    const { user_type, user_id, first_name = '', last_name = '', Assignee } = userData;

    if (user && userData) {
      setItem('email', email);
      return {
        firstName: first_name,
        lastName: last_name,
        isFetching: false,
        isAuthenticated: user_type.toLowerCase() === 'admin',
        userId: user_id,
        isAssignee: !!Assignee
      };
    }
  } catch (err) {
    console.log('err: ', err);
  }
  return getInitialState();
});

export const logOut = createAsyncThunk('auth/logout', async () => {
  await Auth.signOut();
});

export const AuthSlice = createSlice({
  name: 'authentication',
  initialState: getInitialState(),
  reducers: {
    setAppIsLoading: (state) => {
      state = { ...state, isFetching: true };
      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadAuth.pending, (state) => {
      state.isFetching = true;
    });
    builder.addCase(loadAuth.fulfilled, (state, action) => {
      state = { ...action.payload };
      return state;
    });
    builder.addCase(logOut.fulfilled, (state) => {
      state = getInitialState();
      return state;
    });
  },
});

export const { setAppIsLoading } = AuthSlice.actions;
export default AuthSlice.reducer;
