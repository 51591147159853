import { ITalentReferralHistoryFilters } from './types';
import { API } from 'aws-amplify';

import awsUtils from 'utils/awsUtils';
import { getItem } from 'utils/storageUtils';
import { getQueryFromObject } from 'network/utils';

async function getWalletBalance(user_id: number) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/token/getBalance?user_id=${user_id}`;
  const myInit = {
    headers: { ...authHeader },
    response: true,
  };
  const result = await API.get(apiName, path, myInit);
  return result;
}

async function getRewardHistory(user_id: number) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/token/getRewardHistory?user_id=${user_id}`;
  const myInit = {
    headers: { ...authHeader },
    response: true,
  };
  const result = await API.get(apiName, path, myInit);
  return result;
}

async function inviteFriends(emailList: Array<string>) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/token/inviteFriends`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify({ emailList }),
    response: true,
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

// async function addRewardHistory()
async function getReferralsHistory(options: ITalentReferralHistoryFilters = {}) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const query = getQueryFromObject(options)
  const path = `/token/getReferrals?${query}`;

  const myInit = {
    headers: { ...authHeader },
    response: true,
  };
  const result = await API.get(apiName, path, myInit);
  return result;
}

async function getAggregatedRewardHistory(
  user_id: number,
  filter: string = 'monthly'
) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/token/getYearlyRewards?user_id=${user_id}&filter=${filter}`;
  const myInit = {
    headers: { ...authHeader },
    response: true,
  };
  const result = await API.get(apiName, path, myInit);
  return result;
}

async function addFreelancerReward({
  user_id,
  status = 'COMPLETED',
  amount = 0,
}) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/token/addFreelancerReward`;
  const myInit = {
    headers: { ...authHeader },
    body: JSON.stringify({ user_id, status, amount }),
    response: true,
  };
  const result = await API.post(apiName, path, myInit);
  return result;
}

export default {
  getWalletBalance,
  getRewardHistory,
  inviteFriends,
  getReferralsHistory,
  getAggregatedRewardHistory,
  //admin
  addFreelancerReward,
};
