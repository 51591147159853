import { IIdentificationType, IReputationIdentityProof } from "types/Reputation"
import { IPageProps } from "."
import ButtonSelect from "../Form/ButtonSelect"
import Doucment from "../Form/Document"
import Input from "../Form/Input"
import Label from "../Form/Label"

interface IPage extends IPageProps {
  repu: IReputationIdentityProof
}

const PageIdentityProof1 = ({ repu }: IPage) => {
  return <div className="flex flex-col gap-5">
    <div>
      <Label>Personal Info</Label>
      <p className="mt-[10px]">Enter the personal information required for proof of identification.</p>
    </div>

    <Input
      label="Location"
      text={repu.location || ""}
    />
    <Input
      label="First name*"
      text={repu.first_name || ""}
    />
    <Input
      label="Middle name"
      text={repu.middle_name || ""}
    />
    <Input
      label="Last name*"
      text={repu.last_name || ""}
    />
  </div>
}


const PageIdentityProof2 = ({ repu }: IPage) => {
  const { identification_type } = repu;
  return <div className="flex flex-col gap-10">
    <div>
      <Label>Identification</Label>
      <p className="mt-[10px]">Choose a document to submit for your verification.</p>
    </div>

    <ButtonSelect items={documents} value={repu.identification_type} />

    {/* Dynamic fields based on document type */}
    <div className="grid grid-cols-1 gap-5">
      {identification_type === "PASSPORT" &&
        <>
          <Input
            label="Identity number"
            text={repu.pass_identity_number}
          />
          <Input
            label="Date of birth"
            text={repu.pass_birth}
          />
          <Input
            label="Country of issuance"
            text={repu.pass_country}
          />
          <Input
            text={repu.pass_exp}
            label="Date of expiration"
          />
        </>
      }
      {identification_type === "DRIVER_L" &&
        <>
          <Input
            label="Driver license number"
            text={repu.drv_licence_number}
          />
          <Input
            label="Date of birth"
            text={repu.drv_birth}
          />
          <Input
            label="State of issuance"
            text={repu.drv_state}
          />
          <Input
            label="Date of expiration"
            text={repu.drv_exp}
          />
        </>
      }
      {identification_type === "UTILITY_BILL" &&
        <>
          <Input
            label="Address"
            text={repu.bill_address}
          />
          <Input
            label="State"
            text={repu.bill_state}
          />
          <Input
            label="Country of issuance"
            text={repu.bill_country}
          />
        </>
      }
    </div>

    <Doucment url={repu.document_url_presigned} label="Document upload*" />

  </div>
}

const documents: { value: IIdentificationType, title: string, src: string }[] = [
  {
    value: "PASSPORT",
    title: "Passport",
    src: "/common/reputation/doc.png",
  },
  {
    value: "DRIVER_L",
    title: "Driver’s license",
    src: "/common/reputation/keys.png",
  },
  {
    value: "UTILITY_BILL",
    title: "Utility bill",
    src: "/common/reputation/mining.png",
  },
];

const IdentityProofPages = [
  { Comp: PageIdentityProof1, title: "Personal Info" },
  { Comp: PageIdentityProof2, title: "Identification" }
]

export default IdentityProofPages;