import { getFileExt } from "utils/urlUtils";
import Label from "./Label";

interface IDoucment {
  label?: string;
  url?: string;
  className?: string
}

const Doucment = ({ url, className, label }: IDoucment) => {
  return (
    <div className={"w-[331px] " + className}>
      {label && <Label>{label}</Label>}
      {url && <a className="flex gap-[14px] mt-[14px]" href={url} target="_blank">
        <img src="/common/reputation/chain.png" className="w-6 h-6" />
        <p className="text-blue2 font-semibold underline">document.{getFileExt(url)}</p>
      </a>}
    </div>

  );
}

export default Doucment;