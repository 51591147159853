import { useEffect, useState } from 'react';
import { toast } from 'react-toastify'

import Loader from 'components/V2/Loader/Loader';
import adminAPI from 'network/admin'
import { ITalentManagementLocation, ITalentManagementRole, ITalentManagementSkill } from 'types/TalentManagement';
import TabV2 from 'components/V2/Tab/TabV2';
import Table, { Category } from './components/Table';
import InputV2 from 'components/V2/Input/InputV2';
import IconV2 from "components/V2/Icons/IconV2";
import { IJobManagementLocation, IJobManagementRole, IJobManagementSkill } from 'types/JobManagement';
import Button from 'components/Button/ButtonV2';
import { ICompanyManagementIndustry, ICompanyManagementLocation } from 'types/CompanyManagement';

const Management = () => {
  const [keyword, setKeyword] = useState<string>();
  const [data, setData] = useState<{
    talent: {
      role_management: { data: ITalentManagementRole[], total: number },
      skills_management: { data: ITalentManagementSkill[], total: number },
      locations_management: { data: ITalentManagementLocation[], total: number }
    },
    job: {
      role_management: { data: IJobManagementRole[], total: number },
      skills_management: { data: IJobManagementSkill[], total: number },
      locations_management: { data: IJobManagementLocation[], total: number }
    },
    company: {
      locations_management: { data: ICompanyManagementLocation[], total: number },
      industries_management: { data: ICompanyManagementIndustry[], total: number },
    }
  }>();
  const [tab, setTab] = useState(0);
  const fetchData = async () => {
    try {
      const response = await adminAPI.getManangement();
      if (!response.success) throw new Error(JSON.stringify(response));
      // This is to improve search performance
      response.talent.role_management.data.forEach(d => d.lowerCase = d.role.toLowerCase());
      response.talent.skills_management.data.forEach(d => d.lowerCase = d.skill.toLowerCase());
      response.talent.locations_management.data.forEach(d => d.lowerCase = d.location.toLowerCase());
      response.job.role_management.data.forEach(d => d.lowerCase = d.role.toLowerCase());
      response.job.skills_management.data.forEach(d => d.lowerCase = d.skill.toLowerCase());
      response.job.locations_management.data.forEach(d => d.lowerCase = d.location.toLowerCase());
      response.company.locations_management.data.forEach(d => d.lowerCase = d.location.toLowerCase());
      response.company.industries_management.data.forEach(d => d.lowerCase = d.industry.toLowerCase());
      setData(response)
    } catch (e) {
      toast.error('Error while fething data')
      console.error(e)
    }
  }
  const [category, setCategory] = useState<Category>("Talent");
  useEffect(() => { fetchData() }, []);

  if (!data) return <Loader />;

  // Filter based on keyword
  const filter = (data) => keyword ? data.filter(d => d.lowerCase.includes(keyword)) : data;
  let content;
  let tabs;
  switch (category) {
    case "Talent":
      tabs = ["Role", "Skills", "Locations"];
      const { role_management: trm, skills_management: tsm, locations_management: tlm } = data.talent;
      content = [
        <Table key="Role" total={trm.total} title='Role' rows={filter(trm.data)} getName={(r) => r.role} category={category} />,
        <Table key="Skills" total={tsm.total} title='Skill' rows={filter(tsm.data)} getName={(s) => s.skill} category={category} />,
        <Table key="Location" skip={["us_can", "latam", "apac"]} total={tlm.total} title='Location' rows={filter(tlm.data)} getName={(l) => l.location} category={category} />,
      ]
      break;
    case "Jobs":
      tabs = ["Role", "Skills", "Locations"];
      const { role_management: jrm, skills_management: jsm, locations_management: jlm } = data.job;
      content = [
        <Table key="Role" total={jrm.total} title='Role' rows={filter(jrm.data)} getName={(r) => r.role} category={category} />,
        <Table key="Skills" total={jsm.total} title='Skill' rows={filter(jsm.data)} getName={(s) => s.skill} category={category} />,
        <Table key="Location" skip={["us_can", "latam", "apac"]} total={jlm.total} title='Location' rows={filter(jlm.data)} getName={(l) => l.location} category={category} />,
      ]
      break;
    case "Companies":
      tabs = ["Industry", "Locations"];
      const { industries_management: cim, locations_management: clm } = data.company;
      content = [
        <Table key="Industry" total={cim.total} title='Industry' rows={filter(cim.data)} getName={(r) => r.industry} category={category} />,
        <Table key="Location" skip={["us_can", "latam", "apac"]} total={clm.total} title='Location' rows={filter(clm.data)} getName={(l) => l.location} category={category} />,
      ]
      break;
  }

  return (
    <div className='w-full h-full overflow-y-auto pt-[62px]'>

      <span className='flex font-poppins text-[24px] font-bold ml-[35px] mb-[12px]'>{category} management</span>

      {/* Search bar */}
      <div className='ml-[35px] mb-[20px] w-[80%]'>
        <InputV2 value={keyword} onChange={(e) => setKeyword(e.target.value)} icon={
          <IconV2
            iconType="SEARCH"
            iconClassName="w-6 h-6 "
          />
        } placeholder="Search by roles, skills or location" />
      </div>

      {/* Management Categories */}
      <div className='flex gap-[20px] ml-[35px] mb-[20px]'>
        <CategoryButton text="Talent" active={category === "Talent"} onClick={() => setCategory("Talent")} />
        <CategoryButton text="Jobs" active={category === "Jobs"} onClick={() => setCategory("Jobs")} />
        <CategoryButton text="Companies" active={category === "Companies"} onClick={() => setCategory("Companies")} />
      </div>

      <TabV2
        addClass="!bg-transparent !p-[1px] ml-[35px]"
        tabClass="px-[10px] font-inter font-semibold text-[16px] rounded-none mr-[20px]"
        tabs={tabs}
        activeTabClass="border-b-[4px] border-odf"
        contents={content}
        tabNavigations={tabs}
        onTabChange={setTab}
        initIndex={tab}
      />
    </div>
  );
}

const CategoryButton = ({ text, onClick, active }:
  { text: string, onClick: () => void, active: boolean }) => (
  <Button
    className={`flex items-center gap-[12px] w-fit h-[40px] rounded-3xl !px-[16px] !py-[8px] font-poppins font-bold text-[14px]
      ${active ? "bg-blue2-hue !text-blue2" : "bg-[#F0F1F2] !text-inactive-gray"} `}
    onClick={onClick}
  >
    {text}
  </Button>
)

export default Management;
