import TabV2 from "../Tab/TabV2";

interface IMainTabs {
    tabs: string[],
    contents: JSX.Element[],
    tabNavigations: string[],
    initIndex?: number,
}

const MainTabs = ({ initIndex = 0, contents, tabNavigations, tabs }: IMainTabs) => {
    return (
        <TabV2
            addClass="rounded-none !bg-transparent !p-[1px]"
            tabClass="h-[40px] px-[20px] min-w-[160px] font-inter font-semibold text-[16px]"
            tabs={tabs}
            activeTabClass="!bg-odf !text-white rounded-none"
            inactiveTabClass="!bg-[#F0F1F2] rounded-none"
            contents={contents}
            tabNavigations={tabNavigations}
            initIndex={initIndex}
        />);
}

export default MainTabs;