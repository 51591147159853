import IconV2 from "components/V2/Icons/IconV2";
import { default_avatars } from "constants/default_images";
import { Company } from "types/Company";

interface ICompanyLogo {
  company: Company;
  className?: string;
}

const CompanyLogo = ({ company, className = "" }: ICompanyLogo) => {
  const { logo } = company;
  if (logo === null)
    return <IconV2 iconType="OUTDEF-LOGO" iconClassName={`w-[45px] h-[45px] ${className}`} />;
  let link;
  if (isNaN(Number(logo))) link = logo;
  else link = default_avatars[logo];

  return (
    <div className={`w-[45px] h-[45px] overflow-hidden mx-auto rounded-full ${className}`}>
      <div className="h-full">
        <img src={link} alt="avatar" className="object-cover rounded-full w-full h-full" />
      </div>
    </div>
  );
};

export default CompanyLogo;
