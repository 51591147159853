import React, { useState } from "react";
import useClickOutside from "hooks/utils/useClickOutside";
import IconV2 from "../Icons/IconV2";

type IData = { value: string, className?: string, id?: number | string, };

interface ITH {
    data: IData[],
    className?: string,
    text: string,
    onSelect: (data: IData) => void;
}

export const THdropdown = ({ data, text, className = "", onSelect }: ITH) => {
    const [show, setShow] = useState<boolean>(false);
    const [selected, setSelected] = useState<string>(data[0].value);
    const select = (d: IData) => { onSelect(d); setShow(false); setSelected(d.value) };
    const ref = React.useRef<HTMLDivElement>(null);
    useClickOutside(ref, () => setShow(false));

    return (
        <th className={className + " relative"}>
            <div className={"flex gap-[16px] w-fit mx-auto items-center "}>
                {text}

                <div onClick={() => setShow(!show)}>
                    <IconV2 iconType="ARROW-DOWN" iconClassName={`cursor-pointer w-[12px] fill-inactive-gray ${show && "fill-odf"} `} />
                </div>

            </div>
            {show && (
                <div
                    ref={ref}
                    className="z-10 flex flex-col gap-[20px] bg-white py-[20px] px-[10px] absolute top-[60px] shadow-cta w-full rounded-lg">
                    {data.map(d => (
                        <div onClick={() => select(d)}
                            className={`w-full px-[12px] py-[8px] rounded-lg font-inter text-[14px] font-semibold cursor-pointer hover:!bg-blue2 hover:!text-white 
                            ${selected === d.value && "!bg-blue2 !text-white"} ${d.className} `} >
                            {d.value}
                        </div>
                    )
                    )}
                </div>
            )
            }
        </th >
    )
}