import { API } from "aws-amplify";

import awsUtils from "utils/awsUtils";

async function getCompanyById(id: string | number) {
  const authHeader = await awsUtils.getAuthHeader();
  const apiName = awsUtils.getAPIName();
  const path = `/client/company/${id}`;
  const myInit = {
    headers: { ...authHeader },
    response: true,
  };
  const result = await API.get(apiName, path, myInit);
  return result?.data;
}

export default {
    getCompanyById
}