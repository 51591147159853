import { memo, ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'redux/hooks/redux-hooks';
import { logOut } from 'redux/slices/authentication';

import { ReactComponent as OutdefineLogo } from 'assets/svg/dark-logo.svg';

// menu data from constants
import { menu_data } from 'constants/sidebarv2';
import ItemV2 from './ItemV2';

const SidebarV2: React.FC = (): ReactElement => {
  const dispatch = useAppDispatch();
  const auth = useAppSelector((root) => root.authentication);
  const userRole = auth.isAuthenticated ? 'admin' : 'user';

  const LogOut = async (val: boolean) => {
    if (!val) return;
    dispatch(logOut());
  };

  return (
    <nav className='flex flex-col overflow-x-hidden min-h-screen max-h-screen overflow-auto bg-white pt-[28px] pb-5 z-10 no-scroll shadow-[0_0_3px_0_rgb(0_0_0_/_12%),_0_2px_3px_0_rgb(0_0_0_/_22%)]'>
      <NavLink
        to='/'
        className='flex gap-4 items-center font-montserrat font-extrabold text-[15px] leading-[150%] tracking-[0.05em] text-odf justify-center'
      >
        <OutdefineLogo className='w-[30px] h-[30px]' />
      </NavLink>

      <div className='mt-40 flex flex-col gap-[36px] w-full px-4 2xl:px-[44px]'>
        {menu_data[userRole]?.mainlinks.map((elem, index) => (
          <ItemV2
            key={'mainlink' + index}
            link={elem.link}
            text={elem.text}
            data={elem?.children}
          />
        ))}

        <ItemV2
          onClick={(val: boolean) => LogOut(val)}
          addClass='mt-[6px]'
          text='logout'
          link='#logout'
        />
      </div>
    </nav>
  );
};

export default memo(SidebarV2);
