import { configureStore } from "@reduxjs/toolkit";

import authenticationSlice from "redux/slices/authentication";
import ProfileSlice from "redux/slices/profile";
import AssessmentSlice from "redux/slices/assessment";
import PrototypeSlice from "redux/slices/prototype";
import TokenSlice from "redux/slices/token";
import BannedTalentSlice from "redux/slices/banned_talent";
import InvoiceSlice from "redux/slices/invoice";

export const store = configureStore({
  reducer: {
    authentication: authenticationSlice,
    profile: ProfileSlice,
    prototype: PrototypeSlice,
    assessment: AssessmentSlice,
    token: TokenSlice,
    banned_talent: BannedTalentSlice,
    invoice: InvoiceSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
