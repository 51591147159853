import { AnchorHTMLAttributes } from "react";
import Label from "./Label";

interface IAnchor extends AnchorHTMLAttributes<HTMLAnchorElement> {
  label?: string
}

const Anchor = ({ href, className, target = "_blank", label, ...props }: IAnchor) => {
  return (
    <div className="flex flex-col">
      {label && <Label className="mb-3">{label}</Label>}
      <a
        href={href}
        target={target}
        className={"font-inter text-base bg-odf-light border-[1px] border-dark-gray w-[650px] rounded-xl overflow-x-auto py-2 px-4 " + className}
        {...props}>
        {href}
      </a>
    </div>);
}

export default Anchor;