interface ITimeline {
    current: number;
    steps: string[];
    className?: string;
}

const Point = ({ active = false }) =>
    <div className={`flex items-center justify-center rounded-full w-[26px] h-[26px] 
    border-solid border-[1px] bg-white ${active ? "border-odf" : "border-[#D9D9D9]"} `}>
        <div className={`w-[16px] h-[16px] rounded-full ${active ? "bg-odf" : "bg-[#D9D9D9]"}`} />
    </div>;

const Timeline = ({ current, steps, className = "" }: ITimeline) => {
    return (
        <div className={`relative flex justify-between ${className}`}>
            {/* Line */}
            <div className="absolute h-[3px] w-full bg-[#D9D9D9] top-[13px]" />

            {/* First step */}
            <div className="relative flex flex-col gap-y-[11px] items-start">
                <Point active={current >= 0} />
                <span className="font-inter text-[14px]">{steps[0]}</span>
            </div>


            {/* Middle steps... */}
            {
                steps.slice(1, steps.length - 1).map((text, i) =>
                    <div key={i} className="relative flex flex-col gap-y-[11px] items-center">
                        <Point active={current >= i + 1} />
                        <span className="font-inter text-[14px]">{text}</span>
                    </div>
                )
            }

            {/* Final step */}
            <div className="relative flex flex-col gap-y-[11px] items-end">
                <Point active={current === steps.length - 1} />
                <span className="font-inter text-[14px]">{steps[steps.length - 1]}</span>
            </div>

        </div>
    );
}

export default Timeline;