import Button from "components/Button/ButtonV2";
import ENUMS from "constants/enums";
import { AssessmentStatus } from "types";
import PassFailBadge from "../../components/PassFailBadge";

interface IPassFailButtons {
    status: AssessmentStatus;
}

const PassFailButtons = ({ status }: IPassFailButtons) => {
    const notFinish = [ENUMS.assessmentStatus[0], ENUMS.assessmentStatus[1]].includes(status)
    return (
        <div className="gap-[20px] flex">
            <div className="flex gap-[20px]">
                <Button
                    className="px-4 bg-blue2"
                    disabled
                >
                    Reset test
                </Button>
                {notFinish &&
                    <Button
                        disabled
                        className="px-4 bg-coral-red"
                    >
                        Fail
                    </Button>
                }
                {notFinish &&
                    <Button
                        disabled
                        className="px-4 bg-success"
                    >
                        Pass
                    </Button>
                }
            </div>
            {status === ENUMS.assessmentStatus[2] && <PassFailBadge passed={true} />}
            {status === ENUMS.assessmentStatus[3] && <PassFailBadge passed={false} />}
        </div>
    )
}

export default PassFailButtons;