import { useEffect } from "react";
import { Routes, Route, Navigate, useNavigate } from "react-router-dom";
import AuthenticatedLayout from "components/layout/Layout.Authenticated";
import TalentRoutes from "./route.talent";
import pathUtils from "utils/pathUtils";
import CompanyRoutes from "./route.company";
import DashboardRoutes from "./route.dashboard";
import DatabaseRoutes from "./route.database";
import BoardManagementRoutes from "./route.board";
import TestEmailSimulator from "views/application/email_simulator";
import ManagementRoutes from "./route.management";
import InvoiceRoutes from "./route.invoice";
import LinkedinUsersRoutes from "./route.linkedin_users";
import ReputationRoutes from "./route.reputation";

const routes = [
  {
    path: pathUtils.TALENT,
    element: <TalentRoutes />,
  },
  {
    path: pathUtils.MANAGEMENT,
    element: <ManagementRoutes />,
  },
  {
    path: pathUtils.COMPANY,
    element: <CompanyRoutes />,
  },
  {
    path: pathUtils.DASHBOARD,
    element: <DashboardRoutes />,
  },
  {
    path: pathUtils.DATABASE,
    element: <DatabaseRoutes />,
  },
  {
    path: pathUtils.BOARD_MANAGEMENT,
    element: <BoardManagementRoutes />,
  },
  {
    path: pathUtils.INVOICE,
    element: <InvoiceRoutes />,
  },
  {
    path: pathUtils.LINKEDIN_USERS,
    element: <LinkedinUsersRoutes />,
  },
  {
    path: pathUtils.REPUTATION,
    element: <ReputationRoutes />,
  },
  {
    path: "/dev/test-email",
    element: <TestEmailSimulator />,
  },
  {
    path: "*",
    element: <Navigate to="/" replace />,
  },
];

const Application: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectPath = sessionStorage.getItem("_redirectpath") || "/";
    sessionStorage.removeItem("_redirectpath");
    navigate(redirectPath);
  }, []);

  return (
    <AuthenticatedLayout>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} {...route} />
        ))}
      </Routes>
    </AuthenticatedLayout>
  );
};

export default Application;
