import MainTabs from "components/V2/MainTabs/MainTabs";
import { FreelancerProfileWithRewards } from "types/FreelancerProfileWithRewards";
import AppliedJobs from "./Tabs/AppliedJobs/index.tab";
import Profile from "./Tabs/Profile/index.tab";
import ReferralHistory from "./Tabs/ReferralHistory/index.tab";
import TokenEarnings from "./Tabs/TokenEarnings/index.tab";

interface ITalentProfile {
    talent: FreelancerProfileWithRewards;
}

const TalentProfile = ({ talent }: ITalentProfile) => {
    return (
        <MainTabs
            tabNavigations={["profile", "referrals", "rewards", "applied_jobs"]}
            tabs={["Profile", "Referral history", "Token earnings", "Applied jobs"]}
            contents={[
                <Profile talent={talent} />,
                <ReferralHistory talent={talent} />,
                <TokenEarnings talent={talent} />,
                <AppliedJobs talent={talent} />,
            ]}
        />
    );
}

export default TalentProfile;