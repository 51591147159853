import { FreelancerProfile } from "types";

export const averageRating = (rating) =>
  Object.entries(JSON.parse(rating || "{}")).reduce(
    (acc: number, cur) => acc + parseInt(cur[1] as string),
    0
  ) / (Object.entries(JSON.parse(rating || "{}")).length || 1);

export const isDeterminedTalent = (item: FreelancerProfile) =>
  item.is_trusted_talent === "TRUSTED" || item.is_trusted_talent === "FAILED";

export const isInterviewing = (item: FreelancerProfile) => {
  const booking = item.User.AssessmentInfo?.booking_uid;
  return item.User.AssessmentInfo?.interview_status === "SUBMITTED" ||
    (booking && booking.charAt(0) !== '[');
};

export const interviewScheduled = (item: FreelancerProfile) => {
  const booking = item.User.AssessmentInfo?.booking_uid;
  return booking && booking.charAt(0) !== '[';
}

export const getResumeName = (item: FreelancerProfile) => {
  const { User, resume } = item;
  if (!resume) return "";
  let link = resume.split("?")[0];
  const split = link.split(".");
  const ext = split[split.length - 1];
  return `${User.first_name}_${User.last_name}_CV.${ext}`
}


