import { RootState } from 'app/store';
import { ReactElement } from 'react';
import { useSelector } from 'react-redux';

export default function RoleOfJobTypeList(props: {
  index: 0 | 1 | 2;
  keyword?: string;
}): ReactElement {
  const roles = useSelector(
    (root: RootState) => root.prototype.data[props.index]
  );

  return (
    <div className='w-full border border-light-gray h-fit rounded-md overflow-hidden relative'>
      <div className='h-8 bg-[#F0F1F2] leading-8 text-[#8A8A8A] font-semibold text-base font-inter px-4'>
        {roles?.name}
      </div>
      <ul className='w-full max-h-[500px] overflow-y-auto'>
        <span className='w-full absolute top-8 h-4 bg-gradient-to-b from-white to-transparent' />
        {roles?.roles
          ?.filter((role) =>
            role.name.toLowerCase().includes(props.keyword?.toLowerCase() || '')
          )
          .map((role, index) => (
            <li
              key={role.id}
              className='h-8 font-inter font-semibold leading-8 px-4 border-b border-b-light-gray truncate last:border-none'
            >
              {index + 1}. {role.name}
            </li>
          ))}
        <span className='w-full absolute bottom-0 h-4 bg-gradient-to-t from-white to-transparent' />
      </ul>
    </div>
  );
}
